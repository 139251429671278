import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ArrowUp from '../../assets/images/up-colored-arrow.svg';
import ArrowDown from '../../assets/images/down-colored-arrow.svg';

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="border border-[#0174EB] rounded-[14px] mb-[29px] lg:mb-[25px] xs:mb-[20px] px-[35px] lg:px-[25px] xxs:px-[15px] py-[22px] lg:py-[15px] xs:py-[20px]">
      <button
        className="w-full flex justify-between items-center py-3 xs:py-0 text-left text-[22px] lg:text-[20px] xxs:text-[18px] leading-[1.2] focus:outline-none"
        onClick={onClick}
      >
        <span className="block pr-[10px]">{question}</span>
        <img src={isOpen ? ArrowUp : ArrowDown} alt="Arrow Icon" className="h-[40px] w-[40px] xs:h-[30px] xs:w-[30px]" />
      </button>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        {isOpen && (
          <div className="py-2">
            <p className="text-[18px] xxs:text-[16px]">{answer}</p>
          </div>
        )}
      </motion.div>
    </div>
  );
};

const AccordionSection = ({ items }) => {

  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="w-full max-w-[1310px] px-[20px] mx-auto mb-[120px] sm:mb-[80px] xs:mb-[50px]">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.accordionTitle}
          answer={item.accordionDescription}
          isOpen={openIndex === index}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
  );
};

export default AccordionSection;
