import React from 'react';
import { motion } from 'framer-motion'; // Framer Motion for animations
import { useInView } from 'react-intersection-observer'; // Hook for detecting in-view elements
import logo from '../../assets/images/logo.svg';
import mediaLogo1 from '../../assets/images/mediaLogo1.svg';
import mediaLogo2 from '../../assets/images/mediaLogo2.svg';

const animations = {
  initial: { opacity: 0, y: 50 }, // Animation starting state
  animate: { opacity: 1, y: 0 }, // Animation ending state
  transition: { duration: 0.6, delay: 0.2 }, // Transition duration and delay
};

const MediaLogoIcons = () => {
  const [ref, inView] = useInView({
    threshold: 0.3, // The threshold for triggering the hook (30% visibility)
  });

  return (
    <motion.div
      className="container mx-auto max-w-[1597px] pt-[120px] xl:pt-[20px] sm:pt-[40px] pb-[120px] lg:pb-[80px] sm:pb-[40px] px-[20px]"
      ref={ref} // Attach the ref to the outer div to detect when it's in view
      initial="initial"
      animate={inView ? "animate" : "initial"} // Animate only when in view
      transition={{ duration: 1 }}
      variants={animations} // Variants define the animations for Framer Motion
    >
      <div className="mb-[64px] xl:mb-[44px] lg:mb-[34px] sm:mb-[25px]">
        <h2 className="text-[85px] 2xl:text-[76px] xl:text-[65px] xl:text-[55px] leading-[1.2] font-[900] text-[#0174EB] mb-2 xxs:text-[45px] xxs:leading-[1]">
          Logos and Icons
        </h2>
      </div>
      <div className="mb-[80px] xl:mb-[60px] lg:mb-[40px] sm:mb-[30px]">
        <div className="mb-[36px]">
          <p className="text-[#434343] text-[35px] xl:text-[30px] sm:text-[25px] leading-[1.2] font-[500]">
            InCabinPets logo in different formats
          </p>
        </div>
        <motion.div
          className="flex justify-between flex-wrap"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={inView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
          transition={animations.transition} // Animation transition
        >
          {/* Loop through logo types */}
          {[
            { src: logo, label: 'High Resolution JPG' },
            { src: logo, label: 'Low Resolution JPG' },
            { src: logo, label: 'PDF (Vector)' },
            { src: logo, label: 'PNG' },
          ].map((item, idx) => (
            <div key={idx} className="w-[24%] sm:w-[48%] xxs:w-[100%] sm:mb-[30px] xxs:mb-[20px]">
              <motion.div
                className="border border-[#0174eb47]"
                whileHover={{ scale: 1.05 }} // Hover animation
              >
                <div className="p-[45px] lg:p-[30px] h-[206px] lg:h-[180px] flex items-center justify-center">
                  <img className="w-full max-w-[230px] lg:max-w-[160px]" src={item.src} alt={item.label} />
                </div>
                <div className="bg-[#0174EB] py-[25px] lg:py-[15px] px-[11px] lg:px-[5px] text-center">
                  <p className="text-[22px] 2xl:text-[20px] xl:text-[17px] lg:text-[14px] leading-[1.2] text-white">Logo in {item.label}</p>
                </div>
              </motion.div>
            </div>
          ))}
        </motion.div>
      </div>
      <div className="w-full">
        <div className="mb-[36px]">
          <p className="text-[#434343] text-[35px] xl:text-[30px] sm:text-[25px] leading-[1.2] font-[500]">
            Media logos in different formats
          </p>
        </div>
        <motion.div
          className="flex justify-between flex-wrap"
          initial={{ opacity: 0, x: -50 }}
          animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
          transition={animations.transition} // Animation transition
        >
          {/* Media logos */}
          {[
            { src: mediaLogo1, label: 'PAW icon in High Resolution JPG' },
            { src: mediaLogo1, label: 'PAW icon in PDF' },
            { src: mediaLogo2, label: 'Plane Icon in High Resolution JPG' },
            { src: mediaLogo2, label: 'Plane Icon in PDF' },
          ].map((item, idx) => (
            <div key={idx} className="w-[24%] sm:w-[48%] xxs:w-[100%] sm:mb-[30px] xxs:mb-[20px]">
              <motion.div
                className="border border-[#0174eb47]"
                whileHover={{ scale: 1.05 }} // Hover animation
              >
                <div className="p-[45px] lg:p-[30px] h-[206px] lg:h-[180px] flex items-center justify-center">
                  <img className="w-full max-w-[230px] lg:max-w-[160px]" src={item.src} alt={item.label} />
                </div>
                <div className="bg-[#0174EB] py-[25px] lg:py-[15px] px-[11px] lg:px-[5px] text-center">
                  <p className="text-[22px] 2xl:text-[20px] xl:text-[17px] lg:text-[14px] text-white">{item.label}</p>
                </div>
              </motion.div>
            </div>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default MediaLogoIcons;
