import React, { useState } from 'react';
import { motion } from 'framer-motion';
import rotateIcon from '../../assets/images/rotate-icon.svg';
import LazyLoad from 'react-lazy-load';
const BookingDelivery = (props) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };
    return (
        <>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="block"
            >
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                    className='border-[1px] border-[solid] border-[#004A97] bg-[#FFFFFF] px-[30px] rounded-[26px] md:px-[18px] sm:px-[0px] sm:bg-transparent sm:border-none sm:rounded-[0px]'
                >
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className='flex justify-between sm:flex-col sm:gap-[10px]'
                    >
                        <div className='flex sm:w-full sm:justify-center sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px]'>
                            <div className='py-[20px] pr-[80px] lg:pr-[50px] border-r border-solid border-[#004A97] sm:w-1/2 sm:py-[20px] sm:pl-[20px] sm:pl-[70px] sm:pr-[20px] xxs:pl-[22px]'>
                                <div className="flex flex-col">
                                    <span className='text-[#010101] font-normal text-[24px] leading-[28px] xs:text-[20px]'>From</span>
                                    <h4 className='text-[#0174EB] font-[900] text-[48px] 2xl:text-[36px] xl:text-[26px] lg:text-[24px] leading-[56px] xl:leading-[40px] lg:leading-[37px] xs:text-[22px]  xs:leading-[normal] sm:break-word'>New York</h4>
                                    <span className='text-[#000000] font-normal text-[16px] leading-[22px] xs:text-[16px]'>John F. Kennedy International Airport</span>
                                </div>
                            </div>
                            <div className='py-[20px] pl-[80px] pr-[80px] relative border-r border-solid border-[#004A97] sm:border-none  sm:w-1/2 sm:py-[20px] sm:pl-[70px] sm:pr-[20px] xxs:pl-[22px]'>
                                <div className="flex flex-col">
                                    <span className='text-[#010101] font-normal text-[24px] leading-[28px] xs:text-[20px]'>To</span>
                                    <h4 className='text-[#0174EB] font-[900] text-[48px] 2xl:text-[36px] xl:text-[26px] lg:text-[24px] leading-[56px] xl:leading-[40px] lg:leading-[37px] xs:text-[22px] xs:leading-[normal] sm:break-word'>Indianapolis</h4>
                                    <span className='text-[#000000] font-normal text-[16px] leading-[22px] xs:text-[16px]'>Indianapolis International Airport</span>
                                </div>
                                <div className='h-[63px] w-[63px] xl:h-[50px] xl:w-[50px] lg:h-[40px] lg:w-[40px] border-[1px] absolute cursor-pointer bottom-[40px] -left-[31px] xl:-left-[25px] lg:-left-[20px] border-[solid] border-[#004A97] rounded-[50%] xxs:top-[38%] xxs:-left-[22px]'>
                                    <LazyLoad offset={100} once>
                                        <img src={rotateIcon} alt="rotateIcon" className='h-full w-full rounded-[50%]' />
                                    </LazyLoad>
                                </div>
                            </div>
                        </div>
                        <div className='py-[20px] px-[30px] border-r border-solid border-[#004A97] sm:w-full sm:justify-center sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px]'>
                            <div className="flex flex-col">
                                <span className='text-[#010101] font-normal text-[22px] leading-[28px] xl:text-[18px] lg:text-[16px] xl:leading-[24px] lg:leading-[20px]'>Pick your Date 01</span>
                                <h4 className='text-[#000000] font-[500] text-[32px] leading-[38px] xl:text-[25px] xl:leading-[30px]'><strong className='text-[#0174EB] font-[900] text-[48px] leading-[56px] xl:text-[35px] lg:text-[30px] leading-[46px] lg:leading-[40px] xxs:text-[22px] xxs:leading-[1]'>25</strong> Jan’24</h4>
                                <span className='text-[#000000] font-normal text-[16px] leading-[22px] xxs:text-[14px]'>Thursday</span>
                            </div>
                        </div>
                        <div className='py-[20px] px-[30px] border-r border-solid border-[#004A97] sm:w-full sm:justify-center sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px]'>
                            <div className="flex flex-col">
                                <span className='text-[#010101] font-normal text-[22px] leading-[28px] xl:text-[18px] lg:text-[16px] xl:leading-[24px] lg:leading-[20px]'>Pick your Date 02</span>
                                <h4 className='text-[#000000] font-[500] text-[32px] leading-[38px] xl:text-[25px] xl:leading-[30px]'><strong className='text-[#0174EB] font-[900] text-[48px] leading-[56px] xl:text-[35px] lg:text-[30px] leading-[46px] lg:leading-[40px] xxs:text-[22px] xxs:leading-[1]'>25</strong> Jan’24</h4>
                                <span className='text-[#000000] font-normal text-[16px] leading-[22px] xxs:text-[14px]'>Thursday</span>
                            </div>
                        </div>
                        <div className='py-[20px] px-[15px] sm:w-full sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px] flex items-center'>
                            <div className="flex flex-col">
                                <span className='text-[#010101] font-normal text-[22px] leading-[28px] xl:text-[18px] lg:text-[16px] xl:leading-[24px] lg:leading-[20px]'>Pick your Date 03</span>
                                <h4 className='text-[#000000] font-[500] text-[32px] leading-[38px] xl:text-[25px] xl:leading-[30px]'><strong className='text-[#0174EB] font-[900] text-[48px] leading-[56px] xl:text-[35px] lg:text-[30px] leading-[46px] lg:leading-[40px] xxs:text-[22px] xxs:leading-[1]'>25</strong> Jan’24</h4>
                                <span className='text-[#000000] font-normal text-[16px] leading-[22px] xxs:text-[14px]'>Thursday</span>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
                <div className="w-full px-[30px] py-[20px] xs:px-[10px]">
                    <div className="flex items-center pb-[20px] border-b-[1px] border-b-[#FFFFFF] xs:flex-col xs:gap-[10px]">
                        <div className="title min-w-[230px] w-[230px] mr-[10px] xs:text-center">
                            <h4 className='text-[#FFFFFF] text-[24px] leading-[28px] font-[700]'>Total Round Trip Nautical Air Mileage</h4>
                        </div>
                        <div className="flex flex-wrap gap-[10px] sm:flex-row-reverse sm:justify-end">
                            <div className="flex pl-[30px] pr-[10px] py-[20px] rounded-[15px] bg-[#82BFFF] w-[331px] xs:w-[auto] xs:px-[20px]">
                                <div className="flex mt-1">
                                    <input id="helper-checkbox" aria-describedby="helper-checkbox-text" type="checkbox" value="" className="w-[24px] h-[24px] border-black focus:border-black focus:[box-shadow:none] checked:border-black checked:bg-blue-500 xs:w-[19px] xs:h-[19px]" />
                                </div>
                                <div className="ms-2 text-sm">
                                    <label htmlFor="helper-checkbox" className="text-[28px] text-[#000000] leading-[32px] font-[700] xs:text-[22px] xs:leading-[normal] xxs:text-[20px]">Pet Tracking</label>
                                    <p id="helper-checkbox-text" className="text-[20px] text-[#000000] leading-[24px] font-[400] xs:text-[16px] xs:leading-[normal]">(Coming Soon)</p>
                                </div>
                            </div>
                            <div className="flex pl-[30px] pr-[10px] py-[20px] rounded-[15px] bg-[#82BFFF] w-[331px] xs:w-[auto] xs:px-[20px]">
                                <div className="flex mt-1 relative">
                                    <input
                                        id="Expedited-checkbox"
                                        aria-describedby="helper-checkbox-text"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={toggleCheckbox}
                                        value=""
                                        className="w-[24px] h-[24px] relative border-black focus:border-black focus:[box-shadow:none] checked:border-black checked:bg-blue-500 xs:w-[19px] xs:h-[19px]"
                                    />
                                    <motion.div
                                        initial={{ opacity: 0, scale: 0.8 }}
                                        animate={{ opacity: isChecked ? 1 : 0, scale: isChecked ? 1 : 0.8 }}
                                        transition={{ duration: 0.2 }}
                                        className={`absolute top-[-26px] w-[553px] z-[99] left-[320px] rounded-[18px] border-[3px] bg-[#FFFFFF] border-[solid] border-[#004A97] 2xl:left-[0px] 2xl:top-[30px] sm:w-[max-content] xs:w-[350px] text-sm xxs:w-[265px] xxs:-left-[30px] ${isChecked ? 'block' : 'hidden'}`}
                                    >
                                        <form action="" className='flex flex-col gap-[20px] items-left aligns-center'>
                                            <div className="pickup-address px-[20px] pt-[20px]">
                                                <h5 className="text-[28px] leading-[33px] mb-[20px] text-[#0174EB] font-[700] xxs:text-[22px] xxs:leading-[normal] xxs:mb-[0px]">Pickup Address</h5>
                                                <div className="w-full">
                                                    <input type="text" placeholder='Address Line 1' className='border-[0] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]' />
                                                </div>
                                                <div className="w-full">
                                                    <input type="text" placeholder='Address Line 2' className='border-[0] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]' />
                                                </div>
                                                <div className='flex w-full gap-[10px]'>
                                                    <div className="w-full">
                                                        <input type="text" placeholder='City' className='border-[0] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]' />
                                                    </div>
                                                    <div className="w-full">
                                                        <input type="text" placeholder='State' className='border-[0] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]' />
                                                    </div>
                                                </div>
                                                <div className="w-full">
                                                    <input type="number" placeholder='Postal Code' className='border-[0] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]' />
                                                </div>
                                            </div>
                                            <div className="border-t border-solid border-blue-900"></div>
                                            <div className="drop-address px-[20px]">
                                                <h5 className="text-[28px] leading-[33px] mb-[20px] text-[#0174EB] font-[700] xxs:text-[22px] xxs:leading-[normal] xxs:mb-[0px]">Drop Address</h5>
                                                <div className="w-full">
                                                    <input type="text" placeholder='Address Line 1' className='border-[0] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]' />
                                                </div>
                                                <div className="w-full">
                                                    <input type="text" placeholder='Address Line 2' className='border-[0] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]' />
                                                </div>
                                                <div className='flex w-full gap-[10px]'>
                                                    <div className="w-full">
                                                        <input type="text" placeholder='City' className='border-[0] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]' />
                                                    </div>
                                                    <div className="w-full">
                                                        <input type="text" placeholder='State' className='border-[0] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]' />
                                                    </div>
                                                </div>
                                                <div className="w-full">
                                                    <input type="number" placeholder='Postal Code' className='border-[0] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]' />
                                                </div>
                                            </div>
                                            <div className="mx-[20px] pb-[20px]">
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    transition={{ duration: 0.3, ease: "easeInOut" }}
                                                    
                                                    className='rounded-[36px] bg-[#044384] px-[40px] py-[15px] xxs:px-[20px] xxs:py-[12px]'>
                                                    <span className='text-[20px] leading-[23px] text-[#FFFFFF] font-bold xxs:text-[16px]'>Submit</span>
                                                </motion.button>
                                            </div>
                                        </form>
                                    </motion.div>
                                </div>
                                <div className="ms-2 text-sm">
                                    <label htmlfoF="Expedited-checkbox" className="text-[28px] text-[#000000] leading-[32px] font-[700] xs:text-[22px] xs:leading-[normal] xxs:text-[20px]">Expedited Service</label>
                                    <p id="helper-checkbox-text" className="text-[20px] text-[#000000] leading-[24px] font-[400] xs:text-[16px] xs:leading-[normal]">less than 7 days</p>
                                    <p id="helper-checkbox-text" className="text-[20px] text-[#000000] leading-[24px] font-[400] xs:text-[16px] xs:leading-[normal]">(Advance Notice)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center py-[20px] border-b-[1px] border-b-[#FFFFFF] xs:flex-col xs:gap-[10px]">
                        <div className="title min-w-[230px] w-[230px] mr-[10px] xs:text-center">
                            <h4 className='text-[#FFFFFF] text-[24px] leading-[28px] font-[700]'>Booking Options:</h4>
                        </div>
                        <div className="flex flex-wrap gap-[10px]">
                            <div className="flex pl-[30px] pr-[10px] py-[20px] rounded-[15px] bg-[#82BFFF] w-[331px] xs:w-[auto] xs:px-[20px]">
                                <div className="flex mt-1">
                                    <input
                                        id="nanny-only"
                                        name="radioGroup"
                                        type="radio"
                                        value="nanny-only"
                                        checked={selectedOption === 'nanny-only'}
                                        onChange={handleOptionChange}
                                        className="w-[24px] h-[24px] border-black focus:border-black focus:[box-shadow:none] checked:border-black checked:bg-blue-500 xs:w-[19px] xs:h-[19px]"
                                    />
                                </div>
                                <div className="ms-2 text-sm">
                                    <label htmlFor="nanny-only" className="text-[28px] text-[#000000] leading-[32px] font-[700] xs:text-[22px] xs:leading-[normal] xxs:text-[20px]">
                                        Nanny Only
                                    </label>
                                </div>
                            </div>

                            <div className="flex pl-[30px] pr-[10px] py-[20px] rounded-[15px] bg-[#82BFFF] w-[331px] xs:w-[auto] xs:px-[20px]">
                                <div className="flex mt-1">
                                    <input
                                        id="nanny-and-flight"
                                        name="radioGroup"
                                        type="radio"
                                        value="nanny-and-flight"
                                        checked={selectedOption === 'nanny-and-flight'}
                                        onChange={handleOptionChange}
                                        className="w-[24px] h-[24px] border-black focus:border-black focus:[box-shadow:none] checked:border-black checked:bg-blue-500 xs:w-[19px] xs:h-[19px]"
                                    />
                                </div>
                                <div className="ms-2 text-sm">
                                    <label htmlFor="nanny-and-flight" className="text-[28px] text-[#000000] leading-[32px] font-[700] xs:text-[22px] xs:leading-[normal] xxs:text-[20px]">
                                        Nanny and Flight
                                    </label>
                                </div>
                            </div>

                            <div className="flex pl-[30px] pr-[10px] py-[20px] rounded-[15px] bg-[#82BFFF] w-[331px] xs:w-[auto] xs:px-[20px]">
                                <div className="flex mt-1">
                                    <input
                                        id="nanny-and-flight-and-car"
                                        name="radioGroup"
                                        type="radio"
                                        value="nanny-and-flight-and-car"
                                        checked={selectedOption === 'nanny-and-flight-and-car'}
                                        onChange={handleOptionChange}
                                        className="w-[24px] h-[24px] border-black focus:border-black focus:[box-shadow:none] checked:border-black checked:bg-blue-500 xs:w-[19px] xs:h-[19px]"
                                    />
                                </div>
                                <div className="ms-2 text-sm">
                                    <label htmlFor="nanny-and-flight-and-car" className="text-[28px] text-[#000000] leading-[32px] font-[700] xs:text-[22px] xs:leading-[normal] xxs:text-[20px]">
                                        Nanny and Flight and Car
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center py-[20px] border-b-[1px] border-b-[#FFFFFF] xs:flex-col xs:gap-[10px]">
                        <div className="title min-w-[230px] w-[230px] mr-[10px] xs:text-center">
                            <h4 className='text-[#FFFFFF] text-[24px] leading-[28px] font-[700]'>Order Options:</h4>
                        </div>
                        <div className="flex flex-wrap gap-[10px]">
                            <div className="flex pl-[30px] pr-[10px] py-[20px] rounded-[15px] bg-[#82BFFF] w-[331px] xs:w-[auto] xs:px-[20px]">
                                <div className="flex mt-1">
                                    <input id="helper-checkbox" aria-describedby="helper-checkbox-text" type="checkbox" value="" className="w-[24px] h-[24px] border-black focus:border-black focus:[box-shadow:none] checked:border-black checked:bg-blue-500 xs:w-[19px] xs:h-[19px]" />
                                </div>
                                <div className="ms-2 text-sm">
                                    <label htmlfoF="helper-checkbox" className="text-[28px] text-[#000000] leading-[32px] font-[700] xs:text-[22px] xs:leading-[normal] xxs:text-[20px]">Nanny Pick-up from Breeder required</label>
                                    <p id="helper-checkbox-text" className="text-[20px] text-[#000000] leading-[24px] font-[400] xs:text-[16px] xs:leading-[normal]">(Fixed Fee)</p>
                                </div>
                            </div>
                            <div className="flex pl-[30px] pr-[10px] py-[20px] rounded-[15px] bg-[#82BFFF] w-[331px] xs:w-[auto] xs:px-[20px]">
                                <div className="flex mt-1">
                                    <input id="helper-checkbox" aria-describedby="helper-checkbox-text" type="checkbox" value="" className="w-[24px] h-[24px] border-black focus:border-black focus:[box-shadow:none] checked:border-black checked:bg-blue-500 xs:w-[19px] xs:h-[19px]" />
                                </div>
                                <div className="ms-2 text-sm">
                                    <label htmlfoF="helper-checkbox" className="text-[28px] text-[#000000] leading-[32px] font-[700] xs:text-[22px] xs:leading-[normal] xxs:text-[20px]">Nanny Vet Certificate Service</label>
                                    <p id="helper-checkbox-text" className="text-[20px] text-[#000000] leading-[24px] font-[400] xs:text-[16px] xs:leading-[normal]">(Fixed Fee)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-[10px] -mb-[55px] flex items-center justify-center xxs:-mb-[35px]">
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        
                        className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[5] xs:px-[25px] xxs:py-[12px] xxs:px-[20px]' onClick={props.closehangleCoupon}>
                        <span className='text-[32px] leading-[38px] font-[900] text-[#FFFFFF] xs:text-[20px] xxs:text-[14px] uppercase xxs:leading-[normal] xxs:font-[500]'>Search</span>
                    </motion.button>
                </div>
            </motion.div>
        </>
    )
}

export default BookingDelivery