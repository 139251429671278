// import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import VerifyIdentity from './pages/Login/VerifyIdentity';
import EnterCode from './pages/Login/EnterCode';
import CreateAccount from './pages/Register/CreateAccount';
import VerifyAccount from './pages/Register/VerifyAccount';
import WelcomeScreen from './pages/Register/WelcomeScreen';
import NannyLanding from './pages/Landing/NannyLanding';
import StrapiApi from './pages/Strapi/StrapiApi';
import Dashboard from './pages/Dashboard/Dashboard';
import PetShipping from './pages/PetShipping/PetShipping';
import PetTrack from './pages/PetTrack/PetTrack';
import Policies from './pages/Policies/Policies';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import FaqPage from './pages/FaqPage/FaqPage';
import MeidaCentre from './pages/MeidaCentre/MeidaCentre';


function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/petShipping"
          element={
            <>
              <Header />
              <PetShipping />
              <Footer />
            </>
          }
        />
        <Route
          path="/PetTrack"
          element={
            <>
              <Header />
              <PetTrack />
              <Footer />
            </>
          }
        />
        <Route
          path="/MeidaCentre"
          element={
            <>
              <Header />
              <MeidaCentre />
              <Footer />
            </>
          }
        />
        <Route
          path="/services"
          element={
            <>
              <Header />
              <Policies id="1" />
              <Footer />
            </>
          }
        />
        <Route
          path="/privacy"
          element={
            <>
              <Header />
              <Policies id="2" />
              <Footer />
            </>
          }
        />
        <Route
          path="/shipping"
          element={
            <>
              <Header />
              <Policies id="3" />
              <Footer />
            </>
          }
        />
        <Route
          path="/refund"
          element={
            <>
              <Header />
              <Policies id="4" />
              <Footer />
            </>
          }
        />
        <Route
          path="/aboutUs"
          element={
            <>
              <Header />
              <AboutUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/contactUs"
          element={
            <>
              <Header />
              <ContactUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/faq"
          element={
            <>
              <Header />
              <FaqPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <Header />
              <Login />
              <Footer />
            </>
          }
        />
        <Route
          path="/login/admin"
          element={
            <>
              <Header />
              <Login role="admin" />
              <Footer />
            </>
          }
        />
        <Route
          path="/verifyidentity"
          element={
            <>
              <Header />
              <VerifyIdentity />
              <Footer />
            </>
          }
        />
        <Route
          path="/entercode"
          element={
            <>
              <Header />
              <EnterCode />
              <Footer />
            </>
          }
        />
        <Route path="/CreateAccount" element={<CreateAccount />} />
        <Route path="/verifyaccount" element={<VerifyAccount />} />
        <Route path="/welcomescreen" element={<WelcomeScreen />} />
        <Route path="/nannyLanding" element={<NannyLanding />} />
        <Route path="/StrapiApi" element={<StrapiApi />} />
        <Route path="/dashboard" element={
          <>
            <Header />
            <Dashboard />
          </>
        } />
      </Routes>
    </Router>
  );
}

export default App;