import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import gsap from 'gsap';
import { useInView } from 'react-intersection-observer';
import LazyLoad from 'react-lazy-load';

const RealTimeTracking = (props) => {
    const [ref, inView] = useInView({ threshold: 0.3 });
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);
    useEffect(() => {
        if (inView) {
            gsap.from(textRef1.current, {
                opacity: 0,
                x: -50,
                duration: 1,
                delay: 0.5,
            });

            gsap.from(textRef2.current, {
                opacity: 0,
                x: 50,
                duration: 1,
                delay: 0.5,
            });
        }
    }, [inView]);
    return (
        <div className="overflow-hidden">
            <div ref={ref} className="mx-auto flex flex-col items-center bg-linear-custom max-md:px-5 pt-[25px] pb-[25px] mb-[60px]  md:mb-0">
                <div className="max-w-[1597px] px-[20px] flex gap-5 w-full max-md:flex-col max-md:gap-0 max-w-[1530px] ml-auto mr-auto xs:px-[20px] xs:flex-wrap">
                    <div className="flex xs:flex-col">
                        <div className="w-full w-1/2 px-4 xs:px-0 flex items-center justify-end xs:justify-center relative top-[90px] md:top-0">
                            <motion.div
                                className="p-6 pb-0 md:mb-3"
                                initial={{ opacity: 0, x: -50 }}
                                animate={inView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5 }}
                            >
                                <LazyLoad offset={100} once>
                                    <motion.img
                                        src={"https://strapi.incabinpets.com" + props.data.image.data.attributes.url}
                                        alt="image"
                                        initial={{ opacity: 0, x: -50 }}
                                        animate={inView ? { opacity: 1, x: 0 } : {}}
                                        transition={{ duration: 0.5 }}
                                    />
                                </LazyLoad>
                            </motion.div>
                        </div>
                        <div className="w-full w-1/2 px-4 xs:px-0 flex items-center xs:justify-center">
                            <motion.div
                                className="w-[85%] sm:w-[100%] xs:w-full"
                                initial={{ opacity: 0, x: 50 }}
                                animate={inView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5 }}
                            >
                                <h2
                                    ref={textRef2}
                                    className="max-w-[565px] text-[85px] 2xl:text-[76px] xl:text-[65px] lg:text-[45px] leading-[90px] 2xl:leading-[80px] xl:leading-[70px] lg:leading-[55px] font-[900] text-[#fff] mb-4 xxs:text-[35px] xxs:leading-[1]"
                                >
                                    <motion.span
                                        initial={{ opacity: 0, x: -50 }}
                                        animate={inView ? { opacity: 1, x: 0 } : {}}
                                        transition={{ duration: 0.25, delay: inView ? 0.25 : 0 }}
                                    >
                                        {props.data.title}
                                    </motion.span>
                                </h2>
                                <p
                                    ref={textRef1}
                                    className="text-[25px] xl:text-[22px] lg:text-[20px]  xxs:text-[17px] font-[500] text-[#fff] mb-4"
                                >
                                    {props.data.description}
                                </p>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RealTimeTracking
