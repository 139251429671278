import React, { useState } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import BackArrow from '../../assets/images/back-arrow.svg';
import facebook from '../../assets/images/facebook.svg';
import google from '../../assets/images/google.svg';
import apple from '../../assets/images/apple.svg';
import { motion } from 'framer-motion';
import LazyLoad from 'react-lazy-load';
function CreateAccount() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [subscribeNewsletter, setSubscribeNewsletter] = useState(false);
  const [errors, setErrors] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
      isValid = false;
    }

    if (!password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = "Please confirm your password";
      isValid = false;
    }

    if (!firstName) {
      newErrors.firstName = "First Name is required";
      isValid = false;
    }

    if (!lastName) {
      newErrors.lastName = "Last Name is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const createAccount = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('form submitted');
    } else {
      console.log("Error");
    }
  };

  return (
    <>
      <header className="bg-white !border-[#C7C7C7] border-b-[1px] xs:border-b-[0] border-[solid]">
        <div className="container mx-auto max-w-[1597px] p-[20px] flex items-center justify-between">
          <div className="flex-shrink-0">
            <Link to="/" className="text-gray-500 hover:text-gray-700">
              <LazyLoad offset={100} once>
                <img className="md:max-w-[25px] xxs:max-w-[22px]" src={BackArrow} alt="attow" />
              </LazyLoad>
            </Link>
          </div>
          <div className="flex-grow text-center">
            <Link to="/">
              <LazyLoad offset={100} once>
                <img className="max-w-[208px] md:max-w-[180px] xxs:max-w-[160px] inline-block" src={logo} alt="Logo" />
              </LazyLoad>
            </Link>
          </div>

          <div className="flex-shrink-0"></div>
        </div>
      </header>

      <div className="flex items-center justify-center bg-white pt-[60px] xs:pt-[40px] xxs:pt-[30px] pb-[80px] xs:pb-[60px] xxs:pb-[50px]">
        <div className="max-w-[780px] w-full px-[30px] xxs:px-[20px] xxs:px-[20px]">
          <div className="main-heading pb-3 xxs:pb-2">
            <h2 className="text-[#0174EB] font-[900] text-[64px] md:text-[54px] xs:text-[40px] xxs:text-[30px] leading-[64px] md:leading-[54px] xs:leading-[40px] xxs:leading-[30px] text-center mb-[30px] xxs:mb-[20px]">
              Create an account
            </h2>
            <ul className="flex items-center justify-center mb-[25px] gap-[15px] xxs:mb-3">
              <li><Link to="/"><LazyLoad offset={100} once><img className="w-[32px] h-[32px] xs:w-[27px] xs:h-[27px]" src={facebook} alt="facebook" /></LazyLoad></Link></li>
              <li><Link to="/"><LazyLoad offset={100} once><img className="w-[32px] h-[32px] xs:w-[27px] xs:h-[27px]" src={google} alt="facebook" /></LazyLoad></Link></li>
              <li><Link to="/"><LazyLoad offset={100} once><img className="w-[32px] h-[32px] xs:w-[27px] xs:h-[27px]" src={apple} alt="facebook" /></LazyLoad></Link></li>
            </ul>
            <p className="text-center text-[24px] leading-[29px] text-[#000]">or continue with</p>
          </div>
          <form className="mt-8 xs:mt-4 space-y-6" onSubmit={createAccount}>
            {/* <div className="bg-[#BD0A0A] w-full h-[95px] xs:h-[120px] xxs:h-[100px] px-[20px] rounded-[17px] flex items-center xs:items-start justify-left xs:p-[15px]">
              <p className="text-[30px] md:text-[25px] xs:text-[22px] xxs:text-[18px] text-[#fff] leading-[35px] md:leading-[30px] xxs:leading-[25px] text-left">Error Message</p>
            </div> */}
            <div className="rounded-md shadow-sm -space-y-px">
              <div className="pb-5">
                <label htmlFor="email" className="sr-only">Email address</label>
                <input
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  autoComplete="email"

                  className="appearance-none rounded-[17px] w-full h-[90px] md:h-[80px] xxs:h-[65px] relative block border-[2px] border-[solid] placeholder-[#7B7B7B] !border-[#D9D9D9] !bg-[#FFFFFF] !text-[#7B7B7B] px-[30px] xxs:px-[20px] text-[40px] leading-[47px] md:text-[30px] md:leading-[37px] xxs:text-[20px] xxs:leading-[27px] focus:ring-0 placeholder-[300] font-[300]"
                  placeholder="Your Email ID"
                />
              </div>
              {errors && <p className="text-[#B70000] text-[24px] leading-[28px] xxs:text-[18px] leading-[28px] xxs:leading-[22px] left-0 mt-0 pb-5">{errors.email}</p>}
              <div className="flex xs:flex-wrap space-x-4 xs:space-x-0">
                <div className="pb-5 w-1/2 xs:w-full">
                  <label htmlFor="firstName" className="sr-only">First Name</label>
                  <input
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    name="firstName"
                    type="text"

                    className="appearance-none rounded-[17px] w-full h-[90px] md:h-[80px] xxs:h-[65px] relative block border-[2px] border-[solid] placeholder-[#7B7B7B] !border-[#D9D9D9] !bg-[#FFFFFF] !text-[#7B7B7B] px-[30px] xxs:px-[20px] text-[40px] leading-[47px] md:text-[30px] md:leading-[37px] xxs:text-[20px] xxs:leading-[27px] focus:ring-0 placeholder-[300] font-[300]"
                    placeholder="First Name"
                  />
                </div>

                <div className="pb-5 w-1/2 xs:w-full xs:ml-0">
                  <label htmlFor="lastName" className="sr-only">Last Name</label>
                  <input
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    name="lastName"
                    type="text"

                    className="appearance-none rounded-[17px] w-full h-[90px] md:h-[80px] xxs:h-[65px] relative block border-[2px] border-[solid] placeholder-[#7B7B7B] !border-[#D9D9D9] !bg-[#FFFFFF] !text-[#7B7B7B] px-[30px] xxs:px-[20px] text-[40px] leading-[47px] md:text-[30px] md:leading-[37px] xxs:text-[20px] xxs:leading-[27px] focus:ring-0 placeholder-[300] font-[300]"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="flex xs:flex-wrap space-x-4 xs:space-x-0">
                <div className="w-1/2 xs:w-full">
                  {errors && <p className="text-[#B70000] text-[24px] leading-[28px] xxs:text-[18px] leading-[28px] xxs:leading-[22px] left-0 mt-0 pb-5">{errors.firstName}</p>}
                </div>
                <div className="w-1/2 xs:w-full">
                  {errors && <p className="text-[#B70000] text-[24px] leading-[28px] xxs:text-[18px] leading-[28px] xxs:leading-[22px] left-0 mt-0 pb-5">{errors.lastName}</p>}
                </div>
              </div>
              <div className="pb-5 relative">
                <label htmlFor="password" className="sr-only">Password</label>
                <div className="relative">
                  <input
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"

                    className="appearance-none rounded-[17px] w-full h-[90px] md:h-[80px] xxs:h-[65px] relative block border-[2px] border-[solid] placeholder-[#7B7B7B] !border-[#D9D9D9] !bg-[#FFFFFF] !text-[#7B7B7B] px-[30px] xxs:px-[20px] text-[40px] leading-[47px] md:text-[30px] md:leading-[37px] xxs:text-[20px] xxs:leading-[27px] focus:ring-0 placeholder-[300] font-[300]"
                    placeholder="Password"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    {showPassword ? (
                      <HiEyeOff className="h-[28px] w-[44px] xxs:h-[22px] xxs:w-[36px] text-[#000] cursor-pointer" onClick={togglePasswordVisibility} />
                    ) : (
                      <HiEye className="h-[28px] w-[44px] xxs:h-[22px] xxs:w-[36px] text-[#000] cursor-pointer" onClick={togglePasswordVisibility} />
                    )}
                  </div>
                </div>
              </div>
              {errors && <p className="text-[#B70000] text-[24px] leading-[28px] xxs:text-[18px] leading-[28px] xxs:leading-[22px] left-0 mt-0 pb-5">{errors.password}</p>}
              <div className="pb-5 relative">
                <label htmlFor="confirmPassword" className="sr-only">Confirm Password</label>
                <div className="relative">
                  <input
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    autoComplete="new-password"

                    className="appearance-none rounded-[17px] w-full h-[90px] md:h-[80px] xxs:h-[65px] relative block border-[2px] border-[solid] placeholder-[#7B7B7B] !border-[#D9D9D9] !bg-[#FFFFFF] !text-[#7B7B7B] px-[30px] xxs:px-[20px] text-[40px] leading-[47px] md:text-[30px] md:leading-[37px] xxs:text-[20px] xxs:leading-[27px] focus:ring-0 placeholder-[300] font-[300]"
                    placeholder="Confirm Password"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    {showConfirmPassword ? (
                      <HiEyeOff className="h-[28px] w-[44px] xxs:h-[22px] xxs:w-[36px] text-[#000] cursor-pointer" onClick={toggleConfirmPasswordVisibility} />
                    ) : (
                      <HiEye className="h-[28px] w-[44px] xxs:h-[22px] xxs:w-[36px] text-[#000] cursor-pointer" onClick={toggleConfirmPasswordVisibility} />
                    )}
                  </div>
                </div>

              </div>
              {errors && <p className="text-[#B70000] text-[24px] leading-[28px] xxs:text-[18px] leading-[28px] xxs:leading-[22px] left-0 mt-0 pb-5">{errors.confirmPassword}</p>}
              <div className="mt-6 flex items-center justify-between">
                <p className="text-[24px] leading-[29px] xxs:text-[18px] xxs:leading-[22px] text-[#000] mb-[10px]">Password strength</p>
                <p className="text-[24px] xxs:text-[18px] leading-[29px] xxs:leading-[22px] text-[#000] mb-[10px]">Strong</p>
              </div>
              <div className="relative h-[13px] mt-1 w-full bg-gray-200 rounded-[7px]">
                <div className="absolute left-0 top-0 h-[13px] bg-[#3A8D6B] rounded-[7px]" style={{ width: '50%' }}></div>
              </div>
              <div className="text-[24px] pt-[20px] text-[#828282] leading-[38px] mt-[10px]">
                <ul className="list-disc pl-[22px]">
                  <li className="md:text-[20px] xxs:text-[18px]">Includes 9-64 characters</li>
                  <li className="md:text-[20px] xxs:text-[18px]">Combines letters and numbers</li>
                  <li className="md:text-[20px] xxs:text-[18px]">A special character ~ # @ $ % & ! * _ ? ^ -</li>
                </ul>
              </div>
            </div>
            <div className="text-left">
              <div className="flex items-center mb-3">
                <input
                  id="keepSignedInCheckbox"
                  checked={keepSignedIn}
                  onChange={() => setKeepSignedIn(!keepSignedIn)}
                  name="keepSignedIn"
                  type="checkbox"
                  className="h-[24px] w-[24px] text-indigo-600 focus:ring-transparent focus:outline-none border-[2px] border-[solid] !border-[#000000] rounded-[2px]"
                  style={{ backgroundColor: keepSignedIn ? '#0174EB' : '#fff' }}
                />
                <label htmlFor="keepSignedInCheckbox" className="ml-3 block text-[24px] md:text-[20px] xxs:text-[18px] text-[#000]">
                  Keep me signed in
                </label>
              </div>
              <div className="flex items-center mb-3">
                <input
                  id="twoFactorAuthCheckbox"
                  checked={twoFactorAuth}
                  onChange={() => setTwoFactorAuth(!twoFactorAuth)}
                  name="twoFactorAuth"
                  type="checkbox"
                  className="h-[24px] w-[24px] text-indigo-600 focus:ring-transparent focus:outline-none border-[2px] border-[solid] !border-[#000000] rounded-[2px]"
                  style={{ backgroundColor: twoFactorAuth ? '#0174EB' : '#fff' }}
                />
                <label htmlFor="twoFactorAuthCheckbox" className="ml-3 block text-[24px] md:text-[20px] xxs:text-[18px] text-[#000]">
                  Enable Two-factor Authentication (2FA)
                </label>
              </div>
              <div className="flex items-center mb-3">
                <input
                  id="newsletterCheckbox"
                  checked={subscribeNewsletter}
                  onChange={() => setSubscribeNewsletter(!subscribeNewsletter)}
                  name="subscribeNewsletter"
                  type="checkbox"
                  className="h-[24px] w-[24px] text-indigo-600 focus:ring-transparent focus:outline-none border-[2px] border-[solid] !border-[#000000] rounded-[2px]"
                  style={{ backgroundColor: subscribeNewsletter ? '#0174EB' : '#fff' }}
                />
                <label htmlFor="newsletterCheckbox" className="ml-3 block text-[24px] md:text-[20px] xxs:text-[18px] text-[#000]">
                  Subscribe to our Newsletter
                </label>
              </div>
            </div>
            <div className="select-checkbox-text">
              <p className="text-[22px] md:text-[20px] xxs:text-[18px] text-[#828282] leading-[32px]">Selecting this checkbox will keep you signed into your account on this device until you sign out. Do not select this on shared devices.</p>
            </div>
            <div className="flex items-center justify-left xs:flex-wrap gap-[40px] xs:gap-[20px]">
              <div className="flex items-center">
                <input
                  id="acceptTermsCheckbox"
                  checked={acceptTerms}
                  onChange={() => setAcceptTerms(!acceptTerms)}
                  name="acceptTerms"
                  type="checkbox"
                  className="h-[24px] w-[24px] text-indigo-600 focus:ring-transparent focus:outline-none border-[2px] border-[solid] !border-[#000000] rounded-[2px]"
                  style={{ backgroundColor: acceptTerms ? '#0174EB' : '#fff' }}
                />
                <label htmlFor="acceptTermsCheckbox" className="ml-3 block text-[24px] md:text-[20px] xxs:text-[18px] text-[#000]">
                  <Link to="/" className="text-[#0174EB] underline">I accept the Terms of Use</Link>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="privacyPolicyCheckbox"
                  checked={acceptPrivacyPolicy}
                  onChange={() => setAcceptPrivacyPolicy(!acceptPrivacyPolicy)}
                  name="privacyPolicyCheckbox"
                  type="checkbox"
                  className="h-[24px] w-[24px] text-indigo-600 focus:ring-transparent focus:outline-none border-[2px] border-[solid] !border-[#000000] rounded-[2px]"
                  style={{ backgroundColor: acceptPrivacyPolicy ? '#0174EB' : '#fff' }}
                />
                <label htmlFor="privacyPolicyCheckbox" className="ml-3 block text-[24px] md:text-[20px] xxs:text-[18px] text-[#000]">
                  <Link to="/" className="text-[#0174EB] underline">I accept Privacy Policy</Link>
                </label>
              </div>
            </div>
            <div>
              <motion.input
                type="submit"
                className="w-full flex justify-center items-center h-[72px] xxs:h-[60px] bg-[#0174EB] border border-transparent rounded-[15px] shadow-sm text-[32px] xs:text-[27px] xxs:text-[22px] font-medium text-white focus:outline-none focus:ring-2 focus:ring-none focus:ring-none cursor-pointer"
                value="Create Account"
                whileHover={{ scale: 1.1, backgroundColor: "#0174EB73" }}
                whileTap={{ scale: 0.9 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                
              />
            </div>
          </form>
          <div className="text-center mt-[40px] md:mt-[30px]">
            <p className="text-[24px] leading-[28px] md:text-[20px] xxs:text-[18px] md:leading-[24px] text-[#000]">
              Already have an account?
              <Link to="/login" className="text-[#0174EB] font-[700]"> Login</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateAccount;
