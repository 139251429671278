import React from 'react';
import { useQuery } from 'react-query';
import Loader from '../../Components/Loader'
import { fetchFaqData } from '../../Strapi/UseStrapiApi';
import FaqSection from '../../Components/FaqSection/FaqSection';
import AccordionSection from '../../Components/AccordionSection/AccordionSection';

const FaqPage = () => {
    const { data, isLoading, isError } = useQuery('faqData', fetchFaqData, {
        refetchOnWindowFocus: false
    });

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div>Error: {isError.message}</div>;
    }
    return (
        <>
            <FaqSection />
            <AccordionSection items={data.accordions} />
        </>
    )
}

export default FaqPage;
