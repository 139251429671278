import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';
import LazyLoad from 'react-lazy-load';

const AboutColumn = ({ title, description, image, reverse = false }) => {
    const [ref, inView] = useInView({ threshold: 0.3 });
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);

    useEffect(() => {
        if (inView) {
            gsap.from(textRef1.current, {
                opacity: 0,
                x: reverse ? 50 : -50,
                duration: 1,
                delay: 0.5,
            });

            gsap.from(textRef2.current, {
                opacity: 0,
                x: reverse ? -50 : 50,
                duration: 1,
                delay: 0.5,
            });
        }
    }, [inView, reverse]);

    const flexDirectionClass = reverse ? 'flex xs:flex-col flex-row-reverse' : 'flex xs:flex-col';
    const justifyDirectionClass = reverse ? 'justify-end xs:justify-center' : 'justify-start xs:justify-center';

    return (
        <div ref={ref} className="container mx-auto xs:mt-5 max-w-[1597px] overflow-hidden px-[20px]">
            <div className={`w-full mx-auto overflow-hidden ${inView ? 'animate' : ''}`}>
                <div className={flexDirectionClass}>
                    <div className={`w-full w-1/2 px-4 xs:px-0 flex items-center ${justifyDirectionClass}`}>
                        <motion.div
                            className="bg-white w-[95%] sm:w-[100%] xs:w-full"
                            initial={{ opacity: 0, x: reverse ? 50 : -50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5 }}
                        >
                            <h2
                                ref={textRef2}
                                className="max-w-[467px] text-[85px] 2xl:text-[76px] xl:text-[65px] lg:text-[50px] md:text-[40px] leading-[90px] 2xl:leading-[80px] xl:leading-[70px] lg:leading-[55px] font-[900] text-[#0174EB] mb-4 xxs:text-[34px] xxs:leading-[1]"
                            >
                                {title}
                            </h2>
                            <p
                                ref={textRef1}
                                className="text-[22px] xl:text-[20px] lg:text-[18px] xxs:text-[17px] leading-[1.2] font-[400] text-[#6D6D6D] mb-4"
                            >
                                {description}
                            </p>
                        </motion.div>
                    </div>
                    <div className={`w-full w-1/2 px-4 xs:px-0 flex items-center ${justifyDirectionClass}`}>
                        <motion.div
                            className="bg-white p-6 pb-0"
                            initial={{ opacity: 0, x: reverse ? -50 : 50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5 }}
                        >
                            <LazyLoad offset={100} once>
                                <motion.img
                                    src={image}
                                    alt={title}
                                    initial={{ opacity: 0, x: reverse ? -50 : 50 }}
                                    animate={inView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.5 }}
                                />
                            </LazyLoad>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutColumn;
