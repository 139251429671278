import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import shippingImg1 from '../../assets/images/shipping-image-map.png';

const HowShipPet = (props) => {
    console.log(props.data);
    const [ref, inView] = useInView({ threshold: 0.3 });
    return (
        <div ref={ref} className="mx-auto flex flex-col items-center bg-linear-custom max-md:px-5 pt-[40px] pb-[60px]">
            <div className="max-w-[1597px] px-[20px] flex gap-5 w-full max-md:flex-col max-md:gap-0 max-w-[1530px] ml-auto mr-auto xs:px-[20px] xs:flex-wrap">
                <div className="flex flex-col relative w-[40%] max-md:ml-0 sm:pl-[0px] xs:w-full xxs:w-full">
                    <motion.div className="-mt-[160px] lg:-mt-[0]" initial={{ opacity: 0, x: -50 }} animate={inView ? { opacity: 1, x: 0 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                        <div className="flex flex-col self-stretch my-auto font-black max-md:mt-10 max-md:max-w-full">
                            <div className="max-md:max-w-full">
                                <img src={shippingImg1} alt="shippingImg" />
                            </div>
                            <div className="relative z-[7] -mt-[50px] xs:-mt-[0] xs:text-center">
                                <span className='mt-0 text-[40px] block leading-[48px] mb-[5px] font-[500] text-[#fff] sm:text-[35px] sm:leading-[normal] xs:text-[28px] xxs:text-[20px]'>{props.data.sub_title}</span>
                                <h2 className='text-[86px] 2xl:text-[75px] xl:text-[62px] lg:text-[55px] md:text-[50px] text-[#fff] font-[900] leading-[64px] sm:text-[40px] sm:leading-[normal] xs:text-[42px] xxs:text-[34px]'>{props.data.title}</h2>
                            </div>
                        </div>
                    </motion.div>
                </div>
                <div className="flex ml-5 w-[60%] max-md:ml-0 z-[7] xs:w-full xxs:w-full xxs:ml-[0]">
                    <motion.div className="w-full" initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                        <div style={{ background: 'linear-gradient(90deg, #0174EB -101.41%, #014285 114.74%)', }} className="max-md:max-w-full w-full -mb-[190px] pt-[40px] rounded-[24px] p-[10px] lg:mb-[0] xs:pt-[0] xs:mb-[0] xs:pl-[0] xs:m-auto">
                            <iframe src="https://www.youtube.com/embed/5Peo-ivmupE?si=ZUPsLeuajVYqY7wo" className="max-w-full w-full h-[492px] xl:h-[350px] sm:h-[300px] xxs:h-[250px] rounded-[24px]" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default HowShipPet
