import React from 'react';
import { useQuery } from 'react-query';
import Loader from '../../Components/Loader';
import BannerSection from '../../Components/BannerSection/BannerSection';
import { fetchHomeData } from '../../Strapi/UseStrapiApi';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FiArrowUp } from 'react-icons/fi';
import TwoColumn from '../../Components/TwoColumn/TwoColumnSection';
import BookingSection from '../../Components/BookingSection/BookingSection';
import ContentFooter from '../../Components/ContentFooter/ContentFooter';
import PetTabs from '../../Components/PetTabs/PetTabs';
import CounterSection from '../../Components/CounterSection/CounterSection';

const Home = () => {
  const [twoColumn1Ref] = useInView({ threshold: 0.1 });
  const [bookingSectionRef, bookingSectionInView] = useInView({ threshold: 0.1 });
  const [contentFooterRef, contentFooterInView] = useInView({ threshold: 0.1 });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const { data, isLoading, isError } = useQuery('homeData', fetchHomeData, {
    refetchOnWindowFocus: false
  });

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Error: {isError.message}</div>;
  }

  return (
    <>
      {data && <BannerSection bannerData={data.bannerVideo} />}
      <PetTabs />
      {data.twoColumnContent.map(homeItem => (
        <motion.div ref={twoColumn1Ref} className="section" transition={{ duration: 0.5 }} key={homeItem.id}>
          <TwoColumn
            imageSrc={"https://strapi.incabinpets.com" + homeItem.image.data.attributes.url}
            altText="contentImage"
            toptitle={homeItem.TopContent != null ? homeItem.TopContent : ""}
            title={homeItem.heading}
            subtitle={homeItem.BottomContent}
            reverse={homeItem.id % 2 === 0 ? false : true}
          />
        </motion.div>
      ))}
      <motion.div ref={bookingSectionRef} className="section" animate={{ opacity: bookingSectionInView ? 1 : 0 }} transition={{ duration: 0.5 }}>
        <BookingSection boxContent={data.boxContent} />
      </motion.div>
      <motion.div ref={contentFooterRef} className="section" animate={{ opacity: contentFooterInView ? 1 : 0 }} transition={{ duration: 0.5 }}>
        <ContentFooter />
      </motion.div>
      <CounterSection counterSection={data.counterSection} />
      {(
        <motion.button className="fixed bottom-5 right-5 bg-[#0174eb] border border-[#0174eb] font-[900] text-white hover:bg-white hover:text-[#0174eb] text-[24px] px-2 py-2 rounded-full shadow" onClick={scrollToTop}>
          <FiArrowUp />
        </motion.button>
      )}
    </>
  );
};

export default Home;