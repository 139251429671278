import React from 'react';
import { motion } from 'framer-motion';

function EnterCode() {
  return (
    <>
        <div className="flex items-center justify-center bg-white pt-[120px] sm:pt-[50px] pb-[80px] xs:pb-[60px] xxs:pb-[50px]">
            <div className="max-w-[689px] px-[30px] xxs:px-[20px] w-full space-y-8">
                <div className="main-heading">
                    <h2 className="text-[#0174EB] font-[900] text-[64px] md:text-[54px] xs:text-[40px] xxs:text-[30px] leading-[64px] md:leading-[54px] xs:leading-[40px] xxs:leading-[30px] text-center mb-[30px] xxs:mb-[20px]">
                        Please enter the code
                    </h2>
                </div>
                <div className="w-full text-center">
                    <input
                        type="text"
                        placeholder="Enter code"
                        className="w-full max-w-[440px] xs:max-w-[380px] xxs:max-w-[300px] h-[95px] md:h-[80px] xxs:h-[65px] border-[solid] !border-[#D9D9D9] text-center px-[30px] xxs:px-[20px] text-[40px] leading-[47px] md:text-[30px] md:leading-[37px] xxs:text-[20px] xxs:leading-[27px] bg-white rounded-[17px] focus:outline-none focus:ring-2 focus:ring-blue-500 mb-[50px] xs:mb-[30px] xxs:mb-[20px]"
                    />
                    <motion.button 
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    
                    className="w-full max-w-[207px] xs:max-w-[180px] flex justify-center items-center h-[72px] xxs:h-[60px] bg-[#0174EB] border border-transparent rounded-[15px] shadow-sm text-[32px] xs:text-[27px] xxs:text-[22px] font-medium text-white focus:outline-none focus:ring-2 focus:ring-none focus:ring-none cursor-pointer hover:bg-[#0174EB73] focus:bg-[#0174EB73] ml-auto mr-auto">
                        Verify
                    </motion.button>
                </div>
            </div>
        </div>
    </>
  );
}

export default EnterCode;
