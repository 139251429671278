import React from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const GoogleMapsAutocomplete = () => {
    const handlePlaceSelect = (place) => {
        console.log(place);
    };

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyDREGT1UJTmCcgxmESBJZlXp0pNYTUcn5g"
            libraries={['places']}
        >
            <Autocomplete
                onLoad={handlePlaceSelect}
            >
                <input
                    type="text"
                    placeholder="Enter Address"
                />
            </Autocomplete>
        </LoadScript>
    );
};

export default GoogleMapsAutocomplete;
