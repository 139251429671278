import React, { useState } from 'react';
import gradientbanner from '../../assets/images/gradient-banner.png';
import LazyLoad from 'react-lazy-load';

const BannerSection = (props) => {

    const [videoVisible, setVideoVisible] = useState(false);
    const handleVideoVisible = () => {
        setVideoVisible(true);
    };
    return (
        <div className="home-page">
            <LazyLoad offset={200}>
                <div className="banner-video" onLoad={handleVideoVisible}>
                    {videoVisible && (
                        <video className="w-full max-w-[1920px] h-[652px] sm:h-[460px] xs:h-[300px] object-cover" autoPlay loop muted playsInline controls={false}>
                            <source src={"https://strapi.incabinpets.com" + props.bannerData.video.data.attributes.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                    {!videoVisible && (
                        <img className="w-full max-w-[1920px] h-[652px] sm:h-[460px] xs:h-[300px] object-cover" src={gradientbanner} alt="Placeholder" />
                    )}
                </div>
            </LazyLoad>
            <div className="bg-cover bg-center w-full h-[235px]" style={{ backgroundImage: `url(${gradientbanner})` }}></div>
        </div>
    );
};

export default BannerSection;