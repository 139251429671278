import React from 'react';
import { motion } from 'framer-motion';
import errorIcon from '../../assets/images/error-profile-icon.svg';
import LazyLoad from 'react-lazy-load';

const PetProfile = (props) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="charges-sec bg-[#0174EB] rounded-[26px] p-[15px] mt-[50px] xl:mt-[0] pt-[30px] lg:pt-[15px] sm:p-[0]">
                <div className="flex w-full items-center sm:relative sm:flex-col">
                    <div className="charges-content-sec pt-[0] pb-[30px] relative sm:static w-full">
                        <h4 className='text-[#FFFFFF] text-center text-[40px] leading-[47px] xxs:leading-[35px] font-bold mb-[20px] xxs:mb-[15px] lg:text-[35px] sm:text-[30px] xxs:text-[22px] uppercase'>Pet Profile</h4>
                        <form className="w-full mx-auto my-[30px] mb-[10px] sm:mt-[15px]">
                            <div className="w-full flex flex-wrap justify-between px-[20px] sm:px-[0] sm:mx-[0px]">
                                <div className="w-full max-w-[48%] md:max-w-[49%] xs:max-w-[100%] xs:mb-[20px]">
                                    <label htmlFor="website-admin" className="block mb-[10px] font-[700] sm:mb-[5px] text-[32px] leading-[38px] xl:text-[25px] lg:text-[20px] sm:text-[18px] xxs:text-[16px] lg:leading-[25px] sm:leading-[23px] xxs:leading-[18px] text-[#fff]">Pet Weight (In Pounds)</label>
                                    <div className="flex">
                                        <input type="text" className="h-[80px] xl:h-[70px] lg:h-[62px] sm:h-[55px] xxs:h-[50px] rounded-[15px] rounded-r-[0] bg-[#fff] border border-[2px] border-[#fff] text-[#0174EB] font-bold text-[40px] xl:text-[35px] lg:text-[30px] sm:text-[25px] xxs:text-[20px] px-[20px] xxs:px-[15px] xxs:py-[0] xl:px-[15px] block flex-1 min-w-0 w-full placeholder-[#0174EB] focus:ring-0 focus:outline-none focus:shadow-none focus:border-transparent" />
                                        <span className="inline-flex items-center text-[#0174EB] px-[20px] xl:px-[15px] xxs:px-[6px] bg-[#fff] rounded-[15px] rounded-l-[0] text-[32px] xl:text-[25px] lg:text-[20px] sm:text-[18px] border-l-2 border-solid !border-[#0174EB] capitalize font-[700]">
                                            <p>pounds</p>
                                            <span className="pl-[15px] xl:pl-[10px] xxs:pl-[6px]">
                                                <LazyLoad offset={100} once>
                                                    <img className="max-w-[40px] xl:max-w-[30px] lg:max-w-[25px] xxs:max-w-[20px]" src={errorIcon} alt="icon" />
                                                </LazyLoad>
                                            </span>
                                        </span>
                                    </div>
                                    <p className="w-full text-[#fff] text-[20px] leading-[23px] mt-[15px] sm:mt-[7px] xl:text-[17px] lg:text-[15px] sm:text-[13px] xl:leading-[21px] lg:leading-[18px] sm:leading-[16px] xl:mt-[10px]">The weight must not be greater than 25lbs. We only ship pets under 25lbs.</p>
                                </div>
                                <div className="w-full max-w-[48%] md:max-w-[49%] xs:max-w-[100%]">
                                    <label htmlFor="website-admin" className="block mb-[10px] sm:mb-[5px] text-[32px] leading-[38px] xl:text-[25px] lg:text-[20px] sm:text-[18px] xxs:text-[16px] lg:leading-[25px] sm:leading-[23px] xxs:leading-[18px] text-[#fff] font-[700]">Pet Height (In Inches)</label>
                                    <div className="flex">
                                        <input type="text" className="h-[80px] xl:h-[70px] lg:h-[62px] sm:h-[55px] xxs:h-[50px] rounded-[15px] rounded-r-[0] bg-[#fff] border border-[2px] border-[#fff] text-[#0174EB] font-bold text-[40px] xl:text-[35px] lg:text-[30px] sm:text-[25px] xxs:text-[20px] px-[20px] xxs:px-[15px] xxs:py-[0] xl:px-[15px] block flex-1 min-w-0 w-full placeholder-[#0174EB] focus:ring-0 focus:outline-none focus:shadow-none focus:border-transparent" />
                                        <span className="inline-flex items-center text-[#0174EB] px-[20px] xl:px-[15px] xxs:px-[6px] bg-[#fff] rounded-[15px] rounded-l-[0] text-[32px] xl:text-[25px] lg:text-[20px] sm:text-[18px] border-l-2 border-solid !border-[#0174EB] capitalize font-[700]">
                                            <p>inches</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="mt-[10px] w-full mb-[5px] absolute -bottom-[60px] flex gap-[15px] items-center justify-center xxs:-bottom-[35px]">
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                
                                className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[7] xs:px-[25px] xxs:px-[20px] xxs:py-[8px]' onClick={props.closehangleCoupon}
                            >
                                <span className='text-[32px] xl:text-[25px] leading-[38px] xl:leading-[30px] font-[900] text-[#FFFFFF] uppercase xs:text-[20px] xxs:text-[14px] xxs:leading-[normal] xxs:font-[500]'>Back</span>
                            </motion.button>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                
                                className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[5] xs:px-[25px] xxs:px-[20px] xxs:py-[8px]' onClick={props.handlePetsDetail}
                            >
                                <span className='text-[32px] xl:text-[25px] leading-[38px] xl:leading-[30px] font-[900] text-[#FFFFFF] uppercase xs:text-[20px] break-word xxs:text-[14px] xxs:leading-[normal] xxs:font-[500]'>Next</span>
                            </motion.button>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default PetProfile