import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LazyLoad from 'react-lazy-load';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import mainIcon from '../../assets/images/mail-icon.svg';
export const AnimatedHamburgerButton = (props) => {
  return (
    <div className="">
      <Nav navData={props.headerData} />
      <div className="fixed z-[99] right-[100px] xxs:right-[80px] top-[20px] xxs:top-[25px]">
        <motion.a
          href={props.headerData.booknow_link}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className="text-[20px] xxs:text-[15px] z-[10] relative xxs:leading-[15px] bg-[#0174EB] text-[#fff] w-full flex uppercase rounded-[100px] p-[10px] xxs:p-[9px] pl-[30px] pr-[30px] xxs:pl-[15px] xxs:pr-[15px]">{props.headerData.booknow_btn}</motion.a>
      </div>
    </div>
  );
};


const Nav = (props) => {

  const [active, setActive] = useState(false);
  return (
    <>
      <HamburgerButton active={active} setActive={setActive} />
      <AnimatePresence>{active && <LinksOverlay menuData={props.navData} />}</AnimatePresence>
    </>
  );
};


const LinksOverlay = (props) => {
  const navigate = useNavigate();
  const handleLogout = () => {

    axios.post('logout')
      .finally(() => {
        localStorage.removeItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer';
        navigate('/login', { replace: true });
      });
  }
  return (
    <nav className="fixed right-[20px] top-4 z-[99] h-[calc(100vh_-_32px)] w-[calc(100%_-_32px)] overflow-hidden">
      <Logo logo={props.menuData.Logo} />
      <LinksContainer links={props.menuData.menu_list} />
      <div>
        <ul className="flex items-center gap-2 mt-6 xxs:mt-0 p-2 md:pl-4 pl-20">
          {!localStorage.getItem('token') && (
            <>
              <motion.li
                initial={{ opacity: 0, y: -8 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.75,
                    duration: 0.5,
                    ease: "easeInOut",
                  },
                }}
                exit={{ opacity: 0, y: -8 }}
              >
                <motion.a
                  href={props.menuData.logins_buttons.signin_link}
                  // href="/login"
                  className="block px-5 py-2 xxs:py-1 bg-[#0174EB] text-sky-200 rounded-lg font-medium text-2xl h-fit hover:opacity-70 w-[150px] xxs:w-[110px] xxs:text-[18px] text-center"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                  {props.menuData.logins_buttons.signin_text}
                </motion.a>
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: -8 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.95,
                    duration: 0.5,
                    ease: "easeInOut",
                  },
                }}
                exit={{ opacity: 0, y: -8 }}
              >
                <motion.a
                  href={props.menuData.logins_buttons.signup_link}
                  className="block px-5 py-2 xxs:py-1 bg-[#0174EB] text-sky-200 rounded-lg font-medium text-2xl h-fit hover:opacity-70 w-[150px] xxs:w-[110px] xxs:text-[18px] text-center"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                  {props.menuData.logins_buttons.signup_text}
                </motion.a>
              </motion.li>
            </>
          )}
          {localStorage.getItem('token') && (
            <motion.li
              initial={{ opacity: 0, y: -8 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.95,
                  duration: 0.5,
                  ease: "easeInOut",
                },
              }}
              exit={{ opacity: 0, y: -8 }}
            >
              <motion.a
                href="#"
                className="block px-5 py-2 xxs:py-1 bg-[#0174EB] text-sky-200 rounded-lg font-medium text-2xl h-fit hover:opacity-70 w-[150px] xxs:w-[110px] xxs:text-[18px] text-center"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                onClick={handleLogout}
              >
                Logout
              </motion.a>
            </motion.li>
          )}
        </ul>
      </div>
      <motion.div className="p-2 md:pl-4 pl-20 max-w-screen-md">
        <div className="max-w-screen-md md:text-center max-md:text-center">
          <motion.p
            initial={{ opacity: 0, y: -8 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.75,
                duration: 0.5,
                ease: "easeInOut",
              },
            }}
            exit={{ opacity: 0, y: -8 }}
            className="text-pretty break-words mb-4 max-w-xl text-start font-light text-white md:mb-5 text-[35px] leading-[45px] xs:text-[30px] xs:leading-[40px] xxs:text-[20px] xxs:leading-[30px] max-md:w-3/4 max-sm:w-[400px] xs:text-[25px] xs:leading-[35px]">
            {props.menuData.subscribe_text}
          </motion.p>
          <div className="items-center mb-3 max-w-screen-md flex xxs:flex-wrap space-y-0 xxs:justify-center">
            <motion.div
              initial={{ opacity: 0, y: -8 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.75,
                  duration: 0.5,
                  ease: "easeInOut",
                },
              }}
              exit={{ opacity: 0, y: -8 }}
              className="relative w-1/2 max-md:w-3/4 max-w-[360px] xxs:mb-[15px] xxs:w-full xxs:max-w-[100%]">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <img src={mainIcon} alt="icon" />
              </div>
              <input className="block p-3 pl-10 w-full text-md text-gray-900 bg-gray-50 border border-gray-300 rounded-none rounded-l-lg xxs:rounded-r-lg focus:ring-primary-500 focus:border-primary-500" placeholder="Enter your email" type="email" id="emails" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -8 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.75,
                  duration: 0.5,
                  ease: "easeInOut",
                },
              }}
              exit={{ opacity: 0, y: -8 }}
              className="xxs:w-full xxs:max-w-[100%]"
            >
              <button type="submit" className="py-3 px-5 max-md:px-2 w-full text-md font-medium text-center text-white border cursor-pointer bg-[#0174EB] border-sky-600 rounded-none rounded-r-lg xxs:rounded-l-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300">Subscribe</button>
            </motion.div>
          </div>
        </div>
      </motion.div>
      <FooterCTAs socialLinks={props.menuData.socialLinks} />
    </nav>
  );
};

const LinksContainer = (props) => {
  // const navigate = useNavigate();

  return (
    <motion.div className="p-2 md:pl-4 pl-20 ">
      {props.links.map((l, idx) => {
        return (
          <NavLink key={l.id} href={l.menu_link} idx={idx} onClick="true">
            {l.menu_text}
          </NavLink>
        );
      })}
    </motion.div>
  );
};

const NavLink = ({ children, href, idx }) => {
  return (
    <motion.a
      initial={{ opacity: 0, y: -8 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          delay: 0.75 + idx * 0.125,
          duration: 0.5,
          ease: "easeInOut",
        },
      }}
      exit={{ opacity: 0, y: -8 }}
      href={href}
      className="block text-[44px] leading-[60px] lg:text-[40px] lg:leading-[50px] sm:text-[35px] sm:leading-[45px] xs:text-[30px] xs:leading-[40px] xxs:text-[25px] xxs:leading-[35px] font-[500] text-[#b9b9b9] transition-colors hover:text-[#fff] cursor-pointer"
    >
      {children}.
    </motion.a>
  );
};

const Logo = (props) => {
  return (
    <motion.a
      initial={{ opacity: 0, y: -12 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: { delay: 0.5, duration: 0.5, ease: "easeInOut" },
      }}
      exit={{ opacity: 0, y: -12 }}
      href="/"
      className="grid h-[100px] w-[170px] md:h-[75px] xxs:h-[60px] md:w-[140px] xxs:w-[110px] pl-[10px] pr-[10px] place-content-center rounded-br-xl rounded-tl-xl bg-white transition-colors hover:bg-violet-50"
    >
      <img src={"https://strapi.incabinpets.com" + props.logo.data.attributes.url} alt="site-logo" />
    </motion.a>
  );
};

const HamburgerButton = ({ active, setActive }) => {
  return (
    <>
      <motion.div
        initial={false}
        animate={active ? "open" : "closed"}
        variants={UNDERLAY_VARIANTS}
        style={{ top: 16, right: 20 }}
        className="fixed z-10 rounded-xl bg-[#044384] from-violet-600 to-violet-500 shadow-lg shadow-violet-800/20"
      />

      <motion.button
        initial={false}
        animate={active ? "open" : "closed"}
        onClick={() => setActive((pv) => !pv)}
        className={`group fixed right-5 top-4 z-[999] h-[55px] w-[55px] bg-white/0 transition-all hover:bg-white/20 ${active ? "rounded-bl-xl rounded-tr-xl" : "rounded-xl"
          }`}
      >
        <motion.span
          variants={HAMBURGER_VARIANTS.top}
          className="absolute block h-1 w-[35px] bg-white"
          style={{ y: "-50%", left: "50%", x: "-50%" }}
        />
        <motion.span
          variants={HAMBURGER_VARIANTS.middle}
          className="absolute block h-1 w-[35px] bg-white"
          style={{ left: "50%", x: "-50%", top: "50%", y: "-50%" }}
        />
        <motion.span
          variants={HAMBURGER_VARIANTS.bottom}
          className="absolute block h-1 w-[20px] bg-white"
          style={{ x: "-50%", y: "50%" }}
        />
      </motion.button>
    </>
  );
};

const FooterCTAs = (props) => {
  return (
    <>
      <div className="absolute bottom-6 left-20 md:left-4 gap-4 flex flex-row">
        <ul className="flex items-center">
          {props.socialLinks.map(links => (
            <motion.li key={links.id} className="pr-2" initial={{ opacity: 0, y: -8 }} animate={{
              opacity: 1,
              y: 0,
              transition: {
                delay: 1 * 0.125,
                duration: 0.5,
                ease: "easeInOut",
              },
            }}
              exit={{ opacity: 0, y: -8 }}>
              <Link to={links.url}>
                <LazyLoad offset={100} once>
                  <motion.img src={"https://strapi.incabinpets.com" + links.icon.data.attributes.url} alt="SocialIcon" />
                </LazyLoad>
              </Link>
            </motion.li>
          ))}
        </ul>
      </div>
    </>
  );
};

const UNDERLAY_VARIANTS = {
  open: {
    width: "calc(100% - 32px)",
    height: "calc(100vh - 32px)",
    transition: { type: "spring", mass: 3, stiffness: 400, damping: 50 },
  },
  closed: {
    width: "55px",
    height: "55px",
    transition: {
      delay: 0.75,
      type: "spring",
      mass: 3,
      stiffness: 400,
      damping: 50,
    },
  },
};

const HAMBURGER_VARIANTS = {
  top: {
    open: {
      rotate: ["0deg", "0deg", "45deg"],
      top: ["35%", "50%", "50%"],
    },
    closed: {
      rotate: ["45deg", "0deg", "0deg"],
      top: ["50%", "50%", "30%"],
    },
  },
  middle: {
    open: {
      rotate: ["0deg", "0deg", "-45deg"],
    },
    closed: {
      rotate: ["-45deg", "0deg", "0deg"],
    },
  },
  bottom: {
    open: {
      rotate: ["0deg", "0deg", "45deg"],
      bottom: ["35%", "50%", "50%"],
      left: "50%",
    },
    closed: {
      rotate: ["45deg", "0deg", "0deg"],
      bottom: ["50%", "50%", "27%"],
      left: "calc(50% + 7px)",
    },
  },
};

export default AnimatedHamburgerButton;
