import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import LazyLoad from 'react-lazy-load';

const CounterSection = (props) => {

  const [ref, inView] = useInView({ threshold: 0.3 });
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 0.5 }}
      className="counter-section bg-[#82B1E233]"
    >
      <div className="container mx-auto max-w-[1443px] px-[30px] xs:px-[20px]">
        <div className="mx-auto flex flex-wrap justify-between py-[60px] lg:py-[50px] rounded-[30px] xxs:!bg-center">
          {props.counterSection.map(counterItem => (
            <motion.div
              key={counterItem.id}
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: counterItem.id * 0.2 }}
              className={`w-[33%] xxs:w-[100%] text-center border-r xxs:border-r-[0] ${counterItem.id === 2 ? '' : 'border-b-[0] xxs:pb-[20px] xxs:mb-[20px]'
                } !border-[#9FCAF7] border-r-[2px] last:border-r-[0]`}
            >
              <div className="flex flex-col items-center justify-center">
                <motion.div
                  className="mb-[25px] xl:mb-[20px] lg:mb-[15px] sm:mb-[10px] border border-[#0174EB] border-[5px] lg:border-[4px] rounded-[100px] w-full max-w-[127px] lg:max-w-[90px] h-[127px] lg:h-[90px] flex items-center justify-center"
                >
                  <LazyLoad offset={100} once>
                    <motion.img
                      initial={{ opacity: 0 }}
                      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
                      transition={{ duration: 0.5, delay: counterItem.id * 0.2 }}
                      className="w-full max-w-[80px] lg:max-w-[50px]"
                      src={"https://strapi.incabinpets.com" + counterItem.icon.data.attributes.url}
                      alt="icon"
                    />
                  </LazyLoad>
                </motion.div>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
                  transition={{ duration: 0.5, delay: counterItem.id * 0.2 + 0.1 }}
                  className="text-[40px] xl:text-[38px] lg:text-[35px] sm:text-[30px] xs:text-[25px] text-[#6D7B8D] leading-[1] capitalize mb-[10px]"
                >
                  Total
                </motion.p>
                <motion.h2
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
                  transition={{ duration: 0.5, delay: counterItem.id * 0.2 + 0.1 }}
                  className="text-[45px] xl:text-[38px] lg:text-[35px] sm:text-[30px] xs:text-[25px] text-[#6D7B8D] leading-[1] font-[900] capitalize mb-[10px]"
                >
                  {counterItem.heading}
                </motion.h2>
                <motion.h3
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
                  transition={{ duration: 0.5, delay: counterItem.id * 0.2 + 0.1 }}
                  className="text-[#0174EB] font-[900] text-[96px] leading-[96px] xl:text-[70px] xl:leading-[70px] lg:text-[60px] sm:text-[50px] xs:text-[45px] xs:leading-[45px] lg:leading-[60px] sm:leading-[50px] m-0"
                >
                  {counterItem.counter}
                </motion.h3>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default CounterSection;