import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';
import LazyLoad from 'react-lazy-load';
import companyImage from '../../assets/images/companyimage.png';
import rightArrow from '../../assets/images/right-arrow-white.svg';
import brochuresIcon from '../../assets/images/brochuresIcon.svg';

const CompanyBrochures = () => {
    const [ref, inView] = useInView({ threshold: 0.3 });
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);

    useEffect(() => {
        if (inView) {
            gsap.from(textRef1.current, {
                opacity: 0,
                x: -50,
                duration: 1,
                delay: 0.5,
            });

            gsap.from(textRef2.current, {
                opacity: 0,
                x: 50,
                duration: 1,
                delay: 0.5,
            });
        }
    }, [inView]);

    const listVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { delay: 0.5, staggerChildren: 0.2 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <div ref={ref} className="container mx-auto max-w-[1597px] pt-[120px] lg:pt-[60px] sm:pt-[40px] pb-[120px] lg:pb-[60px] sm:pb-[40px] px-[20px]">
            <div className={`w-full mx-auto overflow-hidden ${inView ? 'animate' : ''}`}>
                <div className="flex xs:flex-col">
                    <div className="w-full w-1/2 flex items-center justify-end xs:justify-center">
                        <motion.div
                            className="bg-white w-full"
                            initial={{ opacity: 0, x: 50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5 }}
                        >
                            <h2
                                ref={textRef2}
                                className="text-[85px] 2xl:text-[76px] xl:text-[65px] lg:text-[50px] sm:text-[38px] leading-[1.2] font-[900] text-[#0174EB] mb-2 xxs:text-[30px] xxs:leading-[1]"
                            >
                                <motion.span
                                    initial={{ opacity: 0, x: -50 }}
                                    animate={inView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.25, delay: inView ? 0.25 : 0 }}
                                >
                                    Company Brochures
                                </motion.span>
                            </h2>
                            <p
                                ref={textRef1}
                                className="text-[30px] leading-[1.2] xl:text-[22px] lg:text-[20px] sm:text-[18px] xxs:text-[17px] font-[500] text-[#676767]"
                            >
                                Please click on the below link to download a brief description of the organization.
                            </p>
                            <motion.ul
                                className="flex mt-[40px] sm:mt-[20px]"
                                initial="hidden"
                                animate={inView ? 'visible' : 'hidden'}
                                variants={listVariants}
                            >
                                <motion.li variants={itemVariants} className="mr-[8px]">
                                    <a href="##" className="bg-black flex items-center justify-center text-white text-[35px] xl:text-[25px] lg:text-[22px] sm:text-[18px] leading-[1.2] font-[500] h-[79px] xl:h-[70px] lg:h-[62px] sm:h-[55px] w-[323px] xl:w-[260px] lg:w-[200px] sm:w-[160px] rounded-[6px]">
                                        <span className="pr-[20px] lg:pr-[12px]">View PDF</span>
                                        <img src={rightArrow} alt="icon" className="xl:max-w-[50px] lg:max-w-[40px]" />
                                    </a>
                                </motion.li>
                                <motion.li variants={itemVariants}>
                                    <a href="##" className="w-[117px] lg:w-[90px] sm:w-[80px] h-[79px] xl:h-[70px] lg:h-[62px] sm:h-[55px] bg-[#0174EB] flex items-center justify-center rounded-[6px]">
                                        <img className="w-[56px] h-[56px] xl:w-[45px] xl:h-[45px] sm:w-[40px] sm:h-[40px]" src={brochuresIcon} alt="icon" />
                                    </a>
                                </motion.li>
                            </motion.ul>
                        </motion.div>
                    </div>
                    <div className="w-full w-1/2 flex items-center justify-center">
                        <motion.div
                            className="bg-white p-6 pb-0"
                            initial={{ opacity: 0, x: -50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5 }}
                        >
                            <LazyLoad offset={100} once>
                                <motion.img
                                    src={companyImage}
                                    alt="image"
                                    initial={{ opacity: 0, x: -50 }}
                                    animate={inView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.5 }}
                                />
                            </LazyLoad>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyBrochures;
