import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const InCabinPetNannies = (props) => {
    const [ref, inView] = useInView({ threshold: 0.4 });
    return (
        <div ref={ref} className="flex justify-center items-center py-[68px] bg-neutral-700 max-md:px-5">
            <div className="flex justify-center items-center w-full max-w-[1530px] px-[97px] ml-auto mr-auto max-md:px-5 xl:px-[40px] xs:px-[20px]">
                <motion.div initial={{ opacity: 0, y: 50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                    <div className="flex gap-[30px] justify-center xxs:flex-wrap xxs:flex-col-reverse">
                        <div className="flex flex-col text-white max-md:max-w-full max-w-[74%] xxs:max-w-[100%]">
                            <div className="text-5xl font-black max-md:max-w-full sm:text-[42px] xs:text-[36px] xxs:text-[30px]">
                                {props.icpNannies.mainTitle}
                            </div>
                            <div className="mt-3.5 text-3xl font-medium leading-10 sm:text-[28px] xs:text-[24px] xxs:text-[18px] xxs:leading-[normal]">
                                {props.icpNannies.description}
                            </div>
                        </div>
                        <div className="flex justify-center items-center my-auto w-[153px] h-[130px] max-w-[25%] xxs:max-w-[100%] xxs:w-full xxs:h-full">
                            <img
                                src={"https://strapi.incabinpets.com" + props.icpNannies.Icon.data.attributes.url}
                                className="fill-white w-[153px] h-[130px] xxs:h-[90px] xxs:w-auto"
                                alt='image2'
                            />
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default InCabinPetNannies
