import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import LazyLoad from 'react-lazy-load';

const BoxWithImage = ({ image, title, content }) => {
  return (
    <motion.div
      className="flex flex-col h-full items-center justify-center bg-white p-5 rounded-xl border border-blue-500"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <LazyLoad offset={100} once>
        <img src={image} className="h-auto max-w-[94px] xl:max-w-[75px] lg:max-w-[50px] w-auto mb-[20px] lg:mb-[10px]" alt="Box Icon" />
      </LazyLoad>
      <h2 className="text-[48px] leading-[44px] 2xl:text-[40px] xl:text-[35px] lg:text-[30px] font-[900] text-[#0174EB] mb-4 lg:mb-2">{title}</h2>
      <p className="text-[24px] 2xl:text-[20px] xl:text-[17px] lg:text-[16px] leading-[28px] lg:leading-[24px] font-[500] text-[#6D7B8D] mb-4">{content}</p>
    </motion.div>
  );
};

const BookingSection = (props) => {

  const [ref, inView] = useInView({ threshold: 0.3 });
  return (
    <div ref={ref} className="container mx-auto max-w-[1597px] px-[20px]">
      <div className="block py-[100px] xl:py-[60px] lg:py-[40px] overflow-hidden">
        <motion.div
          className="mx-auto flex flex-wrap justify-center gap-6 sm:gap-[1rem]"
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {props.boxContent.map((boxItem) => (
            <motion.div
              key={boxItem.id}
              className="w-[32%] max-w-[447px] h-[447px] 2xl:max-w-[400px] lg:max-w-[310px] 2xl:h-[400px] xl:h-[380px] lg:h-[340px] text-center sm:max-w-[48%] sm:h-auto sm:w-[50%] xxs:w-[100%] xxs:max-w-[100%]"
              initial={{ opacity: 0, x: boxItem.id % 2 === 0 ? 50 : -50 }}
              animate={inView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <BoxWithImage
                image={"https://strapi.incabinpets.com" + boxItem.iconImage.data.attributes.url}
                title={boxItem.heading}
                content={boxItem.description}
              />
            </motion.div>
          ))}

        </motion.div>
        <div className="mt-[50px] xl:mt-[30px] flex justify-center">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            animate={inView ? { backgroundColor: "#0053ba" } : {}}
            className="w-[473px] 2xl:w-[400px] xl:w-[360px] lg:w-[300px] h-[73px] 2xl:h-[63px] xl:h-[58px] bg-[#044384] text-white text-[32px] 2xl:text-[27px] xl:text-[23px] lg:text-[20px] leading-[37px] 2xl:leading-[32px] xl:leading-[30px] lg:leading-[28px] font-[900] py-2 px-4 rounded-[36px] uppercase"
          >
            Register & Book Now
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default BookingSection;