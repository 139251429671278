import React from 'react';
import { useQuery } from 'react-query';
import Loader from '../../Components/Loader';
import { fetchTrackPetData } from '../../Strapi/UseStrapiApi';
import TrackYourPet from '../../Components/TrackYourPet/TrackYourPet';
import RealTimeTracking from '../../Components/RealTimeTracking/RealTimeTracking';
import FlightInformation from '../../Components/FlightInformation/FlightInformation';


const PetTrack = () => {
    const { data, isLoading, isError } = useQuery('trackData', fetchTrackPetData, {
        refetchOnWindowFocus: false
    });

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div>Error: {isError.message}</div>;
    }

    return (
        <>
            <TrackYourPet data={data.TrachYourPet} />
            <RealTimeTracking data={data.RealTimeTracking} />
            <FlightInformation data={data.FlightInformation} />
        </>
    )
}

export default PetTrack
