import React from 'react';
import { motion } from 'framer-motion';

function VerifyIdentity() {
  return (
    <>
        <div className="flex items-center justify-center bg-white pt-[120px] sm:pt-[50px] pb-[80px] xs:pb-[60px] xxs:pb-[50px]">
            <div className="max-w-[943px] px-[30px] xs:px-[20px] w-full space-y-8">
                <div className="main-heading">
                    <h2 className="text-[#0174EB] font-[900] text-[64px] md:text-[54px] xs:text-[40px] xxs:text-[30px] leading-[64px] md:leading-[54px] xs:leading-[40px] xxs:leading-[30px] text-center mb-[30px] xxs:mb-[20px]">
                        We need to verify your identity
                    </h2>
                </div>
                <p className="text-[24px] leading-[28px] xs:text-[20px] xs:leading-[24px] text-[#252525] text-center pt-[20px] pb-[30px] xs:pb-[10px]">
                    We will send an email to your registered email id, <br/>
                    <strong>l**in@gmail.com</strong> <br/>
                    with a one-time verification code.
                </p>
                <div className="flex items-center justify-center">
                    <motion.button 
                     whileHover={{ scale: 1.1 }}
                     whileTap={{ scale: 0.9 }}
                     transition={{ duration: 0.3, ease: "easeInOut" }}
                     animate={{ backgroundColor: "#044384" }}       
                    className="w-full max-w-[253px] xs:max-w-[220px] flex justify-center items-center h-[72px] xxs:h-[60px] bg-[#0174EB] border border-transparent rounded-[15px] shadow-sm text-[32px] xs:text-[27px] xxs:text-[22px] font-medium text-white focus:outline-none focus:ring-2 focus:ring-none focus:ring-none cursor-pointer hover:bg-[#0174EB73] focus:bg-[#0174EB73]">
                        Send Code
                    </motion.button>
                </div>
            </div>
        </div>
    </>
  );
}

export default VerifyIdentity;
