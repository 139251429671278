import React from 'react';
import MeidaCentreSection from '../../Components/MeidaCentreSection/MeidaCentreSection';
import PressRelease from '../../Components/PressRelease/PressRelease';
import ServiceMarketing from '../../Components/ServiceMarketing/ServiceMarketing';
import CompanyBrochures from '../../Components/CompanyBrochures/CompanyBrochures';
import PressMedia from '../../Components/PressMedia/PressMedia';
import MediaLogoIcons from '../../Components/MediaLogoIcons/MediaLogoIcons';



const MeidaCentre = () => {
    return (
        <>
            <MeidaCentreSection />
            <PressRelease />
            <ServiceMarketing />
            <CompanyBrochures />
            <PressMedia />
            <MediaLogoIcons />
        </>
    )
}

export default MeidaCentre;
