import React from 'react';
import { motion } from 'framer-motion';
import downloadFile from '../../assets/images/download-file.png';
import LazyLoad from 'react-lazy-load';

const PetProfileDetail = (props) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="charges-sec bg-[#0174EB] rounded-[26px] p-[15px] mt-[50px] xl:mt-[0] pt-[30px] lg:pt-[15px] sm:p-[0]">
                <div className="flex w-full items-center sm:relative sm:flex-col">
                    <div className="charges-content-sec pt-[0] pb-[20px] relative sm:static w-full flex justify-center flex-wrap">
                        <div className="text-center w-full max-w-[300px] sm:max-w-[250px] xxs:max-w-[225px]">
                            <h4 className='text-[#FFFFFF] text-center text-[40px] leading-[47px] lg:leading-[35px] xxs:leading-[35px] font-bold mb-[10px] xxs:mb-[15px] xl:text-[35px] lg:text-[30px] sm:text-[27px] xxs:text-[22px] uppercase'>Pet Profile</h4>
                            <p className="text-[#fff] text-[20px] leading-[23px] lg:text-[18px] sm:text-[16px] xxs:text-[14px] sm:leading-[19px] xxs:leading-[17px]">Already have a Pet Profile? Login</p>
                            <div className="text-center">
                                <div className="flex items-center">
                                    <div className="flex-1 border-t border-[#fff]"></div>
                                    <p className="mx-2 text-[14px] leading-[19px] text-[#fff]">or</p>
                                    <div className="flex-1 border-t border-[#fff]"></div>
                                </div>
                            </div>
                            <p className="text-[#fff] text-[20px] leading-[23px] lg:text-[18px] sm:text-[16px] xxs:text-[14px] sm:leading-[19px] xxs:leading-[17px]">Add new Pet Profile</p>
                        </div>
                        <form className="w-full mx-auto my-[20px]">
                            <div className="w-full flex flex-wrap justify-between px-[20px] sm:px-[0] sm:mx-[0px]">
                                <div className="w-full max-w-[24%] xs:max-w-[49%] xxs:max-w-full xs:mb-[15px]">
                                    <div className="flex">
                                        <input type="text" className="h-[70px] xl:h-[60px] lg:h-[55px] sm:h-[50px] rounded-[15px] bg-[#fff] border border-[2px] border-[#fff] text-[#0174EB] text-[28px] 2xl:text-[24px] xl:text-[21px] lg:text-[17px] px-[20px] sm:text-[13px] xs:text-[15px] xl:px-[15px] lg:px-[10px] sm:px-[8px] block flex-1 min-w-0 w-full placeholder-[#0174EB] focus:ring-0 focus:outline-none focus:shadow-none focus:border-transparent" placeholder="Name" />
                                    </div>
                                </div>
                                <div className="w-full max-w-[24%] xs:max-w-[49%] xxs:max-w-full xs:mb-[15px]">
                                    <div className="flex">
                                        <input type="text" className="h-[70px] xl:h-[60px] lg:h-[55px] sm:h-[50px] rounded-[15px] bg-[#fff] border border-[2px] border-[#fff] text-[#0174EB] text-[28px] 2xl:text-[24px] xl:text-[21px] lg:text-[17px] px-[20px] sm:text-[13px] xs:text-[15px] xl:px-[15px] lg:px-[10px] sm:px-[8px] block flex-1 min-w-0 w-full placeholder-[#0174EB] focus:ring-0 focus:outline-none focus:shadow-none focus:border-transparent" placeholder="Breed" />
                                    </div>
                                </div>
                                <div className="w-full max-w-[24%] xs:max-w-[49%] xxs:max-w-full xs:mb-[15px]">
                                    <div className="flex">
                                        <input type="text" className="h-[70px] xl:h-[60px] lg:h-[55px] sm:h-[50px] rounded-[15px] bg-[#fff] border border-[2px] border-[#fff] text-[#0174EB] text-[28px] 2xl:text-[24px] xl:text-[21px] lg:text-[17px] px-[20px] sm:text-[13px] xs:text-[15px] xl:px-[15px] lg:px-[10px] sm:px-[8px] block flex-1 min-w-0 w-full placeholder-[#0174EB] focus:ring-0 focus:outline-none focus:shadow-none focus:border-transparent" placeholder="Color" />
                                    </div>
                                </div>
                                <div className="w-full max-w-[24%] xs:max-w-[49%] xxs:max-w-full xs:mb-[15px]">
                                    <div className="w-full flex relative">
                                        <input className="hidden" id="user_avatar" type="file" />
                                        <label htmlFor="user_avatar" className="w-full h-[70px] xl:h-[60px] lg:h-[55px] sm:h-[50px] rounded-[15px] bg-[#fff] border border-[2px] border-[#fff] text-[#0174EB] text-[28px] 2xl:text-[24px] xl:text-[21px]  lg:text-[17px] px-[20px] sm:text-[13px] xs:text-[15px] xl:px-[15px] lg:px-[10px] sm:px-[8px] py-[10px] flex items-center cursor-pointer">
                                            Upload Pet Image
                                            <LazyLoad offset={100} once>
                                                <img className="absolute right-[15px] xl:max-w-[24px] lg:right-[10px] lg:max-w-[20px] sm:max-w-[18px] sm:right-[8px] top-[33%]" src={downloadFile} alt="icon" />
                                            </LazyLoad>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-wrap justify-between px-[20px] sm:px-[0] mt-[20px] xs:mt-[0]">
                                <div className="w-full max-w-[32%] xs:max-w-[49%] xxs:max-w-full xs:mb-[15px]">
                                    <div className="w-full flex relative">
                                        <input className="hidden" id="user_avatar" type="file" />
                                        <label htmlFor="user_avatar" className="w-full h-[70px] xl:h-[60px] lg:h-[55px] sm:h-[50px] rounded-[15px] bg-[#fff] border border-[2px] border-[#fff] text-[#0174EB] text-[28px] 2xl:text-[24px] xl:text-[21px]  lg:text-[17px] px-[20px] sm:text-[13px] xs:text-[15px] xl:px-[15px] lg:px-[10px] sm:px-[8px] py-[10px] flex items-center cursor-pointer">
                                            Upload Vaccination Certificate
                                            <LazyLoad offset={100} once>
                                                <img className="absolute right-[15px] xl:max-w-[24px] lg:right-[10px] lg:max-w-[20px] sm:max-w-[18px] sm:right-[8px] top-[33%]" src={downloadFile} alt="icon" />
                                            </LazyLoad>
                                        </label>
                                    </div>
                                </div>
                                <div className="w-full max-w-[32%] xs:max-w-[49%] xxs:max-w-full xs:mb-[15px]">
                                    <div className="w-full flex relative">
                                        <input className="hidden" id="user_avatar" type="file" />
                                        <label htmlFor="user_avatar" className="w-full h-[70px] xl:h-[60px] lg:h-[55px] sm:h-[50px] rounded-[15px] bg-[#fff] border border-[2px] border-[#fff] text-[#0174EB] text-[28px] 2xl:text-[24px] xl:text-[21px]  lg:text-[17px] px-[20px] sm:text-[13px] xs:text-[15px] xl:px-[15px] lg:px-[10px] sm:px-[8px] py-[10px] flex items-center cursor-pointer">
                                            Upload Health Certificate
                                            <LazyLoad offset={100} once>
                                                <img className="absolute right-[15px] xl:max-w-[24px] lg:right-[10px] lg:max-w-[20px] sm:max-w-[18px] sm:right-[8px] top-[33%]" src={downloadFile} alt="icon" />
                                            </LazyLoad>
                                        </label>
                                    </div>
                                </div>
                                <div className="w-full max-w-[32%] xs:max-w-[49%] xxs:max-w-full">
                                    <div className="w-full flex relative">
                                        <input className="hidden" id="user_avatar" type="file" />
                                        <label htmlFor="user_avatar" className="w-full h-[70px] xl:h-[60px] lg:h-[55px] sm:h-[50px] rounded-[15px] bg-[#fff] border border-[2px] border-[#fff] text-[#0174EB] text-[28px] 2xl:text-[24px] xl:text-[21px]  lg:text-[17px] px-[20px] sm:text-[13px] xs:text-[15px] xl:px-[15px] lg:px-[10px] sm:px-[8px] py-[10px] flex items-center cursor-pointer">
                                            Upload Health Record
                                            <LazyLoad offset={100} once>
                                                <img className="absolute right-[15px] xl:max-w-[24px] lg:right-[10px] lg:max-w-[20px] sm:max-w-[18px] sm:right-[8px] top-[33%]" src={downloadFile} alt="icon" />
                                            </LazyLoad>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="mt-[10px] w-full mb-[5px] absolute -bottom-[60px] flex gap-[15px] items-center justify-center xxs:-bottom-[35px]">
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                
                                className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[7] xs:px-[25px] xxs:px-[20px] xxs:py-[8px]' onClick={props.closehangleCoupon}
                            >
                                <span className='text-[32px] xl:text-[25px] leading-[38px] xl:leading-[30px] font-[900] text-[#FFFFFF] uppercase xs:text-[20px] xxs:text-[14px] xxs:leading-[normal] xxs:font-[500]'>Back</span>
                            </motion.button>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                
                                className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[5] xs:px-[25px] xxs:px-[20px] xxs:py-[8px]' onClick={props.closehangleCoupon}
                            >
                                <span className='text-[32px] xl:text-[25px] leading-[38px] xl:leading-[30px] font-[900] text-[#FFFFFF] uppercase xs:text-[20px] break-word xxs:text-[14px] xxs:leading-[normal] xxs:font-[500]'>Save and Proceed </span>
                            </motion.button>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default PetProfileDetail