import React from 'react';
import { useQuery } from 'react-query';
import Loader from '../../Components/Loader';
import ContactSection from '../../Components/ContactSection/ContactSection';
import ContactAddress from '../../Components/ContactAddress/ContactAddress';
import ContactForm from '../../Components/ContactForm/ContactForm';
import { fetchContactUsData } from '../../Strapi/UseStrapiApi';

const ContactUs = () => {

    const { data, isLoading, isError } = useQuery('contactUsData', fetchContactUsData, {
        refetchOnWindowFocus: false
    });

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div>Error: {isError.message}</div>;
    }
    return (
        <>
            <ContactSection data={data.ContactSection} />
            <ContactAddress data={data.contactUs} links={data.socialLinks} />
            <ContactForm data={data.ContactForm} />
        </>
    )
}

export default ContactUs
