import React from 'react';
import { useQuery } from 'react-query';
import Loader from '../../Components/Loader'; // Import the Loader component
import NannyBanner from '../../Components/NannyBanner/NannyBanner';
import { fetchNannyLandingPage } from '../../Strapi/UseStrapiApi';
import InCabinPetNannies from '../../Components/InCabinPetNannies/InCabinPetNannies';
import IntroducingInCabinPet from '../../Components/IntroducingInCabinPet/IntroducingInCabinPet';
import CertifiedNanny from '../../Components/CertifiedNanny/CertifiedNanny';
import NannyListing from '../../Components/NannyListing/NannyListing';
import NannyFooter from '../../Components/NannyFooter/NannyFooter';

function NannyLanding() {
    const { data, isLoading, isError } = useQuery('nannyData', fetchNannyLandingPage, {
        refetchOnWindowFocus: false
    });

    if (isLoading) {
        return <Loader />; // Render the Loader component while loading
    }

    if (isError) {
        return <div>Error: {isError.message}</div>;
    }
    return (
        <>
            <NannyBanner headerData={data.nannyHeader} bannerData={data.nannyBanner} />
            <InCabinPetNannies icpNannies={data.InCabinPetNannies} />
            <IntroducingInCabinPet introNannies={data.IntroducingInCabinPet} />
            <CertifiedNanny certifyNanny={data.CertifiedNanny} />
            <NannyListing listNanny={data.RegisterSection} />
            <NannyFooter footer={data.nannyFooter} />
        </>
    );
}

export default NannyLanding;
