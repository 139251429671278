import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  // Animation variants for the form fields
  const fieldVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  // Intersection Observer to trigger animations
  const { ref, inView } = useInView({ threshold: 0.5 });

  return (
    <motion.div
      ref={ref}
      className="pt-[106px] lg:pt-[80px] sm:pt-[50px] pb-[144px] lg:pb-[100px] sm:pb-[70px] max-w-[1050px] px-[20px] lg:px-[50px] sm:px-[20px] m-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-center mb-[33px] xs:mb-[25px]">
        <motion.h2
          className="text-[64px] lg:text-[55px] sm:text-[50px] xs:text-[40px] xxs:text-[26px] leading-[1.2] font-[500] text-[#0174EB]"
          variants={fieldVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          transition={{ duration: 0.7, delay: 0.2 }}
        >
          Feel free to get in touch{' '}
          <span className="text-[48px] sm:text-[40px] xs:text-[32px] xxs:text-[25px] font-[300] block">with our experts</span>
        </motion.h2>
      </div>
      <motion.form
        onSubmit={handleSubmit}
        className="w-full"
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={fieldVariants}
        transition={{ staggerChildren: 0.2 }}
      >
        <div className="flex justify-between flex-wrap">
          <motion.div
            className="mb-[27px] sm:mb-[22px] xxs:mb-[15px] w-[48%] xxs:w-full"
            variants={fieldVariants}
            transition={{ duration: 0.5 }}
          >
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Your name"
              className="block w-full px-[46px] sm:px-[30px] xxs:px-[20px] py-[20px] sm:py-[15px] xxs:py-[10px] border h-[87px] sm:h-[70px] xs:h-[65px] xxs:h-[60px] text-[32px] sm:text-[28px] xxs:text-[20px] font-[300] text-[#000] border-[#0174EB] rounded-[10px] shadow-none focus:outline-none"
              value={formData.name}
              onChange={handleChange}
            />
          </motion.div>
          <motion.div
            className="mb-[27px] sm:mb-[22px] xxs:mb-[15px] w-[48%] xxs:w-full"
            variants={fieldVariants}
            transition={{ duration: 0.5 }}
          >
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              className="block w-full px-[46px] sm:px-[30px] xxs:px-[20px] py-[20px] sm:py-[15px] xxs:py-[10px] border h-[87px] sm:h-[70px] xs:h-[65px] xxs:h-[60px] text-[32px] sm:text-[28px] xxs:text-[20px] font-[300] text-[#000] border-[#0174EB] rounded-[10px] shadow-none focus:outline-none"
              value={formData.email}
              onChange={handleChange}
            />
          </motion.div>
        </div>
        <motion.div
          className="mb-[27px] sm:mb-[22px] xxs:mb-[15px]"
          variants={fieldVariants}
          transition={{ duration: 0.5 }}
        >
          <textarea
            name="message"
            id="message"
            rows="4"
            placeholder="Write a message"
            className="block w-full px-[46px] sm:px-[30px] xxs:px-[20px] py-[25px] sm:py-[20px] xxs:py-[15px] border h-[166px] sm:h-[140px] text-[32px] sm:text-[28px] xxs:text-[20px] font-[300] text-[#000] border-[#0174EB] rounded-[10px] shadow-none focus:outline-none"
            value={formData.message}
            onChange={handleChange}
          />
        </motion.div>
        <div className="text-center mt-[48px] sm:mt-[30px]">
          <motion.button
            type="submit"
            className="w-full max-w-[230px] sm:max-w-[200px] xxs:max-w-[175px] bg-[#000] border border-[#0174EB] m-auto h-[70px] sm:h-[65px] xxs:h-[60px] text-[30px] sm:text-[25px] xxs:text-[20px] font-[400] text-white rounded-[10px] hover:bg-blue-600"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            Submit
          </motion.button>
        </div>
      </motion.form>
    </motion.div>
  );
};

export default ContactForm;
