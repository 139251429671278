export async function fetchHomeData() {
    const response = await fetch('https://strapi.incabinpets.com/api/home?populate=twoColumnContent.image&populate=bannerVideo.video&populate=counterSection.icon&populate=boxContent.iconImage');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.attributes;
}

export async function fetchFooterData() {
    const response = await fetch('https://strapi.incabinpets.com/api/footer?populate=footerLogo&populate=socialLinks.icon&populate=usefulLinks.icon&populate=contactUs.icon&populate=quickLinks.icon&populate=legal.icon&populate=footerLogos.footerLogos&populate=newsLetter.appIcons.icon');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.attributes;
}

export async function fetchNannyLandingPage() {
    const response = await fetch('https://strapi.incabinpets.com/api/nanny-landing-page?populate=nannyHeader.Logo&populate=nannyBanner.bannerImage&populate=nannyBanner.bannerBoxs.Icon&populate=InCabinPetNannies.Icon&populate=IntroducingInCabinPet.image&populate=CertifiedNanny.Icon&populate=RegisterSection.Image&populate=RegisterSection.listing&populate=nannyFooter.Social_icons.Icon');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.attributes;
}

export async function fetchHeaderData() {
    const response = await fetch('https://strapi.incabinpets.com/api/header?populate=menu_list&populate=socialLinks.icon&populate=logins_buttons&populate=Logo');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.attributes;
}

export async function fetchPetShipping() {
    const response = await fetch('https://strapi.incabinpets.com/api/pet-shipping?populate=ShipPetSection&populate=HowShipPet&populate=ShippingFeature.FeatureBox.icon');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.attributes;
}

export async function fetchTrackPetData() {
    const response = await fetch('https://strapi.incabinpets.com/api/pet-track?populate=TrachYourPet.image&populate=RealTimeTracking.image&populate=FlightInformation.image');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.attributes;
}

export async function fetchServicesData(id) {
    const response = await fetch('https://strapi.incabinpets.com/api/privaciespages/' + id);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.attributes;
}

export async function fetchAboutUsData() {
    const response = await fetch('https://strapi.incabinpets.com/api/about-us?populate=AboutTwoColumns.image');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.attributes;
}

export async function fetchContactUsData() {
    const response = await fetch('https://strapi.incabinpets.com/api/contact-us?populate=ContactSection.image&populate=socialLinks.icon&populate=contactUs.icon&populate=ContactForm');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.attributes;
}

export async function fetchFaqData() {
    const response = await fetch('https://strapi.incabinpets.com/api/faq?populate=*');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data.attributes;
}

