import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import LazyLoad from 'react-lazy-load';
import { useInView } from 'react-intersection-observer';
import { fetchFooterData } from '../../Strapi/UseStrapiApi';
import doublearrow from '../../assets/images/double-right-arrow.svg';
import locationIcon from '../../assets/images/location.svg';
import mail from '../../assets/images/mail.svg';
import rightarrow from '../../assets/images/right-arrow.svg';
import AppleIcon from '../../assets/images/apple.png';
import GoogleIcon from '../../assets/images/google.png';
import DownloadApp from '../../assets/images/downlaoad-app-icon.svg';
import ComingSoon from '../../assets/images/coming-soon-icon.svg';
import Loader from '../../Components/Loader';
const Footer = () => {
  const { ref, inView } = useInView({ threshold: 0.2 });
  const { data, isLoading, isError } = useQuery('footerData', fetchFooterData, {
    refetchOnWindowFocus: false
  });

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Error: {isError.message}</div>;
  }

  return (
    <motion.footer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      ref={ref}
    >
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={inView ? { y: 0, opacity: 1 } : {}}
        transition={{ duration: 0.5 }}
        className="news-letter bg-[#6D7B8D]"
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
          className="container mx-auto max-w-[1597px] px-[20px] py-[20px] flex justify-between items-center md:flex-col md:gap-[15px]"
        >
          <div className="block w-full text-left flex-wrap">
            <div className="flex items-center xxs:flex-wrap pr-[10px]">
              <div>
                <div className="flex items-center mb-[15px]">
                  <div className="icon mr-[10px]">
                    <LazyLoad offset={100} once>
                      <img className="xxs:max-w-[70px]" src={DownloadApp} alt="icon" />
                    </LazyLoad>
                  </div>
                  {/* <div > */}
                  {/* <h4 className="text-[32px] lg:text-[30px] xxs:text-[25px] leading-[34px] xxs:leading-[30px] font-[600] text-[#FFFFFF] mb-0">Download APP</h4>
                    <p className="text-[32px] lg:text-[30px] xxs:text-[25px] leading-[34px]  xxs:leading-[30px] font-[400] text-[#FFFFFF] mb-0">for Nannies</p> */}
                  {<div className="text" dangerouslySetInnerHTML={{ __html: data.newsLetter.description }} />}
                  {/* </div> */}
                </div>
                <ul className="flex gap-[10px]">
                  <li>
                    <Link to="/">
                      <LazyLoad offset={100} once>
                        <motion.img
                          className="max-w-[200px] lg:max-w-[150px] xxs:max-w-[130px] h-[57px] lg:h-[50px] xxs:h-[42px]"
                          src={AppleIcon}
                          alt="AppleIcon"
                          initial={{ opacity: 0, y: 20 }}
                          animate={inView ? { opacity: 1, y: 0 } : {}}
                          transition={{ duration: 0.5, delay: 0.2 }}
                        />
                      </LazyLoad>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <LazyLoad offset={100} once>
                        <img
                          className="max-w-[200px] lg:max-w-[150px] xxs:max-w-[130px] h-[57px] lg:h-[50px] xxs:h-[42px]"
                          src={GoogleIcon}
                          alt="GoogleIcon"
                          initial={{ opacity: 0, y: 20 }}
                          animate={inView ? { opacity: 1, y: 0 } : {}}
                          transition={{ duration: 0.5, delay: 0.3 }}
                        />
                      </LazyLoad>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="ml-[30px] xxs:ml-[0] xxs:mt-[20px]">
                <LazyLoad offset={100} once>
                  <img className="w-full max-w-[150px] lg:max-w-[120px]" src={ComingSoon} alt="icon" />
                </LazyLoad>
              </div>
            </div>
          </div>
          <div className="block w-full text-left">
            <h4 className="text-[48px] leading-[39px] font-[900] text-[#FFFFFF] mb-4 lg:text-[40px] lg:font-[500]">Newsletter</h4>
            <p className="text-[24px] leading-[27px] font-[500] text-[#FFFFFF] mb-4">Subscribe to our newsletter for updates!</p>
            <form className="flex items-center justify-center md:justify-start">
              <div className="relative w-full max-w-[765px] h-[65px] xxs:h-[60px]">
                <input type="text" id="voice-search" className="text-[29px] leading-[33px] font-[500] placeholder-[#0174EB] text-[#0174EB] h-full rounded-[16px] bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-16 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 xs:text-[22px] xxs:text-[18px] xs:pl-[20px] xxs:pl-[15px] xs:pr-[40px]" placeholder="Enter your e-mail address" required />
                <div className="absolute inset-y-0 right-0 flex items-center pr-4">
                  <LazyLoad offset={100} once>
                    <img src={rightarrow} alt="rightarrow" className='xs:h-[25px] xs:w-[25px] xxs:h-[20px] xxs:w-[20px]' />
                  </LazyLoad>
                </div>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5, delay: 0.1 }}
        className="footer-holder bg-[#0174EB] py-[50px] xl:py-[35px]"
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
          className="container mx-auto max-w-[1597px] px-[20px] flex flex-wrap justify-between xs:flex-col"
        >
          <div className="w-1/4 mb-4 mb-0 lg:w-[30%] xs:w-full">
            <div className="footer-logo mb-5">
              <Link to="/">
                <LazyLoad offset={100} once>
                  <motion.img
                    src={"https://strapi.incabinpets.com" + data.footerLogo.data.attributes.url}
                    alt="footerLogo"
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.5, delay: 0.1 }}
                  />
                </LazyLoad>
              </Link>
            </div>
            <div className="footer-content pb-2">
              <p className="text-white text-[20px] xl:text-[17px] leading-[23px] text-justify">{data.footerDescription}</p>
            </div>
            <div className="mt-4 mb-3">
              <ul className="flex items-center">
                {data.socialLinks.map(social =>
                  <motion.li className="pr-2" initial={{ opacity: 0, y: 20 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ duration: 0.5, delay: 0.1 + (social.id * 0.1) }} key={social.id}>
                    <Link to={social.url}>
                      <LazyLoad offset={100} once>
                        <motion.img src={"https://strapi.incabinpets.com" + social.icon.data.attributes.url} alt="SocialIcon" />
                      </LazyLoad>
                    </Link>
                  </motion.li>
                )}
              </ul>
            </div>
          </div>
          <div className="w-[70%] xl:w-[73%] lg:w-[65%] flex justify-between flex-wrap xs:w-full">
            <div className="w-1/4 mb-4 mb-0 pr-[10px] xl:pr-[6px] lg:w-1/2 xxs:w-full">
              <h5 className="text-[32px] leading-[39px] xl:text-[27px] xl:leading-[32px] text-white font-semibold mb-3">Contact Us</h5>
              <ul>
                <li className='flex items-start'>
                  <LazyLoad offset={100} once>
                    <img src={locationIcon} alt="locationIcon" className='mr-[10px] mt-1 xl:max-w-[25px] xl:mr-[5px]' />
                  </LazyLoad>
                  <p className="text-white text-[20px] dont-[500] leading-[28px] xl:text-[16px] xl:leading-[25px]">InCabinPets,LLC <br /> 822 Guilford Ave Suite 924 <br /> Baltimore, <br /> MD 21202</p>
                </li>
                <li className='flex items-start'>
                  <LazyLoad offset={100} once>
                    <img src={mail} alt="mail" className='mr-[10px] xl:mr-[5px] mt-1 xl:max-w-[25px]' />
                  </LazyLoad>
                  <Link to="mailto:info@incabinpets.com" className="text-[20px] font-[800] leading-[41px] xl:text-[17px] xl:leading-[35px] text-white hover:text-[#000]">info@incabinpets.com</Link>
                </li>
              </ul>
            </div>
            <div className="w-1/4 mb-4 mb-0 pr-[10px] xl:pr-[6px] lg:w-1/2 xxs:w-full">
              <h5 className="text-[32px] leading-[39px] xl:text-[27px] xl:leading-[32px] text-white font-semibold mb-3">Quick Links</h5>
              <ul>
                {data.quickLinks.map(quickLinks =>
                  <li className='flex items-center' key={quickLinks.id}>
                    <LazyLoad offset={100} once>
                      <img src={doublearrow} alt="doublearrow" className='mr-[5px]' />
                    </LazyLoad>
                    <Link to={quickLinks.url} className="text-[20px] leading-[41px] xl:text-[17px] xl:leading-[35px] text-white hover:text-[#000] transition-all duration-400 ease-linear hover:ml-[10px]">{quickLinks.title}</Link>
                  </li>
                )}
              </ul>
            </div>
            <div className="w-1/4 mb-4 mb-0 pr-[10px] xl:pr-[6px] lg:w-1/2 xxs:w-full">
              <h5 className="text-[32px] leading-[39px] xl:text-[27px] xl:leading-[32px] text-white font-semibold mb-3">Useful Links</h5>
              <ul>
                {data.usefulLinks.map(usefulLinks =>
                  <li className='flex items-center' key={usefulLinks.id}>
                    <LazyLoad offset={100} once>
                      <img src={doublearrow} alt="doublearrow" className='mr-[5px]' />
                    </LazyLoad>
                    <Link to={usefulLinks.url} className="text-[20px] leading-[41px] xl:text-[17px] xl:leading-[35px] text-white hover:text-[#000] transition-all duration-400 ease-linear hover:ml-[10px]">{usefulLinks.title}</Link>
                  </li>
                )}
              </ul>
            </div>
            <div className="w-1/4 mb-4 mb-0 pr-[10px] xl:pr-[6px] lg:w-1/2 xxs:w-full">
              <h5 className="text-[32px] leading-[39px] xl:text-[27px] xl:leading-[32px] text-white font-semibold mb-3">Legal</h5>
              <ul>
                {data.legal.map(legal =>
                  <li className='flex items-center' key={legal.id}>
                    <LazyLoad offset={100} once>
                      <img src={doublearrow} alt="doublearrow" className='mr-[5px]' />
                    </LazyLoad>
                    <Link to={legal.url} className="text-[20px] leading-[41px] xl:text-[17px] xl:leading-[35px] text-white hover:text-[#000] transition-all duration-400 ease-linear hover:ml-[10px]">{legal.title}</Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="middle-footer bg-[#82B1E2] py-4"
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="container mx-auto max-w-[1597px] px-[20px]"
        >
          <ul className="flex justify-center items-center md:flex-wrap">
            {data.footerLogos.map(allLogos =>
              <motion.li className='px-2 lg:pb-[5px]' initial={{ opacity: 0, y: 20 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ duration: 0.5, delay: 0.1 + (allLogos.id * 0.1) }} key={allLogos.id}>
                <Link to="https://dev2.incabinpets.com">
                  <LazyLoad offset={100} once>
                    <motion.img className="max-w-[175px] xl:max-w-[125px] xs:max-w-[80px] xxs:max-w-[60px]" src={"https://strapi.incabinpets.com" + allLogos.footerLogos.data.attributes.url} alt={"footericon" + allLogos.id} />
                  </LazyLoad>
                </Link>
              </motion.li>
            )}
          </ul>
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="bottom-footer bg-[#1B1B1B] py-4"
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
          className="container mx-auto max-w-[1597px] px-[20px]"
        >
          <div className="w-full text-center">
            <p className="text-[20px] leading-[23px] xl:text-[17px] xl:leading-[20px] font-[500] text-[#FFFFFF]">Copyright &copy; <Link to="/">InCabinPets</Link> | All Rights Reserved</p>
          </div>
        </motion.div>
      </motion.div>
    </motion.footer>
  );
};

export default Footer;
