import React from 'react';
import { motion } from 'framer-motion';

const BookingTransport = (props) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="transport-sec">
                <div className="transport-content-sec pb-[50px] relative w-ful">
                    <div>
                        <ul className='flex flex-wrap justify-center -mb-px text-sm font-medium text-center'>
                            <li className='border-b-[2px] border-[solid] border-b-[#004A97]'>
                            <motion.button
                            className='inline-block border-[2px] border-[solid] rounded-tl-[15px] rounded-br-[0px] rounded-tr-[15px] rounded-bl-none py-[20px] px-[30px] text-[32px] font-normal leading-[38px] bg-[#FFFFFF] text-[#0174EB] border-[#FFFFFF] xl:py-[15px] xl:px-[25px] text-[32px] xl:text-[25px] font-normal leading-[38px] xl:leading-[33px] sm:p-[15px] xs:text-[16px] xs:text-[900] xxs:text-[14px] xxs:leading-[normal] xxs:py-[15px] xxs:px-[7px]'>
                                    Car Transport
                                </motion.button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className='border-[1px] flex w-full border-[solid] border-[#004A97] bg-[#FFFFFF] px-[30px] py-[30px] rounded-[26px] xs:flex-col xs:p-[20px]'
                        >
                            <div className="w-[50%] px-[30px] py-[20px] pt-0 border-r-[#004A97] border-r-[4px] border-dotted xs:w-[100%] xs:border-r-[0px] xs:border-b-[4px] xs:border-b-[#004A97] xs:px-[0px] xs:pt-[20px] xs:pb-[50px] xxs:pb-[30px]">
                                <form action="" className='flex flex-col'>
                                    <h5 className="text-[36px] leading-[42px] mb-[5px] text-[#0174EB] font-[700] xs:text-[30px] xxs:text-[24px] xxs:leading-[normal]">Pickup Location</h5>
                                    <div className="w-full">
                                        <input type="text" placeholder='Pickup Address' className='text-[28px] leading-[32px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] xs:text-[24px] xs:leading-[normal]  xxs:text-[20px]' />
                                    </div>
                                    <div className="w-full">
                                        <input type="text" placeholder='Pickup Address 2' className='text-[28px] leading-[32px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] xs:text-[24px] xs:leading-[normal]  xxs:text-[20px]' />
                                    </div>
                                    <div className='flex w-full gap-[10px] xxs:flex-col'>
                                        <div className="w-full">
                                            <input type="text" placeholder='Pickup City' className='text-[28px] leading-[32px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] xs:text-[24px] xs:leading-[normal]  xxs:text-[20px]' />
                                        </div>
                                        <div className="w-full">
                                            <input type="text" placeholder='Pickup State' className='text-[28px] leading-[32px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] xs:text-[24px] xs:leading-[normal]  xxs:text-[20px]' />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <input type="number" placeholder='Pickup Zip' className='text-[28px] leading-[32px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] xs:text-[24px] xs:leading-[normal]  xxs:text-[20px]' />
                                    </div>
                                </form>
                            </div>
                            <div className="w-[50%] px-[30px] py-[20px] pt-0 xs:w-[100%] xs:px-[0px] xs:pt-[50px] xs:pb-[20px] xxs:pt-[30px]">
                                <form action="" className='flex flex-col'>
                                    <h5 className="text-[36px] leading-[42px] mb-[5px] text-[#0174EB] font-[700] xs:text-[30px] xxs:text-[24px] xxs:leading-[normal]">Dropoff Location</h5>
                                    <div className="w-full">
                                        <input type="text" placeholder='Delivery Address' className='text-[28px] leading-[32px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] xs:text-[24px] xs:leading-[normal]  xxs:text-[20px]' />
                                    </div>
                                    <div className="w-full">
                                        <input type="text" placeholder='Delivery Address 2' className='text-[28px] leading-[32px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] xs:text-[24px] xs:leading-[normal]  xxs:text-[20px]' />
                                    </div>
                                    <div className='flex w-full gap-[10px] xxs:flex-col'>
                                        <div className="w-full">
                                            <input type="text" placeholder='Delivery City' className='text-[28px] leading-[32px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] xs:text-[24px] xs:leading-[normal]  xxs:text-[20px]' />
                                        </div>
                                        <div className="w-full">
                                            <input type="text" placeholder='Delivery State' className='text-[28px] leading-[32px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] xs:text-[24px] xs:leading-[normal]  xxs:text-[20px]' />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <input type="number" placeholder='Delivery Zip' className='text-[28px] leading-[32px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] xs:text-[24px] xs:leading-[normal]  xxs:text-[20px]' />
                                    </div>
                                </form>
                            </div>
                        </motion.div>
                    </div>
                    <div className="mt-[10px] w-full mb-[5px] absolute -bottom-[0] flex gap-[15px] items-center justify-center xxs:-mb-[35px] xxs:mb-[30px] xxs:gap-[5px]">
                        <motion.button
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[5] xs:px-[25px] xs:py-[15px] xxs:py-[8px] xxs:px-[12px]'
                            onClick={props.closehangleCoupon}
                        >
                            <span className='text-[32px] leading-[38px] font-[900] text-[#FFFFFF] xs:text-[20px] xxs:text-[14px] uppercase xxs:leading-[normal] xxs:font-[500]'>Back</span>
                        </motion.button>
                        <motion.button
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            onClick={props.handleCharges}
                            className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[5] xs:px-[25px] xs:py-[15px] xxs:py-[8px] xxs:px-[12px]'
                        >
                            <span className='text-[32px] leading-[38px] font-[900] text-[#FFFFFF] xs:text-[20px] xxs:text-[14px] uppercase xxs:leading-[normal] xxs:font-[500]'>Choose Car Transport</span>
                        </motion.button>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default BookingTransport