import React from 'react';
import { motion } from 'framer-motion';
import LazyLoad from 'react-lazy-load';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import mailColored from '../../assets/images/mailColored.svg';

const PressMedia = () => {
  // Initialize the useInView hook with a 30% threshold
  const [ref, inView] = useInView({
    threshold: 0.3,
  });

  const bounce = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { scale: 1, opacity: 1, transition: { duration: 0.5, type: 'spring' } },
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  const slideIn = {
    hidden: { x: 200, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.5, type: 'spring' } },
  };

  return (
    <motion.div
      ref={ref} // Attach the ref to the container to track visibility
      className="container mx-auto max-w-[1597px] pt-[120px] sm:pt-[40px] pb-[120px] sm:pb-[40px] px-[20px] overflow-hidden"
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'} // Animate based on inView status
    >
      <div className="flex xs:flex-col">
        <motion.div
          className="w-full w-1/2 border-r border-[#67676785] xs:border-r-0 xs:border-b xs:pb-[30px] xs:mb-[20px]"
          variants={fadeIn}
        >
          <div className="max-w-[550px] lg:pr-[20px] xs:pr-0">
            <div className="mb-[29px]">
              <motion.h2
                className="text-[#0174EB] text-[85px] 2xl:text-[76px] xl:text-[65px] lg:text-[50px] md:text-[40px] leading-[90px] 2xl:leading-[80px] xl:leading-[70px] lg:leading-[55px] font-[900] xxs:text-[28px] xxs:leading-[1.2]"
                variants={fadeIn}
              >
                Press Contact
              </motion.h2>
              <motion.p
                className="text-[#676767] text-[30px] xl:text-[27px] sm:text-[22px] leading-[1.2] font-[500]"
                variants={fadeIn}
              >
                For further information and assistance please get in touch with:
              </motion.p>
            </div>
            <div className="mb-[23px]">
              <motion.h3
                className="text-[#000] text-[36px] lg:text-[30px] sm:text-[28px] leading-[1.2] font-[600]"
                variants={fadeIn}
              >
                InCabinPets Info
              </motion.h3>
              <motion.p
                className="text-[#000] text-[30px] lg:text-[28px] sm:text-[25px] leading-[1.2] font-[400]"
                variants={fadeIn}
              >
                Corporate Communications
              </motion.p>
            </div>
            <div className="flex items-center">
              <LazyLoad offset={100} once>
                <motion.img
                  src={mailColored}
                  alt="Mail"
                  className="mr-[12px] sm:mr-[5px] w-[45px] lg:w-[38px] sm:w-[30px]"
                  variants={bounce}
                />
              </LazyLoad>
              <motion.span
                className="text-[30px] lg:text-[25px] sm:lg:text-[18px] leading-[1.2] text-[#0174EB] font-[500]"
                variants={fadeIn}
              >
                <Link to="mailto:info@incabinpets.com">info@incabinpets.com</Link>
              </motion.span>
            </div>
          </div>
        </motion.div>
        <motion.div
          className="w-full w-1/2 justify-center xs:justify-start"
          variants={slideIn}
        >
          <div className="max-w-[520px] ml-auto xs:ml-0 lg:pl-[20px] xs:pl-0">
            <div className="mb-[35px]">
              <motion.h2
                className="text-[#0174EB] text-[85px] 2xl:text-[76px] xl:text-[65px] lg:text-[50px] md:text-[40px] leading-[90px] 2xl:leading-[80px] xl:leading-[70px] lg:leading-[55px] font-[900] xxs:text-[28px] xxs:leading-[1.2]"
                variants={fadeIn}
              >
                Media Kit
              </motion.h2>
              <motion.p
                className="text-[#676767] text-[30px] xl:text-[27px] sm:text-[22px] leading-[1.2] font-[500]"
                variants={fadeIn}
              >
                Download the company logo, images, photos, and other related media assets in zip format.
              </motion.p>
            </div>
            <motion.div>
              <a
                href="##"
                className="bg-black flex items-center px-[50px] lg:px-[30px] sm:px-[25px] text-white text-[35px] lg:text-[25px] sm:text-[21px] leading-[1.2] font-[500] h-[79px] lg:h-[70px] sm:h-[60px] w-[432px] lg:w-[300px] sm:w-[260px] rounded-[6px]"
              >
                <span className="pr-[20px]">Download ZIP file</span>
              </a>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default PressMedia;
