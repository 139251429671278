import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import facebook from '../../assets/images/facebook.svg';
import google from '../../assets/images/google.svg';
import apple from '../../assets/images/apple.svg';
import { motion } from 'framer-motion';
import LazyLoad from 'react-lazy-load';
function LoginPage(prop) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('customer');
  const [device, setDevice] = useState('laptop');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

    const token = localStorage.getItem('token');
    if (token) {

      navigate('/welcomescreen');
    }
    if (prop.role === 'admin') {
      setRole('admin');
    };
  }, [prop, navigate]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
      isValid = false;
    }

    if (!password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (validateForm()) {

      setLoading(true);

      axios.post('/login', { email, password, role, device })
        .then(response => {
          console.log(response);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userid', response.data.user_id);
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
          if (response.data.mfa) {
            navigate('/verifyIdentity');
          } else {
            navigate('/welcomescreen');
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 422) {
            setErrorMessage(error.response.data.message);
          }
          setPassword('');
        })
        .finally(() => {
          setLoading(false);
        });

    } else {
      console.log(errors);
    }
  };

  return (
    <div className="flex items-center justify-center bg-white pt-[120px] sm:pt-[50px] pb-[80px] xs:pb-[60px] xxs:pb-[50px]">
      <div className="max-w-[810px] px-[30px] xxs:px-[20px] w-full space-y-8">
        <div className="main-heading pb-5 xs:pb-0">
          <h2 className="text-[#0174EB] font-[900] text-[64px] md:text-[54px] xs:text-[40px] xxs:text-[30px] leading-[64px] md:leading-[54px] xs:leading-[40px] xxs:leading-[30px] text-center mb-[30px] xxs:mb-[20px]">
            Login
          </h2>
          <ul className="flex items-center justify-center gap-[15px] mb-5">
            <li><Link to="/">
              <LazyLoad offset={100} once>
                <img className="w-[32px] h-[32px] xs:w-[27px] xs:h-[27px]" src={facebook} alt="facebook" />
              </LazyLoad>
            </Link></li>
            <li><Link to="/">
              <LazyLoad offset={100} once>
                <img className="w-[32px] h-[32px] xs:w-[27px] xs:h-[27px]" src={google} alt="facebook" />
              </LazyLoad>
            </Link></li>
            <li><Link to="/"><LazyLoad offset={100} once><img className="w-[32px] h-[32px] xs:w-[27px] xs:h-[27px]" src={apple} alt="facebook" /></LazyLoad></Link></li>
          </ul>
          <div className="text-center">
            <div className="flex items-center">
              <div className="flex-1 border-t border-gray-300"></div>
              <p className="mx-2 text-[24px] leading-[29px] text-[#7B7B7B]">or</p>
              <div className="flex-1 border-t border-gray-300"></div>
            </div>
          </div>
        </div>
        <form onSubmit={handleLogin}>
          {errorMessage && <div className="bg-[#BD0A0A] w-full h-[95px] xs:h-[120px] xxs:h-[100px] px-[20px] rounded-[17px] flex items-center xs:items-start justify-left xs:p-[15px] mb-5">
            <p className="text-[30px] md:text-[25px] xs:text-[22px] xxs:text-[18px] text-[#fff] leading-[35px] md:leading-[30px] xxs:leading-[25px] text-left" >{errorMessage}</p>
          </div>}
          <div className="pb-5">
            <label htmlFor="email" className="sr-only">Email address</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`appearance-none rounded-[17px] w-full h-[90px] md:h-[80px] xxs:h-[65px] relative block border-[2px] border-[solid] placeholder-[#7B7B7B] !border-[#D9D9D9] !bg-[#FFFFFF] !text-[#7B7B7B] px-[30px] xxs:px-[20px] text-[40px] leading-[47px] md:text-[30px] md:leading-[37px] xxs:text-[20px] xxs:leading-[27px] focus:ring-0 placeholder-[300] font-[300]`}
              placeholder="Your Email ID"
            />
          </div>
          {errors.email && <div className="text-[#B70000] text-[24px] leading-[28px] xxs:text-[18px] leading-[28px] xxs:leading-[22px] left-0 mt-0 pb-5">{errors.email}</div>}
          <div className="pb-5 relative">
            <label htmlFor="password" className="sr-only">Password</label>
            <div className="relative">
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`appearance-none rounded-[17px] w-full h-[90px] md:h-[80px] xxs:h-[65px] relative block border-[2px] border-[solid] placeholder-[#7B7B7B] !border-[#D9D9D9] !bg-[#FFFFFF] !text-[#7B7B7B] px-[30px] xxs:px-[20px] text-[40px] leading-[47px] md:text-[30px] md:leading-[37px] xxs:text-[20px] xxs:leading-[27px] focus:ring-0 placeholder-[300] font-[300]`}
                placeholder="Password"
              />

              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                {showPassword ? (
                  <HiEyeOff
                    className="h-[28px] w-[44px] xxs:h-[22px] xxs:w-[36px] text-[#000] cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <HiEye
                    className="h-[28px] w-[44px] xxs:h-[22px] xxs:w-[36px] text-[#000] cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
            </div>
          </div>
          {errors.password && <div className="text-[#B70000] text-[24px] leading-[28px] xxs:text-[18px] leading-[28px] xxs:leading-[22px] left-0 mt-0 pb-5">{errors.password}</div>}
          <div className="text-left mb-[20px] pt-[10px]">
            <p className="text-[24px] leading-[28px] md:text-[20px] xxs:text-[18px] md:leading-[24px] text-[#000]">
              Forgot your password?
              <Link to="/" className="text-[#0174EB] font-[700]">  Reset the password here</Link>
            </p>
          </div>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            
            type="submit"
            disabled={loading} // Disable button when loading
            className={`relative w-full flex justify-center items-center h-[72px] xxs:h-[60px] bg-[#0174EB] border border-transparent rounded-[15px] shadow-sm text-[32px] xs:text-[27px] xxs:text-[22px] font-medium text-white focus:outline-none focus:ring-2 focus:ring-none focus:ring-none cursor-pointer hover:bg-[#0174EB73] focus:bg-[#0174EB73] ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {loading ? 'Loading...' : 'Login'}
          </motion.button>
          <div className="text-left mt-[20px]">
            <p className="text-[24px] leading-[28px] md:text-[20px] xxs:text-[18px] md:leading-[24px] text-[#000]">
              You Have an Account?
              <Link to="/CreateAccount" className="text-[#0174EB] font-[700]"> Register Here</Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
