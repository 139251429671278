import React from 'react';
import { Link } from 'react-router-dom';
import welcomeIcon from '../../assets/images/welcome-icon.svg';
import rightIcon from '../../assets/images/right-arrow-black.svg';
import logo from '../../assets/images/logo.svg';
import BackArrow from '../../assets/images/back-arrow.svg';
import LazyLoad from 'react-lazy-load';

function WelcomeScreen() {
    return (
        <>
            <header className="bg-white !border-[#C7C7C7] border-b-[1px] xs:border-b-[0] border-[solid]">
                <div className="container mx-auto max-w-[1597px] p-[20px] flex items-center justify-between">
                    <div className="flex-shrink-0">
                        <Link to="/" className="text-gray-500 hover:text-gray-700">
                            <LazyLoad offset={100} once>
                                <img className="md:max-w-[25px] xxs:max-w-[22px]" src={BackArrow} alt="attow" />
                            </LazyLoad>
                        </Link>
                    </div>
                    <div className="flex-grow text-center">
                        <Link to="/">
                            <LazyLoad offset={100} once>
                                <img className="max-w-[208px] md:max-w-[180px] xxs:max-w-[160px] inline-block" src={logo} alt="Logo" />
                            </LazyLoad>
                        </Link>
                    </div>

                    <div className="flex-shrink-0"></div>
                </div>
            </header>
            <div className="flex items-center justify-center bg-white pt-[60px] xs:pt-[40px] xxs:pt-[30px] pb-[80px] xs:pb-[60px] xxs:pb-[50px]">
                <div className="max-w-[660px] px-[30px] xs:px-[20px] w-full space-y-8">
                    <div className="main-heading">
                        <h2 className="text-[#0174EB] font-[900] text-[64px] md:text-[54px] xs:text-[40px] xxs:text-[30px] leading-[64px] md:leading-[54px] xs:leading-[40px] xxs:leading-[30px] text-center mb-[30px] xxs:mb-[20px]">
                            Welcome, Matthew!
                        </h2>
                    </div>
                    <p className="text-[24px] leading-[28px] xs:text-[20px] xs:leading-[24px] text-[#252525] text-left mb-8">
                        With your new <strong>InCabinPets</strong> account, the world is within your reach.
                    </p>
                    <div className="flex items-center justify-between">
                        <p className="flex items-center justify-between text-[27px] xs:text-[20px] leading-[31px] xs:leading-[25px] text-[#000] xs:font-bold"><span>Continue</span> <img className="ml-3 xs:ml-2 xs:max-w-[30px]" src={rightIcon} alt="icon" /></p>
                        <Link to="mailto:test@test.com"><LazyLoad offset={100} once><img className="w-[97px] xs:w-[65px]" src={welcomeIcon} alt="Email Icon" /></LazyLoad></Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WelcomeScreen;
