import React from 'react';
import { useQuery } from 'react-query';
import ShipPetSection from '../../Components/ShipPetSection/ShipPetSection';
import ShippingFeature from '../../Components/ShippingFeature/ShippingFeature';
import HowShipPet from '../../Components/HowShipPet/HowShipPet';
import { fetchPetShipping } from '../../Strapi/UseStrapiApi';
import Loader from '../../Components/Loader';

const PetShipping = () => {
    const { data, isLoading, isError } = useQuery('petShipping', fetchPetShipping, {
        refetchOnWindowFocus: false
    });

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div>Error: {isError.message}</div>;
    }
    return (
        <>
            <ShipPetSection data={data.ShipPetSection} />
            <HowShipPet data={data.HowShipPet} />
            <ShippingFeature data={data.ShippingFeature} />
        </>
    );
};

export default PetShipping;
