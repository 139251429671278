import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import marketingimg1 from '../../assets/images/marketingimg1.png';
import marketingimg2 from '../../assets/images/marketingimg2.png';
import marketingimg3 from '../../assets/images/marketingimg3.png';

const ServiceMarketing = () => {
    const [ref, inView] = useInView({ threshold: 0.3, });

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    const pageVariants = {
        initial: { opacity: 0 },
        in: { opacity: 1 },
        out: { opacity: 0 },
    };

    const pageTransition = {
        type: 'tween',
        duration: 0.5,
    };
    const pressReleaseVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };
    const transition = {
        duration: 0.5,
        ease: 'easeOut',
    };
    return (
        <motion.div
            className="bg-white"
            ref={ref}
            initial="initial"
            animate={inView ? "in" : "out"}
            variants={pageVariants}
            transition={pageTransition}
        >
            <div className="max-w-[1597px] px-[20px] pt-[75px] pb-[55px] xs:pt-[40px] xs:pb-[40px] m-auto">
                <div className="text-left mb-[60px] sm:mb-[40px] xs:mb-[25px]">
                    <h2 className="text-[#0174EB] text-[85px] 2xl:text-[76px] xl:text-[65px] lg:text-[50px] md:text-[40px] leading-[90px] 2xl:leading-[80px] xl:leading-[70px] lg:leading-[55px] font-[900] xxs:text-[28px] xxs:leading-[1.2]">
                        Company Product and <span className="block text-[63px] 2xl:text-[55px] xl:text-[50px] lg:text-[40px] md:text-[30px] xxs:text-[22px] font-[400]">Service Marketing Teasers</span>
                    </h2>
                </div>
                <div className="flex justify-between flex-wrap mb-[60px] sm:mb-[40px] xs:mb-[20px]">
                    <motion.div
                        className="w-[32%] xs:w-[48%] xs:mb-[30px] xxs:mb-[20px] xxs:w-full"
                        variants={cardVariants}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        transition={{ delay: 0.2 }}
                    >
                        <div className="bg-[#005FC0] rounded-[6px] pb-[10px]">
                            <div className="w-full">
                                <img className="w-full" src={marketingimg1} alt="img" />
                            </div>
                            <div className="px-[25px] xl:px-[20px] lg:px-[15px] lg:py-[15px] pt-[20px] pb-[28px]">
                                <p className="text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] text-white mb-[9px] font-[600]">InCabinPets Invites Air Nannies to Join Exclusive Pre-Launch: </p>
                                <a href="##" className="text-white text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] font-[500]">Expanding Nationwide Presence</a>
                            </div>
                            <div className="bg-[#fff] rounded-[8px] flex flex-wrap mx-[8px]">
                                <a href="##" className="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-[#0174EB] flex justify-center items-center border-r border-[#03386F] font-[600]">View PDF</a>
                                <a href="##" className="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-[#0174EB] flex justify-center items-center font-[600]">Share</a>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        className="w-[32%] xs:w-[48%] xs:mb-[30px] xxs:mb-[20px] xxs:w-full"
                        variants={cardVariants}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        transition={{ delay: 0.2 }}
                    >
                        <div className="bg-[#005FC0] rounded-[6px] pb-[10px]">
                            <div className="w-full">
                                <img className="w-full" src={marketingimg2} alt="img1" />
                            </div>
                            <div className="px-[25px] xl:px-[20px] lg:px-[15px] lg:py-[15px] pt-[20px] pb-[28px]">
                                <p className="text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] text-white mb-[9px] font-[600]">InCabinPets Invites Air Nannies to Join Exclusive Pre-Launch: </p>
                                <a href="##" className="text-white text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] font-[500]">Expanding Nationwide Presence</a>
                            </div>
                            <div className="bg-[#fff] rounded-[8px] flex flex-wrap mx-[8px]">
                                <a href="##" className="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-[#0174EB] flex justify-center items-center border-r border-[#03386F] font-[600]">View PDF</a>
                                <a href="##" className="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-[#0174EB] flex justify-center items-center font-[600]">Share</a>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        className="w-[32%] xs:w-[48%] xs:mb-[30px] xxs:mb-[20px] xxs:w-full"
                        variants={cardVariants}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        transition={{ delay: 0.2 }}
                    >
                        <div className="bg-[#005FC0] rounded-[6px] pb-[10px]">
                            <div className="w-full">
                                <img className="w-full" src={marketingimg3} alt="img2" />
                            </div>
                            <div className="px-[25px] xl:px-[20px] lg:px-[15px] lg:py-[15px] pt-[20px] pb-[28px]">
                                <p className="text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] text-white mb-[9px] font-[600]">InCabinPets Invites Air Nannies to Join Exclusive Pre-Launch: </p>
                                <a href="##" className="text-white text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] font-[500]">Expanding Nationwide Presence</a>
                            </div>
                            <div className="bg-[#fff] rounded-[8px] flex flex-wrap mx-[8px]">
                                <a href="##" className="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-[#0174EB] flex justify-center items-center border-r border-[#03386F] font-[600]">View PDF</a>
                                <a href="##" className="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-[#0174EB] flex justify-center items-center font-[600]">Share</a>
                            </div>
                        </div>
                    </motion.div>
                </div>
                <div
                    className="w-full"
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={pressReleaseVariants}
                    transition={{ delay: 0.8, ...transition }}
                >
                    <div className="flex flex-wrap justify-end items-center">
                        <a href="##" className="text-[18px] xs:text-[16px] text-[#0174EB] leading-[1.2] font-[600] p-[10px] xs:p-[5px] mx-[6px] xs:mx-[3px]">Prev</a>
                        <ul className="flex flex-wrap">
                            <li className="mx-[5px] xs:mx-[3px]">
                                <a href="##" className="w-[49px] h-[49px] sm:w-[45px] sm:h-[45px] xs:w-[36px] xs:h-[36px] flex justify-center items-center border border-[#0174EB] rounded-[100px] text-[20px] sm:text-[18px] xs:text-[16px] bg-[#0174EB] text-[#fff] font-[600]">01</a>
                            </li>
                            <li className="mx-[5px] xs:mx-[3px]">
                                <a href="##" className="w-[49px] h-[49px] sm:w-[45px] sm:h-[45px] xs:w-[36px] xs:h-[36px] flex justify-center items-center border border-[#0174EB] rounded-[100px] text-[20px] sm:text-[18px] xs:text-[16px] text-[#0174EB] font-[600]">02</a>
                            </li>
                            <li className="mx-[5px] xs:mx-[3px]">
                                <a href="##" className="w-[49px] h-[49px] sm:w-[45px] sm:h-[45px] xs:w-[36px] xs:h-[36px] flex justify-center items-center border border-[#0174EB] rounded-[100px] text-[20px] sm:text-[18px] xs:text-[16px] text-[#0174EB] font-[600]">...</a>
                            </li>
                            <li className="mx-[5px] xs:mx-[3px]">
                                <a href="##" className="w-[49px] h-[49px] sm:w-[45px] sm:h-[45px] xs:w-[36px] xs:h-[36px] flex justify-center items-center border border-[#0174EB] rounded-[100px] text-[20px] sm:text-[18px] xs:text-[16px] text-[#0174EB] font-[600]">10</a>
                            </li>
                        </ul>
                        <a href="##" className="text-[18px] xs:text-[16px] text-[#0174EB] leading-[1.2] font-[600] p-[10px] xs:p-[5px] mx-[6px] xs:mx-[3px]">Next</a>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default ServiceMarketing;
