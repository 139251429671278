import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import logo from '../../assets/images/logo.svg';
import AnimatedHamburgerButton from './AnimatedHamburgerButton';
import Loader from '../../Components/Loader';
import { fetchHeaderData } from '../../Strapi/UseStrapiApi';
import LazyLoad from 'react-lazy-load';
const Header = () => {
  const [ref, inView] = useInView({ threshold: 0.5 });

  const { data, isLoading, isError } = useQuery('headerData', fetchHeaderData, {
    refetchOnWindowFocus: false
  });

  if (isLoading) {
    return <Loader />; // Render the Loader component while loading
  }

  if (isError) {
    return <div>Error: {isError.message}</div>;
  }
  return (
    <motion.header
      className='bg-transparent absolute sm:static w-full z-[9] left-0 right-0 max-w-[1920px] m-[auto]'
      ref={ref}
    >
      <div className='container mx-auto max-w-[100%] px-[20px] sm:px-[0]'>
        <nav className="py-[16px] sm:py-[0]">
          <div className="flex justify-between sm:flex-col w-full items-center mx-auto">
            <motion.div
              className='sm:w-full sm:flex sm:justify-start sm:py-[16px] xxs:pb-[20px]'
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5 }}
            >
              <Link to="/" className="flex items-center w-[175px] xs:w-[170px] xxs:w-[125px] h-auto">
                <LazyLoad offset={100} once>
                  <motion.img
                    src={logo}
                    className="mr-3 h-full w-full"
                    alt="logo"
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={inView ? { opacity: 1, scale: 1 } : {}}
                    transition={{ duration: 0.5 }}
                  />
                </LazyLoad>
              </Link>
            </motion.div>
            <AnimatedHamburgerButton headerData={data} />
          </div>
        </nav>
      </div>
    </motion.header>
  );
};

export default Header;