import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import setifiedIcon from '../../assets/images/setifiedIcon.svg';

const CertifiedNanny = (props) => {
    const [ref, inView] = useInView({ threshold: 0.5 });
    return (
        <div ref={ref} className="flex flex-col items-center bg-[#0174EB] max-md:px-5 pt-[40px] pb-[60px]">
            <div className="flex gap-5 w-full max-md:flex-col max-md:gap-0 max-w-[1530px] pl-[97px] pr-[97px] xl:pr-[40px] xl:pl-[40px] ml-auto mr-auto xs:px-[20px] xxs:flex-wrap">
                <div className="flex flex-col relative w-[30%] pl-[30px] max-md:ml-0 sm:pl-[0px] xs:w-[50%] xxs:w-full">
                    <motion.div initial={{ opacity: 0, x: -50 }} animate={inView ? { opacity: 1, x: 0 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                        <div className="flex flex-col gap-[10px] self-stretch my-auto font-black max-md:mt-10 max-md:max-w-full">
                            <div className="text-6xl text-[#0174EB] leading-[66px] max-md:max-w-full max-md:text-4xl max-md:leading-10 xs:w-[75px] xs:h-[75px]">
                                <img src={setifiedIcon} alt="setifiedIcon" />
                            </div>
                            <div className="relative z-[9]">
                                <span className='mt-0 text-[40px] leading-[48px] mb-[5px] font-[500] text-[#fff] sm:text-[35px] sm:leading-[normal] xs:text-[28px] xxs:text-[20px]'>{props.certifyNanny.topTitle}</span>
                                <h2 className='text-[86px] lg:text-[66px] text-[#fff] font-[900] leading-[64px] sm:text-[52px] sm:leading-[normal] xs:text-[42px] xxs:text-[34px]'>{props.certifyNanny.Title}</h2>
                            </div>
                        </div>
                    </motion.div>
                </div>
                <div className="flex ml-5 w-[70%] max-md:ml-0 z-[9] xs:w-[50%] xxs:w-full xxs:ml-[0]">
                    <motion.div className="w-full" initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                        <div className="pl-16 max-md:max-w-full w-full -mb-[190px] pt-[40px] rounded-[20px] lg:mb-[0] xs:pt-[0] xs:mb-[0] xs:pl-[0]">
                            <iframe src="https://www.youtube.com/embed/5Peo-ivmupE?si=ZUPsLeuajVYqY7wo" className="max-w-full w-full h-[492px] sm:h-[400px] xs:h-[300px] rounded-[30px] sm:rouded-[25px] xs:rounded-[20px]" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default CertifiedNanny
