import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);

  const formatDate = (date) => {
    const options = { weekday: 'long' };
    return date.toLocaleDateString('en-US', options);
  };

  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="text-center relative sm:text-left" ref={datePickerRef}>
      <button
        onClick={() => setShowDatePicker(!showDatePicker)}
        className="bg-none text-white text-left p-0 rounded-md focus:outline-none"
      >
        <h4 className="text-[#000000] font-[500] text-[32px] leading-[38px] xl:text-[25px] xl:leading-[30px] xxs:text-[18px] xxs:leading-[1]">
          <strong className="text-[#0174EB] font-[900] text-[48px] leading-[56px] xl:text-[35px] lg:text-[30px] leading-[46px] lg:leading-[40px] xxs:text-[22px] xxs:leading-[1]">
            {selectedDate.getDate()}
          </strong>{' '}
          <span className="month-year">
            <span data-i18n="feb">{selectedDate.toLocaleString('default', { month: 'short' })}</span>’{selectedDate.getFullYear().toString().substr(-2)}
          </span>
        </h4>
        <span className="text-[#000000] font-normal text-[16px] leading-[22px] week-day xxs:text-[14px]">{formatDate(selectedDate)}</span>
      </button>

      {showDatePicker && (
        <div className="absolute top-12 left-0 z-10">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
              setShowDatePicker(false);
            }}
            dateFormat="dd MMM yy"
            inline
            minDate={selectedDate}
          />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
