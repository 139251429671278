import React from 'react';
import { motion } from 'framer-motion';
import counpunbg from '../../assets/images/coupun-bg.png';
import LazyLoad from 'react-lazy-load';
const Card = ({ title, price }) => {
    return (
      <motion.div
        className="card min-h-[120px] h-[120px] w-[220px] min-w-[220px] p-[15px] bg-[#FFFFFF] border-[1px] rounded-[15px] sm:w-[32%] sm:min-w-[auto] xs:w-[48%] xxs:h-[auto]"
        whileHover={{ scale: 1.05, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.2 }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="flex justify-between items-start">
            <span className="text-[#000000] text-[18px] leading-[22px] font-[700] mb-[0px] mr-[5px] sm:text-[17px] break-word">
              {title}
            </span>
          </div>
          <div className="flex justify-between items-end">
            <h3 className="text-[#0174EB] text-[40px] leading-[1] font-bold mb-[0px] sm:text-[38px] xxs:text-[30px]">${price}</h3>
          </div>
        </div>
      </motion.div>
    );
  };

const Charges = (props) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="charges-sec bg-[#0174EB] rounded-[26px] p-[15px] xxs:p-[0]">
                <div className="flex w-full items-center sm:relative sm:flex-col sm:pb-[50px]">
                    <div className="charges-content-sec pt-[0] pb-[30px] relative sm:static w-[70%] sm:w-[100%]">
                        <h4 className='text-[#FFFFFF] text-center text-[40px] leading-[47px] font-bold mb-[20px] xs:text-[30px] xxs:text-[22px] uppercase'>Total Charges</h4>
                        <div className="flex flex-wrap gap-[10px] mx-[20px] sm:mx-[0px]">
                            <Card title="Airfare" price="300" />
                            <Card title="Air Carrier Check in Baggage Fee" price="100" />
                            <Card title="Air Nanny" price="500" />
                            <Card title="Extra Pet Fee" price="30" />
                            <Card title="Door to Door Fee" price="10" />
                            <Card title="Expedited Fee" price="20" />
                            <Card title="Administrative & Insurance" price="50" />
                        </div>
                        <div className="mt-[10px] w-full mb-[5px] absolute -bottom-[60px] flex gap-[15px] items-center justify-center xxs:-bottom-[35px]">
                            <motion.button 
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                            className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[7] xs:px-[25px] xxs:px-[20px] xxs:py-[8px]' onClick={props.closehangleCoupon}
                            >
                                <span className='text-[32px] xl:text-[25px] leading-[38px] xl:leading-[30px] font-[900] text-[#FFFFFF] uppercase xs:text-[20px] xxs:text-[14px] xxs:leading-[normal] xxs:font-[500]'>Back</span>
                            </motion.button>
                            <motion.button 
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                            className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[7] xs:px-[25px] xxs:px-[20px] xxs:py-[8px]' onClick={props.handlePets}
                            >
                                <span className='text-[32px] xl:text-[25px] leading-[38px] xl:leading-[30px] font-[900] text-[#FFFFFF] uppercase xs:text-[20px] break-word xxs:text-[14px] xxs:leading-[normal] xxs:font-[500]'>Next</span>
                            </motion.button>
                        </div>
                    </div>
                    <div className="coupon-sec w-[30%] sm:w-[500px] xs:w-[100%]">
                        <motion.div className="coupon-card p-[15px] relative w-full h-[370px] bg-[#0068D3] border-[2px] border-dotted rounded-[35px] border-[#FFFFFF] xs:h-[350px]"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            <LazyLoad offset={100} once>
                                <img src={counpunbg} alt="counpunbg" className='absolute w-[auto] h-[auto] top-[0] right-[0] md:w-[75%] sm:w-[50%] lg:h-[145px]' />
                            </LazyLoad>
                            <div className="flex flex-col items-center h-full justify-end">
                                <span className='text-[#FFFFFF] text-[32px] leading-[37px] font-[500] mb-[5px] xxs:text-[22px] xxs:leading-[noraml]'>Total</span>
                                <h3 className='text-[#FFFFFF] text-[66px] leading-[72px] font-bold mb-[10px] lg:text-[75px] lg:leading-[1] xxs:text-[55px] xxs:leading-[noraml]'>$560</h3>
                                <motion.button 
                                className='px-[40px] py-[20px] rounded-[15px] mb-[20px] bg-[#FFFFFF] lg:p-[15px]'>
                                    <span className='text-[#0174EB] text-[26px] leading-[30px] font-[700] mb-[0px] lg:text-[22px] xxs:text-[22px] xxs:leading-[noraml]'>Apply Coupon Code</span>
                                </motion.button>
                                <motion.button 
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ duration: 0.3, ease: "easeInOut" }}

                                className='px-[30px] py-[20px] rounded-[36px] mb-[20px] bg-[#044384] xxs:px-[20px] xxs:py-[12px]'>
                                    <span className='text-[#FFFFFF] text-[24px] leading-[28px] font-[700] mb-[0px] uppercase lg:text-[22px] xxs:text-[17px] xxs:leading-[noraml]'>Grand Total</span>
                                </motion.button>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Charges