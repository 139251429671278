import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import pressimg1 from '../../assets/images/pressimg1.png';
import pressimg2 from '../../assets/images/pressimg2.png';
import pressimg3 from '../../assets/images/pressimg3.png';

const PressRelease = () => {
    const [ref, inView] = useInView({ threshold: 0.3, });

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    const pageVariants = {
        initial: { opacity: 0 },
        in: { opacity: 1 },
        out: { opacity: 0 },
    };

    const pageTransition = {
        type: 'tween',
        duration: 0.5,
    };
    const pressReleaseVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };
    const transition = {
        duration: 0.5,
        ease: 'easeOut',
    };

    return (
        <motion.div
            ref={ref}
            initial="initial"
            animate={inView ? "in" : "out"}
            variants={pageVariants}
            transition={pageTransition}
            className="bg-linear-press"
        >
            <div className="max-w-[1597px] px-[20px] pt-[75px] sm:pt-[55px] pb-[55px] xs:pt-[40px] xs:pb-[40px] m-auto">
                <div className="text-center mb-[60px] sm:mb-[40px] xs:mb-[25px]">
                    <h2 className="text-white text-[85px] 2xl:text-[76px] xl:text-[65px] lg:text-[50px] md:text-[40px] leading-[90px] 2xl:leading-[80px] xl:leading-[70px] lg:leading-[55px] font-[900] xxs:text-[34px] xxs:leading-[1]">Press Releases</h2>
                </div>
                <div className="flex justify-between flex-wrap mb-[60px] sm:mb-[40px] xs:mb-[20px]">
                    <motion.div
                        className="w-[32%] xs:w-[48%] xs:mb-[30px] xxs:mb-[20px] xxs:w-full"
                        variants={cardVariants}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        transition={{ delay: 0.2 }}
                    >
                        <div className="bg-white rounded-[6px] pb-[10px]">
                            <div className="w-full">
                                <img className="w-full" src={pressimg1} alt="img" />
                            </div>
                            <div className="px-[25px] xl:px-[20px] lg:px-[15px] lg:py-[15px] pt-[20px] pb-[28px]">
                                <p className="text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] text-[#363636] mb-[9px] font-[600]">InCabinPets Invites Air Nannies to Join Exclusive Pre-Launch:</p>
                                <a href="##" className="text-[#004D9D] text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] font-[500]">Expanding Nationwide Presence</a>
                            </div>
                            <div className="bg-[#005EC0] rounded-[8px] flex flex-wrap mx-[8px]">
                                <a href="##" className="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-white flex justify-center items-center border-r border-[#03386F] font-[600]">View PDF</a>
                                <a href="##" className="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-white flex justify-center items-center font-[600]">Share</a>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        className="w-[32%] xs:w-[48%] xs:mb-[30px] xxs:mb-[20px] xxs:w-full"
                        variants={cardVariants}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        transition={{ delay: 0.4 }}
                    >
                        <div className="bg-white rounded-[6px] pb-[10px]">
                            <div class="w-full">
                                <img className="w-full" src={pressimg2} alt="image2" />
                            </div>
                            <div className="px-[25px] xl:px-[20px] lg:px-[15px] lg:py-[15px] pt-[20px] pb-[28px]">
                                <p className="text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] text-[#363636] mb-[9px] font-[600]">InCabinPets Invites Air Nannies to Join Exclusive Pre-Launch:</p>
                                <a href="##" className="text-[#004D9D] text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] font-[500]">Expanding Nationwide Presence</a>
                            </div>
                            <div className="bg-[#005EC0] rounded-[8px] flex flex-wrap mx-[8px]">
                                <a href="##" className="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-white flex justify-center items-center border-r border-[#03386F] font-[600]">View PDF</a>
                                <a href="##" class="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-white flex justify-center items-center font-[600]">Share</a>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        className="w-[32%] xs:w-[48%] xs:mb-[30px] xxs:mb-[20px] xxs:w-full"
                        variants={cardVariants}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        transition={{ delay: 0.6 }}
                    >
                        <div className="bg-white rounded-[6px] pb-[10px]">
                            <div className="w-full">
                                <img className="w-full" src={pressimg3} alt="image3" />
                            </div>
                            <div class="px-[25px] xl:px-[20px] lg:px-[15px] lg:py-[15px] pt-[20px] pb-[28px]">
                                <p className="text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] text-[#363636] mb-[9px] font-[600]">InCabinPets Invites Air Nannies to Join Exclusive Pre-Launch:</p>
                                <a href="##" className="text-[#004D9D] text-[26px] xl:text-[22px] lg:text-[18px] sm:text-[16px] leading-[1.2] font-[500]">Expanding Nationwide Presence</a>
                            </div>
                            <div className="bg-[#005EC0] rounded-[8px] flex flex-wrap mx-[8px]">
                                <a href="##" class="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-white flex justify-center items-center border-r border-[#03386F] font-[600]">View PDF</a>
                                <a href="##" class="w-1/2 h-[55px] sm:h-[48px] xs:h-[42px] text-[24px] xl:text-[20px] sm:text-[18px] text-white flex justify-center items-center font-[600]">Share</a>
                            </div>
                        </div>
                    </motion.div>
                </div>

                <motion.div
                    className="w-full"
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={pressReleaseVariants}
                    transition={{ delay: 0.8, ...transition }}
                >
                    <div className="flex flex-wrap justify-end items-center">
                        <a href="##" className="text-[18px] xs:text-[16px] text-white leading-[1.2] font-[600] p-[10px] xs:p-[5px] mx-[6px] xs:mx-[3px]">Prev</a>
                        <ul className="flex flex-wrap">
                            <li className="mx-[5px] xs:mx-[3px]">
                                <a href="##" className="w-[49px] h-[49px] sm:w-[45px] sm:h-[45px] xs:w-[36px] xs:h-[36px] flex justify-center items-center border border-white rounded-[100px] text-[20px] sm:text-[18px] xs:text-[16px] bg-white text-[#005EC0] font-[600]">01</a>
                            </li>
                            <li className="mx-[5px]">
                                <a href="##" className="w-[49px] h-[49px] sm:w-[45px] sm:h-[45px] xs:w-[36px] xs:h-[36px] flex justify-center items-center border border-white rounded-[100px] text-[20px] sm:text-[18px] xs:text-[16px] text-white font-[600]">02</a>
                            </li>
                            <li className="mx-[5px]">
                                <a href="##" class="w-[49px] h-[49px] sm:w-[45px] sm:h-[45px] xs:w-[36px] xs:h-[36px] flex justify-center items-center border border-white rounded-[100px] text-[20px] sm:text-[18px] xs:text-[16px] text-white font-[600]">...</a>
                            </li>
                            <li class="mx-[5px]">
                                <a href="##" className="w-[49px] h-[49px] sm:w-[45px] sm:h-[45px] xs:w-[36px] xs:h-[36px] flex justify-center items-center border border-white rounded-[100px] text-[20px] sm:text-[18px] xs:text-[16px] text-white font-[600]">10</a>
                            </li>
                        </ul>
                        <a href="##" className="text-[18px] xs:text-[16px] text-white leading-[1.2] font-[600] p-[10px] xs:p-[5px] mx-[6px] xs:mx-[3px]">Next</a>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default PressRelease;
