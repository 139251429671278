import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const IntroducingInCabinPet = (props) => {
    const [ref, inView] = useInView({ threshold: 0.5 });
    return (
        <div ref={ref} className="max-md:px-5">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-w-[1530px] pl-[97px] pr-[33px] xl:pl-[40px] ml-auto mr-auto xs:px-[20px] xxs:flex-wrap items-center">
                <motion.div className="w-[55%]" initial={{ opacity: 0, x: -50 }} animate={inView ? { opacity: 1, x: 0 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                    <div className="flex flex-col  max-md:ml-0 xxs:w-full">
                        <div className="flex flex-col py-[40px] my-auto font-black max-md:mt-10 max-md:max-w-full">
                            <div className="text-[64px] lg:text-[42px] lg:leading-[normal] text-[#0174EB] leading-[66px] max-md:max-w-full max-md:text-4xl max-md:leading-10 xs:text-[36px] xxs:text-[30px]">
                                {props.introNannies.Title}
                            </div>
                            <div className="mt-5 text-3xl font-medium leading-10 text-slate-500 sm:text-[28px] xs:text-[24px] xxs:text-[18px] xxs:leading-[normal]">
                                {props.introNannies.description}
                            </div>
                            <motion.a href="/CreateAccount" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="justify-center self-start px-8 py-6 mt-10 text-3xl text-center text-white bg-[#0174EB] rounded-2xl max-md:px-5 max-md:mt-10 sm:text-[28px] xs:text-[24px] xxs:max-w-max xxs:px-[15px] xxs:py-[12px] xxs:font-[500] xxs:text-[20px]">
                                REGISTER NOW
                            </motion.a>
                        </div>
                    </div>
                </motion.div>
                <motion.div className="w-[45%]" initial={{ opacity: 0, x: 50 }} animate={inView ? { opacity: 1, x: 0 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                    <div className="flex flex-col ml-5 xxs:w-full xxs:ml-0">
                        <div className="flex flex-col grow justify-center items-end self-stretch pl-16 max-md:max-w-full -mt-[90px] xs:pl-[0px] xxs:mt-[0]">
                            <img
                                loading="lazy"
                                src={"https://strapi.incabinpets.com" + props.introNannies.image.data.attributes.url}
                                className="max-w-full"
                                alt='image3'
                            />
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default IntroducingInCabinPet
