import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import App from './App';


function isLocalhost() {
  return window.location.hostname === 'localhost';
}

window.axios = axios;
// Default headers for API calls
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Base URL for your API calls
if (isLocalhost()) {
  window.axios.defaults.baseURL = 'http://starterapp.test/api';
} else {
  window.axios.defaults.baseURL = 'https://dev.incabinpets.com/api';
}
// If a token exists in local storage, set it in axios authorization header
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
// Intercept responses. If 401 error, clear token and redirect to login
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('userid');
      axios.defaults.headers.common['Authorization'] = 'Bearer';
      return <Navigate to="/login" />;
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);
