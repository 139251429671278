import React from 'react';
import { useQuery } from 'react-query';
import Loader from '../../Components/Loader';
import AboutColumns from '../../Components/AboutColumns/AboutColumns';
import { fetchAboutUsData } from '../../Strapi/UseStrapiApi';

const AboutUs = () => {
    const { data, isLoading, isError } = useQuery('aboutUsData', fetchAboutUsData, {
        refetchOnWindowFocus: false
    });

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div>Error: {isError.message}</div>;
    }
    return (
        <>
            <div className="pt-[120px] sm:pt-[40px]">
                {data.AboutTwoColumns.map(aboutUs => (
                    <AboutColumns
                        title={aboutUs.heading}
                        description={aboutUs.descriptions}
                        image={"https://strapi.incabinpets.com" + aboutUs.image.data.attributes.url}
                        reverse={aboutUs.id % 2 !== 0}
                    />
                ))}
            </div>
        </>
    )
}

export default AboutUs
