import React from 'react';
import { useQuery } from 'react-query';
import Loader from '../../Components/Loader';
import { fetchServicesData } from '../../Strapi/UseStrapiApi';

const Policies = (props) => {
    const { data, isLoading, isError } = useQuery(['serviceData', props.id], () => fetchServicesData(props.id), {
        refetchOnWindowFocus: false
    });
    console.log(data);
    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <div>Error: {isError.message}</div>;
    }
    return (
        <>
            <div className="pt-[180px] sm:pt-[60px] xs:pt-[40px] xxs:pt-[30px] pb-[60px] sm:pb-[40px] xxs:pb-[30px]">
                <div className="max-w-[1310px] px-[20px] m-auto">
                    <div className="mb-[40px] sm:mb-[30px]">
                        <h1 className="text-[90px] sm:text-[60px] xs:text-[45px] xxs:text-[35px] text-[#0174EB] leading-[1.2] font-bold mb-5 sm:mb-4 sm:mb-4 xxs:mb-3"><span className="font-[400]">{data.sub_title}</span> {data.title}</h1>
                        <p className="text-[22px] sm:text-[18px] xs:text-[16px] leading-[26px] sm:leading:[1.2]">{data.description}</p>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-[41px] sm:text-[25px] xs:text-[20px] leading-[1.2] font-[500]">{data.inner_title}</h3>
                    </div>
                    <div className="text-[22px] sm:text-[18px] leading-[26px] sm:leading:[1.2] leading-[26px]">
                        {data.inner_description.map(paras => (
                            <p className="mb-5 sm:mb-4" key={paras.id}>{paras.children[0].text}</p>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Policies
