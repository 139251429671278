import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
// import secMap from '../../assets/images/sec-map.png';

const NannyListing = (props) => {
    const [ref, inView] = useInView({ threshold: 0.5 });
    return (
        <>
            <div ref={ref} className="flex flex-col items-center pb-5 max-md:px-5">
                <div className="flex flex-col w-full max-w-[1530px] pl-[10px] pr-[97px] xl:pr-[40px] xs:px-[20px]">
                    <div className="max-md:px-5 max-md:max-w-full pb-[40px] pt-[175px] lg:pt-[80px] xxs:pb-[0px]">
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0 sm:flex-wrap">
                            <motion.div className="w-[65%]" initial={{ opacity: 0, y: 50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                                <div className="flex flex-col lg:w-[55%] max-md:ml-0 sm:w-full">
                                    <motion.div initial={{ opacity: 0, x: -50 }} animate={inView ? { opacity: 1, x: 0 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                                        <div className="flex flex-col grow self-stretch max-md:mt-10 max-md:max-w-full">
                                            <img
                                                src={"https://strapi.incabinpets.com" + props.listNanny.Image.data.attributes.url}
                                                className="w-full max-md:max-w-full"
                                                alt='image4'
                                            />
                                        </div>
                                    </motion.div>
                                </div>
                            </motion.div>
                            <motion.div className="w-[35%]" initial={{ opacity: 0, y: 50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                                <div className="flex flex-col ml-5 lg:w-[45%] max-md:ml-0  sm:w-full xs:ml-0">
                                    <motion.div initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.5, duration: 1 }}>
                                        <div className="flex gap-1.5 self-stretch py-1.5 max-md:flex-wrap max-md:mt-10">
                                            <ul className='text-[30px] leading-[37px] text-[500] pt-[50px] sm:text-[28px] xs:text-[22px] xs:leading-[normal] xxs:text-[18px] xxs:pt-[10px]'>
                                                {props.listNanny.listing.map(listItem => (
                                                    <li className='flex mb-[5px]' key={listItem.id}>
                                                        <div className="shrink-0 bg-[#0174EB] rounded-full h-[18px] w-[18px] mt-[8px] mr-[5px] xl:h-[16px] xl:w-[16px] sm:h-[15px] sm:w-[15px] xs:h-[10px] xs:w-[10px] xxs:h-[8px] xxs:w-[8px] xxs:mt-[5px]" />
                                                        {listItem.listingContent}
                                                    </li>
                                                ))}

                                            </ul>
                                        </div>
                                    </motion.div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full max-w-[1530px] ml-auto mr-auto py-[20px] px-[20px] xs:px-[20px]">
                <motion.a whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} href="#" className="justify-center cursor-pointer self-center px-16 py-7 text-5xl lg:text-[35px] font-black text-center text-white bg-[#0174EB] rounded-[36.5px] lg:rounded-[24px] sm:px-12 sm:font-[600] sm:text-[32px] xs:text-[28px] xs:px-[32px] xs:py-[24px] xs:rounded-[16px] xxs:max-w-max xxs:px-[15px] xxs:py-[12px] xxs:font-[500] xxs:text-[20px] xxs:leading-[2.25rem]">
                    REGISTER NOW
                </motion.a>
            </div>
        </>
    )
}

export default NannyListing
