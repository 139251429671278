import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import LazyLoad from 'react-lazy-load';

const ShippingFeature = (props) => {
    console.log(props.data);
    const [ref, inView] = useInView({ threshold: 0.3 });

    return (
        <div ref={ref} className="container mx-auto max-w-[1597px] px-[20px]">
            <div className="block pb-[100px] xl:py-[60px] lg:py-[40px] overflow-hidden">
                <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    animate={inView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 0.5 }}
                    className="text-center pt-[160px] pb-[50px] lg:pt-[60px]">
                    <h2 className="text-[85px] 2xl:text-[72px] xl:text-[65px] lg:text-[55px] leading-[95px] 2xl:leading-[80px] xl:leading-[70px] lg:leading-[60px] font-[900] text-[#0174EB] xxs:text-[45px] xxs:leading-[1]">{props.data.title}</h2>
                </motion.div>
                <motion.div
                    className="mx-auto flex flex-wrap justify-center gap-6 sm:gap-[1rem]"
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}}
                    transition={{ duration: 0.5 }}
                >
                    {props.data.FeatureBox.map((feature, index) => (
                        <motion.div
                            key={index}
                            className="w-[32%] max-w-[447px] h-[447px] 2xl:max-w-[400px] lg:max-w-[310px] 2xl:h-[400px] xl:h-[380px] lg:h-[340px] text-center sm:max-w-[48%] sm:h-[300px] sm:w-[50%] xxs:w-[100%] xxs:max-w-[100%]"
                            initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5, delay: index * 0.2 }}
                        >
                            <motion.div
                                className="flex flex-col h-full items-center justify-center bg-white p-5 rounded-[45px] border border-blue-500"
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <LazyLoad offset={100} once>
                                    <motion.img
                                        src={"https://strapi.incabinpets.com" + feature.icon.data.attributes.url}
                                        className="h-auto max-w-[94px] xl:max-w-[75px] xs:xl:max-w-[60px] w-auto mb-[30px]"
                                        alt="Feature Icon"
                                        initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100 }}
                                        animate={inView ? { opacity: 1, x: 0 } : {}}
                                        transition={{ duration: 0.5, delay: index * 0.3 }}
                                    />
                                </LazyLoad>
                                <h3 className="text-[42px] leading-[1.3] 2xl:text-[40px] xl:text-[35px] lg:text-[30px] font-[900] text-[#0174EB] max-w-[316px]">
                                    {feature.icon_title}
                                </h3>
                            </motion.div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </div>
    );
};

export default ShippingFeature;
