import React, { useState, useRef, useEffect } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import BookingCharges from '../BookingCharges/Charges';
import PetProfile from '../PetProfile/PetProfile';
import PetProfileDetail from '../PetProfile/PetProfileDetail';
import BookingTransport from '../BookingTransport/BookingTransport';
import BookingDelivery from '../BookingDelivery/BookingDelivery';
import DatePicker from '../DatePicker/DatePicker';
import LeavingFrom from '../CountryMenu/LeavingFrom';
import GoingTo from '../CountryMenu/GoingTo';
import rotateIcon from '../../assets/images/rotate-icon.svg';
import dropdownIcon from '../../assets/images/dropdown-arrow.svg';
import LazyLoad from 'react-lazy-load';

const PetTabs = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [isActive1, setIsActive1] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [iscoupon, setIsCoupon] = useState(true);
    const [istransport, setIsTransport] = useState(false);
    const [isdelivery, setIsDelivery] = useState(false);
    const [ischarges, setIsCharges] = useState(false);
    const [ispets, setIsPets] = useState(false);
    const [ispetsDetail, setIsPetsDetail] = useState(false);
    const [showAnotherStop, setShowAnotherStop] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const [isOpen1, setIsOpen1] = useState(false);
    const [selectedOption1, setSelectedOption1] = useState('');

    const flightPetsOption = ['01', '02', '03', '04', '05', '06', '07', '08'];
    const carPetsOption = ['01', '02', '03', '04', '05', '06', '07', '08'];

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const handleOptionClick1 = (option) => {
        setSelectedOption1(option);
        setIsOpen1(false);
        setIsCoupon(false);
        setIsTransport(true);
        setIsDelivery(false);
        setIsCharges(false);
        setIsPets(false);
        setIsPetsDetail(false);
    };

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
            // Remove scrollbar from body when popup opens
            document.body.style.overflow = 'hidden';
        } else {
            // Restore scrollbar when popup closes
            document.body.style.overflow = 'auto';
        }
    };

    const handleToggle = () => {
        setIsActive(true);
        setIsActive1(false);
        setIsActive2(false);
    };
    const handleToggle1 = () => {
        setIsActive1(true);
        setIsActive(false);
        setIsActive2(false);
    };
    const handleToggle2 = () => {
        setIsActive2(true);
        setIsActive(false);
        setIsActive1(false);
    };

    const handleCharges = () => {
        setIsCoupon(false);
        setIsTransport(false);
        setIsDelivery(false);
        setIsCharges(true);
        setIsPets(false);
        setIsPetsDetail(false);
    };

    const handleTranport = () => {
        setIsCoupon(false);
        setIsTransport(true);
        setIsDelivery(false);
        setIsCharges(false);
        setIsPets(false);
        setIsPetsDetail(false);
    };

    const handlePets = () => {
        setIsCoupon(false);
        setIsTransport(false);
        setIsDelivery(false);
        setIsCharges(false);
        setIsPets(true);
        setIsPetsDetail(false);
    }

    const handlePetsDetail = () => {
        setIsCoupon(false);
        setIsTransport(false);
        setIsDelivery(false);
        setIsCharges(false);
        setIsPets(false);
        setIsPetsDetail(true);
    }

    const closehangleCoupon = () => {
        setIsCoupon(true);
    };
    const popupRef = useRef(null);

    const libraries = ['places'];
    const handlePlaceSelect = (place) => {
        console.log(place);
    };

    const handlePlaceSelect2 = (place) => {
        console.log(place);
    };
    const toggleAnotherStop = () => {
        setShowAnotherStop(!showAnotherStop);
    };
    const [ref, inView] = useInView({ threshold: 0.4 });
    return (
        <>
            <motion.div
                ref={ref}
                className="container mx-auto max-w-[1597px] px-[20px] xxs:px-[15px] -mt-[430px] pb-[100px] xs:pb-[50px] sm:-mt-[320px] xxs:-mt-[280px] relative"
            >
                <div className="block w-full p-[20px] rounded-[26px] xxs:p-[10px] sm:bg-[#0174EB]">
                    {iscoupon ? (
                        <div className="tab-sec">
                            <div className="dark:border-gray-700">
                                <ul
                                    className="flex flex-wrap justify-center -mb-px text-sm font-medium text-center sm:flex-nowrap"
                                    id="default-tab"
                                    data-tabs-toggle="#default-tab-content"
                                    role="tablist"
                                >
                                    <motion.li
                                        initial={{ opacity: 0 }}
                                        animate={inView ? { opacity: 1 } : {}}
                                        transition={{ delay: 0.1 }}
                                        className="me-2"
                                        role="presentation"
                                    >
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className={`inline-block border-[2px] border-[solid] rounded-tl-[15px] rounded-br-[0px] rounded-tr-[15px] rounded-bl-none py-[20px] px-[30px] xl:py-[15px] xl:px-[25px] text-[32px] xl:text-[25px] font-normal leading-[38px] xl:leading-[33px] sm:p-[15px] xs:text-[16px] xs:text-[900] xxs:text-[14px] xxs:leading-[normal]  xxs:py-[15px] xxs:px-[7px] ${isActive ? '!bg-[#FFFFFF] !text-[#0174EB] !font-bold !border-[#FFFFFF]' : '!bg-[#0174EB] !text-[#FFFFFF] !border-[#000000]'}`}
                                            aria-expanded={isActive.toString()}
                                            onClick={handleToggle}
                                            id="profile-tab"
                                            data-tabs-target="#profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="profile"
                                            aria-selected="true"
                                        >
                                            Flight Transport
                                        </motion.button>
                                    </motion.li>
                                    <motion.li
                                        initial={{ opacity: 0 }}
                                        animate={inView ? { opacity: 1 } : {}}
                                        transition={{ delay: 0.2 }}
                                        className="me-2"
                                        role="presentation"
                                    >
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className={`inline-block border-[2px] border-[solid] rounded-tl-[15px] rounded-br-[0px] rounded-tr-[15px] rounded-bl-none py-[20px] px-[30px] xl:py-[15px] xl:px-[25px] text-[32px] xl:text-[25px] font-normal leading-[38px] xl:leading-[33px] sm:p-[15px] xs:text-[16px] xs:text-[900] xxs:text-[14px] xxs:leading-[normal] xxs:py-[15px] xxs:px-[7px] ${isActive1 ? '!bg-[#FFFFFF] !text-[#0174EB] !font-bold !border-[#FFFFFF]' : '!bg-[#0174EB] !text-[#FFFFFF] !border-[#000000]'}`}
                                            aria-expanded={isActive1.toString()}
                                            onClick={handleToggle1}
                                            id="dashboard-tab"
                                            data-tabs-target="#dashboard"
                                            type="button"
                                            role="tab"
                                            aria-controls="dashboard"
                                            aria-selected="false"
                                        >
                                            Car Transport
                                        </motion.button>
                                    </motion.li>
                                    <motion.li
                                        initial={{ opacity: 0 }}
                                        animate={inView ? { opacity: 1 } : {}}
                                        transition={{ delay: 0.3 }}
                                        role="presentation"
                                    >
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            className={`inline-block border-[2px] border-[solid] rounded-tl-[15px] rounded-br-[0px] rounded-tr-[15px] rounded-bl-none py-[20px] px-[30px] xl:py-[15px] xl:px-[25px] text-[32px] xl:text-[25px] font-normal leading-[38px] xl:leading-[33px] sm:p-[15px] xs:text-[16px] xs:text-[900] xxs:text-[14px] xxs:leading-[normal]  xxs:py-[15px] xxs:px-[7px] ${isActive2 ? '!bg-[#FFFFFF] !text-[#0174EB] !font-bold !border-[#FFFFFF]' : '!bg-[#0174EB] !text-[#FFFFFF] !border-[#000000]'}`}
                                            aria-expanded={isActive2.toString()}
                                            onClick={handleToggle2}
                                            id="settings-tab"
                                            data-tabs-target="#settings"
                                            type="button"
                                            role="tab"
                                            aria-controls="settings"
                                            aria-selected="false"
                                        >
                                            Home Pet Nanny
                                        </motion.button>
                                    </motion.li>
                                </ul>
                            </div>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={inView ? { opacity: 1, y: 0 } : {}}
                                transition={{ delay: 0.4 }}
                                id="default-tab-content"
                                className="bg-[#0174EB] rounded-[26px]"
                            >
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={inView ? { opacity: isActive ? 1 : 0, y: isActive ? 0 : 20 } : {}}
                                    transition={{ delay: 0.5 }}
                                    className={`flex-col ${isActive ? 'flex' : 'hidden'}`}
                                    id="profile"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                                >
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={inView ? { opacity: 1, y: 0 } : {}}
                                        transition={{ delay: 0.5 }}
                                        className='border-[1px] border-[solid] border-[#004A97] bg-[#FFFFFF] px-[30px] rounded-[26px] md:px-[18px] sm:px-[0px] sm:bg-transparent sm:border-none sm:rounded-[0px]'
                                    >
                                        <motion.div
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={inView ? { opacity: 1, y: 0 } : {}}
                                            transition={{ delay: 0.7 }}
                                            className='flex justify-between sm:flex-col sm:gap-[10px]'
                                        >
                                            <div className='flex sm:w-full sm:justify-center sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px]'>
                                                <LeavingFrom />
                                                <div className='flex items-center py-[20px] pl-[50px] lg:pl-[30px] pr-[60px] lg:pr-[30px] md:pr-[20px] relative border-r border-solid border-[#004A97] sm:border-none  sm:w-1/2 sm:py-[20px] sm:pl-[70px] sm:pr-[20px] xxs:pl-[22px]'>
                                                    <GoingTo />
                                                    <div className='h-[63px] w-[63px] xl:h-[50px] xl:w-[50px] lg:h-[40px] lg:w-[40px] border-[1px] absolute cursor-pointer bottom-[40px] -left-[31px] xl:-left-[25px] lg:-left-[20px] border-[solid] border-[#004A97] rounded-[50%] xs:h-[50px] xs:w-[50px] xxs:h-[34px] xxs:w-[34px] xxs:top-[38%] xxs:-left-[18px] z-[2]'>
                                                        <LazyLoad offset={100} once>
                                                            <img src={rotateIcon} alt="rotateIcon" className='h-full w-full rounded-[50%]' />
                                                        </LazyLoad>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='py-[20px] px-[20px] lg:py-[10px] lg:px-[10px] flex items-center border-r border-solid border-[#004A97] min-h-[146px] sm:w-full sm:justify-center sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px] sm:py-[20px] sm:min-h-[65px]'>
                                                <div className="block sm:w-full">
                                                    <div className="relative inline-block sm:w-full">
                                                        <button
                                                            onClick={() => setIsOpen(!isOpen)}
                                                            className="flex min-w-[155px] xl:min-w-[140px] lg:min-w-[110px] justify-between items-center text-[#010101] font-[500] text-[32px] xl:text-[25px] lg:text-[20px] leading-[38px] lg:leading-[27px] xl:leading-[30px] sm:w-full"
                                                        >
                                                            {selectedOption || '# of Pets'}
                                                            <LazyLoad offset={100} once>
                                                                <img src={dropdownIcon} alt="dropdownIcon" className='ml-[5px] lg:max-w-[14px]' />
                                                            </LazyLoad>
                                                        </button>
                                                        {isOpen && (
                                                            <div className="absolute z-[91] w-full mt-2 bg-white border-[2px] border-[#004A97] rounded-[18px] shadow sm:w-[110px] sm:right-[0px]">
                                                                {flightPetsOption.map((option, index) => (
                                                                    <div
                                                                        key={option}
                                                                        onClick={() => handleOptionClick(option)}
                                                                        className={`p-2 cursor-pointer ${index === flightPetsOption.length - 1 ? '' : 'border-b-[1px] border-b-[#004A97]'}`}
                                                                    >
                                                                        {option}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='py-[20px] px-[15px] lg:py-[10px] lg:px-[10px] border-r border-solid border-[#004A97] sm:w-full sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px] items-center flex'>
                                                <div className="flex flex-col">
                                                    <span className='text-[#010101] font-normal text-[22px] leading-[28px] xl:text-[18px] lg:text-[14px] xl:leading-[24px] lg:leading-[20px]'>Preferred Date 01</span>
                                                    <DatePicker />
                                                </div>
                                            </div>
                                            <div className='py-[20px] px-[15px] lg:py-[10px] lg:px-[10px] border-r border-solid border-[#004A97] sm:w-full sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px] items-center flex'>
                                                <div className="flex flex-col">
                                                    <span className='text-[#010101] font-normal text-[22px] leading-[28px] xl:text-[18px] lg:text-[14px] xl:leading-[24px] lg:leading-[20px]'>Preferred Date 02</span>
                                                    <DatePicker />
                                                </div>
                                            </div>
                                            <div className='py-[20px] px-[15px] sm:w-full sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px] flex items-center'>
                                                <div className="flex flex-col">
                                                    <span className='text-[#010101] font-normal text-[22px] leading-[28px] xl:text-[18px] lg:text-[14px] xl:leading-[24px] lg:leading-[20px]'>Preferred Date 03</span>
                                                    <DatePicker />
                                                </div>
                                            </div>
                                        </motion.div>
                                    </motion.div>
                                    <div className="my-[20px] sm:mb-[0] flex">
                                        <div className="flex w-full justify-center sm:flex-wrap">
                                            <div className="flex w-full xs:flex-wrap">
                                                <div className="flex px-[70px] 2xl:px-[50px] xl:px-[30px] xs:pl-[0px] xs:pr-[20px]">
                                                    <div className="flex mt-1 relative">
                                                        <input
                                                            id="helper-checkbox"
                                                            aria-describedby="helper-checkbox-text"
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={toggleCheckbox}
                                                            value=""
                                                            className="w-[24px] h-[24px] relative border-black focus:border-black focus:[box-shadow:none] checked:border-black checked:bg-blue-500 xs:w-[19px] xs:h-[19px]"
                                                        />
                                                        <motion.div
                                                            ref={popupRef}
                                                            initial={{ opacity: 0, scale: 0.8 }}
                                                            animate={inView ? { opacity: isChecked ? 1 : 0, scale: isChecked ? 1 : 0.8 } : {}}
                                                            transition={{ duration: 0.2 }}
                                                            className={`fixed top-[30px] xs:top-[78px] sm:h-[475px] w-full max-w-[1332px] lg:max-w-[95%] z-[99] left-[0] right-[0] m-auto rounded-[18px] border-[3px] px-[20px] py-[20px] bg-[#FFFFFF] border-[solid] border-[#004A97] text-sm xxs:top-[70px] xxs:p-[15px] ${isChecked ? 'block' : 'hidden'}`}
                                                        >
                                                            <button onClick={toggleCheckbox} className="absolute top-[-10px] w-[30px] h-[30px] right-[-5px] text-[28px] leading-[23px] p-[0] border-[solid] border-[2px] bg-[#fff] border-[#0174EB] text-[#0174EB] font-[700] focus:outline-none rounded-[100px]">&times;</button>
                                                            <form action="" className='flex flex-col gap-[20px] items-center aligns-center xxs:gap-[10px]'>
                                                                <div
                                                                    className="w-full xs:h-fill max-h-[70vh] sm:max-h-[305px] xs:max-h-[330px] overflow-x-hidden overflow-y-auto"
                                                                    style={{ scrollbarWidth: 'thin', scrollbarColor: '#ddd #fff' }}>
                                                                    <div className='flex w-full xs:flex-col'
                                                                    >
                                                                        <div className="w-[50%] pr-[30px] py-[20px] pt-0 border-r-[#004A97] border-r-[4px] border-dotted xs:w-[100%] xs:border-r-[0px] xs:border-b-[4px] xs:border-b-[#004A97] xs:px-[0px] xs:pt-[0] xs:pb-[20px]">
                                                                            <form action="" className='flex flex-col'>
                                                                                <h5 className="text-[36px] leading-[42px] mb-[5px] text-[#0174EB] font-[700] xs:text-[30px] xxs:text-[24px] xxs:leading-[normal]">Pickup Location</h5>
                                                                                <div className="w-full">
                                                                                    {/* <input type="text" placeholder='Pickup Address' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]  md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' /> */}
                                                                                    <LoadScript
                                                                                        googleMapsApiKey="AIzaSyDREGT1UJTmCcgxmESBJZlXp0pNYTUcn5g"
                                                                                        libraries={libraries}
                                                                                        loading="async"
                                                                                    >
                                                                                        <Autocomplete
                                                                                            onLoad={handlePlaceSelect}
                                                                                        >
                                                                                            <input type="text" placeholder='Pickup Address' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px]  md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]'
                                                                                            />
                                                                                        </Autocomplete>
                                                                                    </LoadScript>
                                                                                </div>
                                                                                <div className="w-full">
                                                                                    <input type="text" placeholder='Pickup Address 2' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                </div>
                                                                                <div className='flex w-full gap-[10px] xxs:flex-col'>
                                                                                    <div className="w-full">
                                                                                        <input type="text" placeholder='Delivery City' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                    </div>
                                                                                    <div className="w-full">
                                                                                        <input type="text" placeholder='Delivery State' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-full">
                                                                                    <input type="number" placeholder='Pickup Zip' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                        <div className="w-[50%] px-[30px] py-[20px] pt-0 xs:w-[100%] xs:px-[0px] xs:pt-[10px] xs:pb-[0]">
                                                                            <form action="" className='flex flex-col'>
                                                                                <h5 className="text-[36px] leading-[42px] mb-[5px] text-[#0174EB] font-[700] xs:text-[30px] xxs:text-[24px] xxs:leading-[normal]">Dropoff Location</h5>
                                                                                <div className="w-full">

                                                                                    <LoadScript
                                                                                        googleMapsApiKey="AIzaSyDREGT1UJTmCcgxmESBJZlXp0pNYTUcn5g"
                                                                                        libraries={libraries}
                                                                                        loading="async"
                                                                                    >
                                                                                        <Autocomplete
                                                                                            onLoad={handlePlaceSelect2}
                                                                                        >
                                                                                            <input type="text" placeholder='Delivery Address' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                        </Autocomplete>
                                                                                    </LoadScript>
                                                                                </div>
                                                                                <div className="w-full">
                                                                                    <input type="text" placeholder='Delivery Address 2' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                </div>
                                                                                <div className='flex w-full gap-[10px] xxs:flex-col'>
                                                                                    <div className="w-full">
                                                                                        <input type="text" placeholder='Delivery City' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                    </div>
                                                                                    <div className="w-full">
                                                                                        <input type="text" placeholder='Delivery State' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-full">
                                                                                    <input type="number" placeholder='Delivery Zip' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    {showAnotherStop && (
                                                                        <>
                                                                            <div className='flex w-full xs:flex-col mt-[20px]'
                                                                            >
                                                                                <div className="w-[50%] pr-[30px] py-[20px] pt-0 border-r-[#004A97] border-r-[4px] border-dotted xs:w-[100%] xs:border-r-[0px] xs:border-b-[4px] xs:border-b-[#004A97] xs:px-[0px] xs:pb-[20px] xs:pt-[20px]">
                                                                                    <form action="" className='flex flex-col'>
                                                                                        <h5 className="text-[36px] leading-[42px] mb-[5px] text-[#0174EB] font-[700] xs:text-[30px] xxs:text-[24px] xxs:leading-[normal]">Pickup Location</h5>
                                                                                        <div className="w-full">
                                                                                            <input type="text" placeholder='Pickup Address' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                        </div>
                                                                                        <div className="w-full">
                                                                                            <input type="text" placeholder='Pickup Address 2' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                        </div>
                                                                                        <div className='flex w-full gap-[10px] xxs:flex-col'>
                                                                                            <div className="w-full">
                                                                                                <input type="text" placeholder='Delivery City' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                            </div>
                                                                                            <div className="w-full">
                                                                                                <input type="text" placeholder='Delivery State' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="w-full">
                                                                                            <input type="number" placeholder='Pickup Zip' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                                <div className="w-[50%] px-[30px] py-[20px] pt-0 xs:w-[100%] xs:px-[0px] xs:pt-[10px] xs:pb-[0]">
                                                                                    <form action="" className='flex flex-col'>
                                                                                        <h5 className="text-[36px] leading-[42px] mb-[5px] text-[#0174EB] font-[700] xs:text-[30px] xxs:text-[24px] xxs:leading-[normal]">Dropoff Location</h5>
                                                                                        <div className="w-full">
                                                                                            <input type="text" placeholder='Delivery Address' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                        </div>
                                                                                        <div className="w-full">
                                                                                            <input type="text" placeholder='Delivery Address 2' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                        </div>
                                                                                        <div className='flex w-full gap-[10px] xxs:flex-col'>
                                                                                            <div className="w-full">
                                                                                                <input type="text" placeholder='Delivery City' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                            </div>
                                                                                            <div className="w-full">
                                                                                                <input type="text" placeholder='Delivery State' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="w-full">
                                                                                            <input type="number" placeholder='Delivery Zip' className='text-[24px] leading-[27px] mb-[0px] text-[#000000] font-[400] placeholder-[#000000] bg-[#FFFFFF] border-[0] border-b-[#7FA4CB] border-b-[1px] w-full focus:[box-shadow:none] outline-[0] pl-[0] pr-[10px] py-[10px] md:text-[20px] xs:text-[20px] xs:leading-[normal]  xxs:text-[18px]' />
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className="mx-[20px] xxs:mx-[0px]">
                                                                    <motion.button
                                                                        whileHover={{ scale: 1.1 }}
                                                                        whileTap={{ scale: 0.9 }}
                                                                        transition={{ duration: 0.3, ease: "easeInOut" }}

                                                                        className='rounded-[36px] bg-[#044384] px-[20px] py-[20px] xs:p-[15px] xxs:p-[10px]'>
                                                                        <span
                                                                            className='text-[20px] leading-[28px] text-[#FFFFFF] font-bold xs:text-[18px] xxs:text-[17px] xxs:font-[500]'>Choose Door to Door Delivery</span>
                                                                    </motion.button>
                                                                </div>
                                                            </form>
                                                            <button onClick={toggleAnotherStop} className="text-[18px] sm:text-[16px] leading-[23px] p-[0] text-[#0174EB] font-[700] underline sm:mt-3">Add Another Stop</button>
                                                        </motion.div>
                                                    </div>
                                                    <div className="ms-2 text-sm">
                                                        <label htmlFor="helper-checkbox" className="text-[32px] xl:text-[25px] text-[#FFFFFF] leading-[38px] xl:leading-[30px] font-bold xxs:text-[20px] xxs:leading-[normal]">Door to Door Car </label>
                                                        <p id="helper-checkbox-text" className="text-[24px] xl:text-[18px] text-[#FFFFFF] leading-[28px] xl:leading-[22px] font-bold xxs:text-[14px]">Fill All Details</p>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mt-1">
                                                        <input id="helper-checkbox1" aria-describedby="helper-checkbox-text" type="checkbox" value="" className="w-[24px] h-[24px] border-black focus:border-black focus:[box-shadow:none] checked:border-black checked:bg-blue-500 xs:w-[19px] xs:h-[19px]" />
                                                    </div>
                                                    <div className="ms-2 text-sm">
                                                        <label htmlFor="helper-checkbox1" className="text-[32px] xl:text-[25px] text-[#FFFFFF] leading-[38px] xl:leading-[30px] font-bold xxs:text-[20px] xxs:leading-[normal]">Expedited Service </label>
                                                        <p id="helper-checkbox-text" className="text-[24px] xl:text-[18px] text-[#FFFFFF] leading-[28px] xl:leading-[22px] font-bold xxs:text-[14px]">(less then 7 days)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full max-w-[370px] gap-[5px] flex items-right justify-center xxs:-mb-[35px] pr-[30px] sm:max-w-[100%] sm:pr-[0px] sm:pt-[20px]">
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    transition={{ duration: 0.3, ease: "easeInOut" }}
                                                    animate={{ backgroundColor: "#044384" }}
                                                    className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[5] xs:px-[25px] xxs:px-[20px] xxs:py-[12px]' onClick={handleCharges}>
                                                    <span className='text-[32px] xl:text-[25px] leading-[38px] xl:leading-[30px] font-[900] text-[#FFFFFF] uppercase xs:text-[20px] xxs:text-[14px] uppercase xxs:leading-[normal] xxs:font-[500]'>Choose Flight</span>
                                                </motion.button>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={inView ? { opacity: isActive1 ? 1 : 0, y: isActive1 ? 0 : 20 } : {}}
                                    transition={{ delay: 0.2 }}
                                    className={`flex-col ${isActive1 ? 'flex' : 'hidden'}`}
                                    id="dashboard"
                                    role="tabpanel"
                                    aria-labelledby="dashboard-tab"
                                >
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={inView ? { opacity: 1, y: 0 } : {}}
                                        transition={{ delay: 0.5 }}
                                        className='border-[1px] border-[solid] border-[#004A97] bg-[#FFFFFF] px-[30px] rounded-[26px]  md:px-[18px] sm:px-[0px] sm:bg-transparent sm:border-none sm:rounded-[0px]'
                                    >
                                        <motion.div
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={inView ? { opacity: 1, y: 0 } : {}}
                                            transition={{ delay: 0.7 }}
                                            className='flex justify-between sm:flex-col sm:gap-[10px]'
                                        >
                                            <div className='flex sm:w-full sm:justify-center sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px]'>
                                                <LeavingFrom />
                                                <div className='flex items-center py-[20px] pl-[50px] lg:pl-[30px] pr-[60px] lg:pr-[30px] md:md-[20px] relative border-r border-solid border-[#004A97] sm:border-none  sm:w-1/2 sm:py-[20px] sm:pl-[70px] sm:pr-[20px] xxs:pl-[22px]'>
                                                    <GoingTo />
                                                    <div className='h-[63px] w-[63px] xl:h-[50px] xl:w-[50px] lg:h-[40px] lg:w-[40px] border-[1px] absolute cursor-pointer bottom-[40px] -left-[31px] xl:-left-[25px] lg:-left-[20px] border-[solid] border-[#004A97] rounded-[50%] xs:h-[50px] xs:w-[50px] xxs:h-[34px] xxs:w-[34px] xxs:top-[38%] xxs:-left-[18px] z-[2]'>
                                                        <LazyLoad offset={100} once>
                                                            <img src={rotateIcon} alt="rotateIcon" className='h-full w-full rounded-[50%]' />
                                                        </LazyLoad>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='py-[20px] px-[20px] lg:py-[10px] lg:px-[10px] flex items-center border-r border-solid border-[#004A97] min-h-[146px] sm:w-full sm:justify-center sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px] sm:py-[20px] sm:min-h-[65px]'>
                                                <div className="block sm:w-full">
                                                    <div className="relative inline-block sm:w-full">
                                                        <button
                                                            onClick={() => setIsOpen1(!isOpen1)}
                                                            className="flex min-w-[155px] xl:min-w-[140px] lg:min-w-[110px] justify-between items-center text-[#010101] font-[500] text-[32px] xl:text-[25px] lg:text-[20px] leading-[38px] lg:leading-[27px] xl:leading-[30px] sm:w-full"
                                                        >
                                                            {selectedOption1 || '# of Pets'}
                                                            <LazyLoad offset={100} once>
                                                                <img src={dropdownIcon} alt="dropdownIcon" className='ml-[5px] lg:max-w-[14px]' />
                                                            </LazyLoad>
                                                        </button>
                                                        {isOpen1 && (
                                                            <div className="absolute z-[91] w-full mt-2 bg-white border-[2px] border-[#004A97] rounded-[18px] shadow sm:w-[110px] sm:right-[0px]">
                                                                {carPetsOption.map((option, index) => (
                                                                    <div
                                                                        key={option}
                                                                        onClick={() => handleOptionClick1(option)}
                                                                        className={`p-2 cursor-pointer ${index === carPetsOption.length - 1 ? '' : 'border-b-[1px] border-b-[#004A97]'}`}
                                                                    >
                                                                        {option}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='py-[20px] px-[15px] lg:py-[10px] lg:px-[10px] border-r border-solid border-[#004A97] sm:w-full sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px] items-center flex'>
                                                <div className="flex flex-col">
                                                    <span className='text-[#010101] font-normal text-[22px] leading-[28px] xl:text-[18px] lg:text-[16px] xl:leading-[24px] lg:leading-[20px]'>Preferred Date 01</span>
                                                    <DatePicker />
                                                </div>
                                            </div>
                                            <div className='py-[20px] px-[15px] lg:py-[10px] lg:px-[10px] border-r border-solid border-[#004A97] sm:w-full sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px] items-center flex'>
                                                <div className="flex flex-col">
                                                    <span className='text-[#010101] font-normal text-[22px] leading-[28px] xl:text-[18px] lg:text-[16px] xl:leading-[24px] lg:leading-[20px]'>Preferred Date 02</span>
                                                    <DatePicker />
                                                </div>
                                            </div>
                                            <div className='py-[20px] px-[15px] sm:w-full sm:bg-white sm:border-[1px] sm:border-[solid] sm:border-[#004A97] sm:rounded-[10px] sm:px-[20px] flex items-center'>
                                                <div className="flex flex-col">
                                                    <span className='text-[#010101] font-normal text-[22px] leading-[28px] xl:text-[18px] lg:text-[16px] xl:leading-[24px] lg:leading-[20px]'>Preferred Date 03</span>
                                                    <DatePicker />
                                                </div>
                                            </div>
                                        </motion.div>
                                    </motion.div>
                                    <div className="my-[20px] sm:mb-[0] flex">
                                        <div className="flex w-full justify-between sm:flex-wrap">
                                            <div className="flex px-[70px] 2xl:px-[50px] xl:px-[30px] xs:pl-[0px] xs:pr-[20px]">
                                                <div className="flex mt-1 relative">
                                                    <input id="helper-checkbox2" aria-describedby="helper-checkbox-text" type="checkbox" value="" className="w-[24px] h-[24px] relative border-black focus:border-black focus:[box-shadow:none] checked:border-black checked:bg-blue-500 xs:w-[19px] xs:h-[19px]" />
                                                </div>
                                                <div className="ms-2 text-sm">
                                                    <label htmlFor="helper-checkbox2" className="text-[32px] xl:text-[25px] text-[#FFFFFF] leading-[38px] xl:leading-[30px] font-bold xxs:text-[20px]">Expedited Service </label>
                                                    <p id="helper-checkbox-text" className="text-[24px] xl:text-[18px] text-[#FFFFFF] leading-[28px] xl:leading-[22px] font-bold xxs:text-[14px]">(less then 7 days)</p>
                                                </div>
                                            </div>
                                            <div className="pr-[30px] sm:pr-[0] sm:pt-[20px] sm:w-full flex items-center justify-center xxs:-mb-[35px]">
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    transition={{ duration: 0.3, ease: "easeInOut" }}
                                                    animate={{ backgroundColor: "#044384" }}
                                                    className='rounded-[36px] py-[18px] px-[45px] bg-[#044384] pointer z-[5] xs:px-[25px] xxs:px-[20px] xxs:py-[12px]' onClick={handleTranport}>
                                                    <span className='text-[32px] leading-[38px] font-[900] text-[#FFFFFF] xs:text-[20px] xxs:text-[14px] uppercase xxs:leading-[normal] xxs:font-[500]'>Choose Car Transport</span>
                                                </motion.button >
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={inView ? { opacity: isActive2 ? 1 : 0, y: isActive2 ? 0 : 20 } : {}}
                                    transition={{ delay: 0.5 }}
                                    className={`flex-col ${isActive2 ? 'flex' : 'hidden'}`}
                                    id="settings"
                                    role="tabpanel"
                                    aria-labelledby="settings-tab"
                                >
                                    <div className='border-[1px] h-[282.5px] border-[solid] border-[#004A97] bg-[#FFFFFF] px-[30px] rounded-[26px] sm:h-[150px] xs:h-[120px] xxs:h-[93px] sm:rounded-[10px]'>
                                        <div className='flex h-full items-center justify-center'>
                                            <h4 className='text-[64px] text-[#0174EB] font-[400] leading-[75px] sm:text-[50px] xs:text-[32px] xxs:text-[25px]'>Coming Soon</h4>
                                        </div>
                                    </div>
                                </motion.div>
                            </motion.div>
                        </div>
                    ) : (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={inView ? { opacity: 1 } : {}}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            {
                                ischarges ? <BookingCharges
                                    closehangleCoupon={closehangleCoupon}
                                    handlePets={handlePets}
                                /> : null
                            }
                            {
                                ispets ? <PetProfile
                                    closehangleCoupon={closehangleCoupon}
                                    handlePetsDetail={handlePetsDetail}
                                /> : null
                            }
                            {
                                ispetsDetail ? <PetProfileDetail
                                    closehangleCoupon={closehangleCoupon}
                                /> : null
                            }
                            {
                                istransport ? <BookingTransport
                                    closehangleCoupon={closehangleCoupon}
                                    handleCharges={handleCharges}
                                /> : null
                            }
                            {
                                isdelivery ? <BookingDelivery
                                    closehangleCoupon={closehangleCoupon}
                                /> : null
                            }
                        </motion.div>
                    )}
                </div>
            </motion.div>
        </>
    );
};

export default PetTabs;
