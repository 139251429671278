// import React, { useEffect, useRef } from 'react';
// import contentImage6 from '../../assets/images/sec-image6.png';
// import { motion } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
// import gsap from 'gsap';
// import LazyLoad from 'react-lazy-load';

// const ContentFooter = () => {
//     const [ref, inView] = useInView({ threshold: 0.2 });
//     const textRef1 = useRef(null);
//     const textRef2 = useRef(null);

//     useEffect(() => {
//         if (inView) {
//             gsap.from(textRef1.current, {
//                 opacity: 0,
//                 x: -50,
//                 duration: 1,
//                 delay: 0.5
//             });

//             gsap.from(textRef2.current, {
//                 opacity: 0,
//                 x: 50,
//                 duration: 1,
//                 delay: 0.5
//             });
//         }
//     }, [inView]);

//     const text1 = "How we";
//     const text2 = "Certify Our Nannies";

//     return (
//         <>
//             <div ref={ref} className="container mx-auto max-w-[1597px] px-[20px]">
//                 <div className={`w-full mx-auto mt-[50px] overflow-hidden ${inView ? 'animate' : ''}`}>
//                     <div className="flex xs:flex-col-reverse xs:justify-center">
//                         <div className="w-full w-1/2 px-4 flex items-center justify-end  xs:justify-center">
//                             <motion.div
//                                 className="bg-white p-6 pb-0"
//                                 initial={{ opacity: 0, x: -50 }}
//                                 animate={inView ? { opacity: 1, x: 0 } : {}}
//                                 transition={{ duration: 0.5 }}
//                             >
//                                 <LazyLoad offset={100} once>
//                                     <motion.img
//                                         className="xl:max-h-[600px]"
//                                         src={contentImage6}
//                                         alt="contentImage6"
//                                         initial={{ opacity: 0, scale: 0.5 }}
//                                         animate={inView ? { opacity: 1, scale: 1 } : {}}
//                                         transition={{ duration: 0.5 }}
//                                     />
//                                 </LazyLoad>
//                             </motion.div>
//                         </div>
//                         <div className="w-full w-1/2 px-4 flex items-center justify-start xs:justify-center">
//                             <motion.div
//                                 initial={{ opacity: 0, x: 50 }}
//                                 animate={inView ? { opacity: 1, x: 0 } : {}}
//                                 transition={{ duration: 0.5 }}
//                                 className="bg-white p-6 w-[75%] lg:w-[90%] sm:w-[100%] xs:w-full xs:text-center xxs:p-[0]"
//                             >
//                                 <p ref={textRef1} className="text-[40px] 2xl:text-[35px] xl:text-[30px] lg:text-[30px] leading-[47px] 2xl:leading-[42px] xl:leading-[37px] lg:leading-[32px] font-[500] text-[#6D7B8D] mb-4">
//                                     {text1.split("").map((char, i) => (
//                                         <motion.span
//                                             key={i}
//                                             initial={{ opacity: 0, x: -50 }}
//                                             animate={inView ? { opacity: 1, x: 0 } : {}}
//                                             transition={{ duration: 0.25, delay: inView ? i / 10 : 0 }}
//                                         >
//                                             {char}
//                                         </motion.span>
//                                     ))}
//                                 </p>
//                                 <h2 ref={textRef2} className="text-[96px] 2xl:text-[76px] xl:text-[65px] lg:text-[55px] leading-[112px] 2xl:leading-[92px] xl:leading-[75px] lg:leading-[65px] font-[900] text-[#0174EB] mb-4 xxs:text-[45px] xxs:leading-[1]">
//                                     {text2.split("").map((char, i) => (
//                                         <motion.span
//                                             key={i}
//                                             initial={{ opacity: 0, x: -50 }}
//                                             animate={inView ? { opacity: 1, x: 0 } : {}}
//                                             transition={{ duration: 0.25, delay: inView ? i / 20 : 0 }}
//                                         >
//                                             {char}
//                                         </motion.span>
//                                     ))}
//                                 </h2>
//                             </motion.div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default ContentFooter;

import React, { useEffect, useRef } from 'react';
import contentImage6 from '../../assets/images/sec-image6.png';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';
import LazyLoad from 'react-lazy-load';

const ContentFooter = () => {
    const [ref, inView] = useInView({ threshold: 0.2 });
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);

    useEffect(() => {
        if (inView) {
            gsap.from(textRef1.current, {
                opacity: 0,
                x: -50,
                duration: 1,
                delay: 0.5
            });

            gsap.from(textRef2.current, {
                opacity: 0,
                x: 50,
                duration: 1,
                delay: 0.5
            });
        }
    }, [inView]);

    const text1 = "How we";
    const text2 = "Certify Our Nannies";

    return (
        <>
            <div ref={ref} className="container mx-auto max-w-[1597px] px-[20px]">
                <div className={`w-full mx-auto mt-[50px] overflow-hidden ${inView ? 'animate' : ''}`}>
                    <div className="flex xs:flex-col-reverse xs:justify-center">
                        <div className="w-full w-1/2 px-4 flex items-center justify-end  xs:justify-center">
                            <motion.div
                                className="bg-white p-6 pb-0"
                                initial={{ opacity: 0, x: -50 }}
                                animate={inView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5 }}
                            >
                                <LazyLoad offset={100} once>
                                    <motion.img
                                        className="xl:max-h-[600px]"
                                        src={contentImage6}
                                        alt="contentImage6"
                                        initial={{ opacity: 0, scale: 0.5 }}
                                        animate={inView ? { opacity: 1, scale: 1 } : {}}
                                        transition={{ duration: 0.5 }}
                                    />
                                </LazyLoad>
                            </motion.div>
                        </div>
                        <div className="w-full w-1/2 px-4 flex items-center justify-start xs:justify-center">
                            <motion.div
                                initial={{ opacity: 0, x: 50 }}
                                animate={inView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5 }}
                                className="bg-white p-6 w-[75%] lg:w-[90%] sm:w-[100%] xs:w-full xs:text-center xxs:p-[0]"
                            >
                                <p ref={textRef1} className="text-[40px] 2xl:text-[35px] xl:text-[30px] lg:text-[30px] leading-[47px] 2xl:leading-[42px] xl:leading-[37px] lg:leading-[32px] font-[500] text-[#6D7B8D] mb-4">
                                    {text1.split("").map((char, i) => (
                                        <motion.span
                                            key={i}
                                            initial={{ opacity: 0, x: -50 }}
                                            animate={inView ? { opacity: 1, x: 0 } : {}}
                                            transition={{ duration: 0.25, delay: inView ? i / 10 : 0 }}
                                        >
                                            {char}
                                        </motion.span>
                                    ))}
                                </p>
                                <h2 ref={textRef2} className="text-[96px] 2xl:text-[76px] xl:text-[65px] lg:text-[55px] leading-[112px] 2xl:leading-[92px] xl:leading-[75px] lg:leading-[65px] font-[900] text-[#0174EB] mb-4 xxs:text-[45px] xxs:leading-[1]">
                                    {text2.split("").map((char, i) => (
                                        <motion.span
                                            key={i}
                                            initial={{ opacity: 0, x: -50 }}
                                            animate={inView ? { opacity: 1, x: 0 } : {}}
                                            transition={{ duration: 0.25, delay: inView ? i / 20 : 0 }}
                                        >
                                            {char}
                                        </motion.span>
                                    ))}
                                </h2>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContentFooter;

