import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import dollarSign from '../../assets/images/dollar-sign.svg';
import badgeIcon from '../../assets/images/badge-icon.svg';
import groupIcon from '../../assets/images/group-icon.svg';

const NannyBanner = (props) => {
    const [ref, inView] = useInView({ threshold: 0.4 });
    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : {}}
            transition={{ duration: 0.5 }} // Faster duration
        >
            <div
                className="relative overflow-hidden"
                style={{
                    background: `linear-gradient(270deg, rgba(255,255,255,1) 32%, rgba(1,116,235,1) 300%)`,
                }}
            >
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}}
                    transition={{ delay: 0.3, duration: 0.5 }} // Faster animation with less delay
                >
                    <div className="max-w-[1530px] ml-auto mr-auto py-[50px] px-[97px] lg:py-[30px] xl:px-[40px] xs:py-[20px] xs:px-[20px] xs:m-[0]">
                        <Link to="/">
                            <img
                                src={
                                    "https://strapi.incabinpets.com" +
                                    props.headerData.Logo.data.attributes.url
                                }
                                className="object-cover inset-0 max-w-[294px] xs:max-w-[200px] xxs:max-w-[120px]"
                                alt="site-logo"
                                initial={{ scale: 0 }}
                                animate={inView ? { scale: 1 } : {}}
                                transition={{ delay: 0.7, duration: 0.5 }} // Faster scaling animation
                            />
                        </Link>
                    </div>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}}
                    transition={{ delay: 0.5, duration: 0.5 }} // Faster opacity transition
                >
                    <div className="flex max-w-[1530px] px-[97px] justify-between ml-auto mr-auto pt-[30px] -mb-[35px] xl:px-[40px] xs:px-[20px] xs:flex-wrap xs:gap-[50px] xs:justify-center xxs:-mb-[10px]">
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ delay: 0.5, duration: 0.5 }} // Faster Y transition
                        >
                            <div className="relative z-[9] xs:w-full">
                                <motion.div
                                    initial={{ opacity: 0, x: -100 }}
                                    animate={inView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ delay: 0.5, duration: 0.5 }} // Faster X transition
                                >
                                    <span className="z-10 mt-0 text-[60px] leading-[72px] font-[700] text-[#414141] lg:text-[40px] sm:text-[35px] xs:text-[30px] xxs:text-[24px]">
                                        {props.bannerData.topHeading}
                                    </span>
                                    <h1 className="text-9xl text-[#0174EB] font-[700] leading-[106.24px] lg:text-[100px] sm:text-[80px] sm:leading-[normal] xs:text-[70px] xxs:text-[50px]">
                                        {props.bannerData.mainHeading}
                                    </h1>
                                    <h2 className="text-6xl font-[700] text-[#0174EB] leading-[65.92px] sm:text-[40px] xs:text-[32px] xs:leading-[normal] xxs:text-[25px]">
                                        {props.bannerData.bottomHeading}
                                    </h2>
                                    <p className="text-4xl w-[339px] text-[#414141] font-[700] sm:text-[32px] xs:w-full xs:text-[28px] xxs:text-[24px] xxs:leading-[normal]">
                                        {props.bannerData.description}
                                    </p>
                                    <motion.a
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        href="##"
                                        className="justify-center px-[31px] py-[25px] max-w-[292px] mt-6 text-3xl block font-black text-center text-white bg-[#0174EB] rounded-2xl sm:text-[28px] xs:text-[22px] xxs:max-w-max xxs:px-[15px] xxs:py-[12px] xxs:font-[500] xxs:text-[20px]"
                                    >
                                        {props.bannerData.button}
                                    </motion.a>
                                </motion.div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: -50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ delay: 0.5, duration: 0.5 }}
                        >
                            <div className="absolute bottom-[-35px] ml-auto mr-auto max-w-[1336px] left-[230px] right-[0] sm:left-[50px] xs:static xs:order-[1]">
                                <img
                                    src={
                                        "https://strapi.incabinpets.com" +
                                        props.bannerData.bannerImage.data.attributes.url
                                    }
                                    alt="image1"
                                />
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ delay: 0.5, duration: 0.5 }} // Faster Y transition
                        >
                            <div className="flex flex-col gap-[30px] pb-[80px] relative z-[9] xs:w-full xs:pb-[20px] xs:items-center">
                                <motion.div
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={inView ? { opacity: 1, y: 0 } : {}}
                                    transition={{ delay: 0.5, duration: 0.5 }}
                                >
                                    <div
                                        className="flex flex-col items-start px-8 py-8 w-[316px] whitespace-nowrap bg-white rounded-2xl drop-shadow max-w-[316px] relative xxs:max-w-full xxs:w-full xxs:px-4 xxs:py-4"
                                    >
                                        <div className="absolute top-[24px] right-[17px]">
                                            <img
                                                className="max-w-[70px] xs:max-w-[55px] xxs:max-w-[45px]"
                                                src={dollarSign}
                                                alt="icon"
                                            />
                                        </div>
                                        <p className="text-[24px] font-[300] leading-[normal] text-[#6D7B8D] xs:text-[20px] xxs:text-[18px]">
                                            {props.bannerData.bannerBoxs[0].mainTitle}
                                        </p>
                                        <h3
                                            className="text-[40px] font-[900] leading-[normal] text-[#0174EB] sm:text-[35px] xxs:text-[28px]"
                                        >
                                            {props.bannerData.bannerBoxs[0].Title}
                                        </h3>
                                        <p
                                            className="text-[20px] font-[500] leading-[normal] text-[#6D7B8D] xs:text-[20px] xxs:text-[18px]"
                                        >
                                            {props.bannerData.bannerBoxs[0].bottomTitle}
                                        </p>
                                        <h3
                                            className="text-[40px] font-[900] leading-[normal] text-[#0174EB] sm:text-[35px] xxs:text-[28px]"
                                        >
                                            {props.bannerData.bannerBoxs[0].titleTwo}
                                        </h3>
                                        <p
                                            className="text-[24px] font-[500] leading-[normal] text-[#6D7B8D] xs:text-[20px] xxs:text-[18px]"
                                        >
                                            <strong
                                                className="text-[24px] font-[900] leading-[normal] text-[#6D7B8D] xs:text-[20px] xxs:text-[18px]"
                                            >
                                                {props.bannerData.bannerBoxs[0].description}
                                                {/* Bonus on */}
                                            </strong>{" "}
                                            {/* first Flight */}
                                        </p>
                                    </div>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={inView ? { opacity: 1, y: 0 } : {}}
                                    transition={{ delay: 0.7, duration: 0.5 }}
                                >
                                    <div
                                        className="flex flex-col items-start px-8 py-8 w-[316px] whitespace-nowrap bg-white rounded-2xl drop-shadow max-w-[316px] relative xxs:max-w-full xxs:w-full xxs:px-4 xxs:py-4"
                                    >
                                        <div className="absolute bottom-[24px] right-[17px]">
                                            <img
                                                className="max-w-[70px] xs:max-w-[55px] xxs:max-w-[45px]"
                                                src={badgeIcon}
                                                alt="icon"
                                            />
                                        </div>
                                        <p
                                            className="text-[24px] font-[500] leading-[normal] text-[#6D7B8D] xs:text-[20px] xxs:text-[18px] whitespace-break-spaces"
                                        >
                                            {props.bannerData.bannerBoxs[1].mainTitle}
                                        </p>
                                        <h3
                                            className="text-[40px] font-[700] leading-[48px] text-[#0174EB] sm:text-[35px] xxs:text-[28px] xxs:leading-[normal] whitespace-break-spaces"
                                        >
                                            {props.bannerData.bannerBoxs[1].Title}
                                        </h3>
                                    </div>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={inView ? { opacity: 1, y: 0 } : {}}
                                    transition={{ delay: 0.9, duration: 0.5 }}
                                >
                                    <div
                                        className="flex flex-col items-start px-8 py-8 w-[316px] whitespace-nowrap bg-white rounded-2xl drop-shadow max-w-[316px] relative xxs:max-w-full xxs:w-full xxs:px-4 xxs:py-4"
                                    >
                                        <div className="absolute bottom-[24px] right-[17px]">
                                            <img
                                                className="max-w-[70px] xs:max-w-[55px] xxs:max-w-[45px]"
                                                src={groupIcon}
                                                alt="icon"
                                            />
                                        </div>
                                        <p
                                            className="text-[24px] font-[300] leading-[normal] text-[#6D7B8D] xs:text-[20px] xxs:text-[18px] whitespace-break-spaces"
                                        >
                                            {props.bannerData.bannerBoxs[2].mainTitle}
                                        </p>
                                        <h3
                                            className="text-[40px] font-[700] leading-[48px] text-[#0174EB] sm:text-[35px] xxs:text-[28px]"
                                        >
                                            {props.bannerData.bannerBoxs[2].Title}
                                        </h3>
                                        <p
                                            className="text-[24px] font-[500] leading-[normal] text-[#6D7B8D] xs:text-[20px] xxs:text-[18px] whitespace-break-spaces max-w-[200px]"
                                        >
                                            {props.bannerData.bannerBoxs[2].bottomTitle}
                                        </p>
                                    </div>
                                </motion.div>
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    )
}

export default NannyBanner
