import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
const GoingTo = ({ defaultText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toAirport, setToAirport] = useState('');
  const [toSearchText, setToSearchText] = useState('');
  const [toSuggestions, setToSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (toSearchText.length >= 3) {
        setLoading(true);
        try {
          const response = await axios.get(`select-search/airport?term=${toSearchText}`);
          const data = response.data;
          setToSuggestions(data.results);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setToSuggestions([]);
      }
    };
    fetchData();
  }, [toSearchText]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, []);

  const handleItemClick = (airport) => {
    setToAirport(airport);
    setToSearchText(airport);
    setIsOpen(false);
  };

  return (
    <div className="flex flex-col relative">
      <span className='text-[#010101] font-normal text-[24px] lg:text-[18px] leading-[28px] lg:leading-[24px] xs:text-[16px]'>To Address</span>
      <div ref={dropdownRef} className="w-full max-w-[190px] lg:max-w-[150px] top-full left-0 z-[2] bg-white border-0 rounded-lg mt-1 xxs:max-w-[120px]">
        <input
          type="text"
          placeholder={toAirport ? toAirport : 'Going To'}
          value={toSearchText}
          onChange={(e) => setToSearchText(e.target.value)}
          onFocus={() => setIsOpen(true)}
          className="appearance-none w-full h-[60px] xl:h-[45px] xxs:h-[35px] p-0 relative block border-[0] placeholder-[#0174EB] !bg-[#FFFFFF] leading-[47px] focus:ring-0 xxs:text-[17px] !text-[#0174EB] font-[900] text-[48px] 2xl:text-[36px] xl:text-[26px] lg:text-[24px] leading-[56px] xl:leading-[40px] lg:leading-[37px] xs:text-[22px] xs:leading-[normal]"
        />
        {isOpen && toSearchText.length >= 3 && (
          <ul className="absolute w-[300px] p-[0] -mt-[20px] pb-[5px] top-full left-0 z-10 bg-white rounded-lg mt-1 xs:w-[250px] xxs:w-[220px]">
            {loading ? (
              <li className="px-4 py-2 flex justify-center items-center">
                <FaSpinner className="animate-spin mr-2" />
              </li>
            ) : (
              toSuggestions.map((item, index) => (
                <li key={item.id} onClick={() => handleItemClick(item.text)} className="px-4 py-2 cursor-pointer border border-gray-300">
                  {item.text}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
    </div>

  );
};

export default GoingTo;
