import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';
import LazyLoad from 'react-lazy-load';

const FlightInformation = (props) => {
    const [ref, inView] = useInView({ threshold: 0.3 });
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);
    const textRef3 = useRef(null);
    useEffect(() => {
        if (inView) {
            gsap.from(textRef1.current, {
                opacity: 0,
                x: -50,
                duration: 1,
                delay: 0.5,
            });

            gsap.from(textRef2.current, {
                opacity: 0,
                x: 50,
                duration: 1,
                delay: 0.5,
            });

            gsap.from(textRef3.current, {
                opacity: 0,
                x: -50,
                duration: 1,
                delay: 0.5,
            });
        }
    }, [inView]);
    return (
        <div ref={ref} className="container mx-auto max-w-[1597px] pt-[100px] sm:pt-[40px] px-[20px]">
            <div className={`w-full mx-auto mb-[80px] sm:mb-[40px] overflow-hidden ${inView ? 'animate' : ''}`}>
                <div className="flex xs:flex-col">
                    <div className="w-full w-1/2 px-4 xs:px-0 flex items-center justify-end xs:justify-center">
                        <motion.div
                            className="bg-white w-[95%] sm:w-[100%] xs:w-full"
                            initial={{ opacity: 0, x: 50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5 }}
                        >
                            <h2
                                ref={textRef2}
                                className="max-w-[702px] text-[85px] 2xl:text-[76px] xl:text-[65px] lg:text-[50px] md:text-[40px] leading-[90px] 2xl:leading-[80px] xl:leading-[70px] lg:leading-[55px] font-[900] text-[#0174EB] mb-4 xxs:text-[34px] xxs:leading-[1]"
                            >
                                <motion.span
                                    initial={{ opacity: 0, x: -50 }}
                                    animate={inView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.25, delay: inView ? 0.25 : 0 }}
                                >
                                    {props.data.titleOne} <span className="text-[70px] xl:text-[60px] lg:text-[45px] md:text-[35px] xxs:text-[30px] text-[#414141]">{props.data.sub_title}</span> {props.data.titleTwo}
                                </motion.span>
                            </h2>
                            <p
                                ref={textRef1}
                                className="text-[25px] xl:text-[22px] lg:text-[20px]  xxs:text-[17px] font-[500] text-[#6D6D6D] mb-4"
                            >
                                {props.data.description}
                            </p>
                            <motion.a
                                href={props.data.button_link}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                className="mt-[25px] flex justify-center items-center w-[196px] 2xl:w-[240px] xl:w-[220px] lg:w-[200px] h-[69px] 2xl:h-[63px] xl:h-[58px] bg-[#0174EB] text-white text-[30px] 2xl:text-[27px] xl:text-[23px] lg:text-[20px] leading-[37px] 2xl:leading-[32px] xl:leading-[30px] lg:leading-[28px] font-[700] py-2 px-4 rounded-[15px] uppercase" tabIndex="0">{props.data.button_text}</motion.a>
                        </motion.div>
                    </div>
                    <div className="w-full w-1/2 px-4 xs:px-0 flex items-center justify-end xs:justify-center">
                        <motion.div
                            className="bg-white p-6"
                            initial={{ opacity: 0, x: -50 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.5 }}
                        >
                            <LazyLoad offset={100} once>
                                <motion.img
                                    src={"https://strapi.incabinpets.com" + props.data.image.data.attributes.url}
                                    alt="image"
                                    initial={{ opacity: 0, x: -50 }}
                                    animate={inView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.5 }}
                                />
                            </LazyLoad>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlightInformation
