import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const NannyFooter = (props) => {
    console.log(props.footer);
    const [ref, inView] = useInView({ threshold: 0.3 });
    return (
        <>
            <motion.div ref={ref} initial={{ opacity: 0, y: 50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                <div className="flex justify-center items-center self-stretch max-w-[1530px] px-[97px] pb-[20px] pt-[50px] ml-auto mr-auto xs:pt-[20px] xl:px-[40px] xs:px-[20px]">
                    <div className="flex gap-[20px] xs:flex-wrap justify-center">
                        <div className="text-4xl leading-[57.6px] text-neutral-700 sm:text-[32px] xs:text-[28px]">
                            {props.footer.title}
                        </div>
                        <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={inView ? { opacity: 1, scale: 1 } : {}} transition={{ delay: 0.6, duration: 1 }}>
                            <div className="flex gap-5 justify-between my-auto">
                                <ul className='flex gap-[20px] sm:flex-wrap xs:gap-[10px] xxs:justify-center'>
                                    {props.footer.Social_icons.map(links => (
                                        <li key={links.id}>
                                            <a href={links.Icon_link} className='xs:flex xs:justify-center'>
                                                <img
                                                    src={"https://strapi.incabinpets.com" + links.Icon.data.attributes.url}
                                                    className="h-[auto] w-[auto] md:w-[75%] xs:w-[70%] xxs:w-[50%]"
                                                    alt={"icon" + links.id}
                                                />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </motion.div>
            <motion.div initial={{ opacity: 0, y: 50 }} animate={inView ? { opacity: 1, y: 0 } : {}} transition={{ delay: 0.5, duration: 1 }}>
                <div className="flex flex-col w-full max-w-[1530px] ml-auto mr-auto py-[50px] px-[20px] max-md:max-w-full sm:py-[20px]">
                    <div className="text-[22px] leading-normal text-center text-[#414141] xs:text-[18px] xxs:text-[16px]">
                        {props.footer.Coppyright_content[0].children[0].text}
                    </div>
                </div>
            </motion.div>
        </>
    )
}

export default NannyFooter
