import React from 'react';
import { motion } from 'framer-motion';
import LazyLoad from 'react-lazy-load';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const ContactAddress = (props) => {
  const { ref, inView } = useInView({ threshold: 0.5 });

  // Variants for different sections
  const slideInLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const slideInRight = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const bounce = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { scale: 1, opacity: 1, transition: { duration: 0.5, type: 'spring' } },
  };

  const staggerContainer = {
    hidden: {},
    visible: { transition: { staggerChildren: 0.2 } },
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };
  const slideIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };


  return (
    <motion.div
      ref={ref}
      className="bg-[#0174EB] pt-[70px] xl:pt-[50px] pb-[35px] xl:pb-[25px] xs:pt-[40px] xs:pb-0 overflow-hidden"
      variants={fadeIn}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
    >
      <div className="max-w-[1570px] m-auto px-[20px] flex justify-between flex-wrap">
        <motion.div
          className="w-1/3 xs:w-full mb-[20px]"
          variants={slideInLeft}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        >
          <div className="flex items-start">
            <LazyLoad offset={100} once>
              <motion.img
                src={"https://strapi.incabinpets.com" + props.data[0].icon.data.attributes.url}
                alt="Location Icon"
                className="mr-[8px] sm:mr-[5px] mt-1 sm:mt-0 w-[45px] lg:w-[38px] sm:w-[30px]"
                variants={bounce}
              />
            </LazyLoad>
            <motion.p
              className="text-white text-[35px] xl:text-[30px] lg:text-[25px] sm:lg:text-[18px] leading-[1.2] font-[500] max-w-[330px]"
              variants={slideInLeft}
            >
              {props.data[0].title}
            </motion.p>
          </div>
        </motion.div>

        <motion.div
          className="w-1/3 xs:w-full mb-[20px]"
          variants={slideInRight}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        >
          <div className="flex items-start">
            <LazyLoad offset={100} once>
              <motion.img
                src={"https://strapi.incabinpets.com" + props.data[1].icon.data.attributes.url}
                alt="Mail"
                className="mr-[8px] sm:mr-[5px] mt-1 sm:mt-0 w-[45px] lg:w-[38px] sm:w-[30px]"
                variants={bounce}
              />
            </LazyLoad>
            <motion.span
              className="text-[35px] xl:text-[30px] lg:text-[25px] sm:lg:text-[18px] leading-[1.2] text-white font-[500]"
              variants={fadeIn}
            >
              <Link to="mailto:info@incabinpets.com">
                {props.data[1].title}
              </Link>
            </motion.span>
          </div>
        </motion.div>
        <motion.div
          className="w-1/3 xs:w-full mb-[20px]"
          variants={fadeIn}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        >
          <motion.h3
            className="text-[35px] xl:text-[30px] lg:text-[25px] sm:lg:text-[18px] leading-[1.2] text-white font-[500]"
            variants={bounce}
          >
            Follow Us
          </motion.h3>
          <motion.div
            className="mt-4 mb-3"
            variants={staggerContainer}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
          >
            <motion.ul className="flex flex-wrap items-center max-w-[300px]" variants={staggerContainer}>
              {props.links.map(allLinks => (
                <motion.li
                  key={allLinks.id}
                  className="pr-[27px] mb-[27px] lg:pr-[24px] lg:mb-[24px] sm:pr-[20px] sm:mb-[20px]"
                  variants={slideIn}
                >
                  <Link to={allLinks.url}>
                    <LazyLoad offset={100} once>
                      <motion.img
                        src={"https://strapi.incabinpets.com" + allLinks.icon.data.attributes.url}
                        className="w-[37px] h-[37px]"
                        alt={`SocialIcon ${allLinks.id + 1}`}
                        variants={slideIn}
                      />
                    </LazyLoad>
                  </Link>
                </motion.li>
              ))}
            </motion.ul>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ContactAddress;
